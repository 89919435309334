import { useState } from 'react';
import { Input, message, Modal, Typography, Form } from 'antd';
import useFetch from '../_fetch';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from '../store/action/client';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

const layout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 },
};

	export default function RequestEntity({ visible, setVisible }) {

		const _fetch = useFetch();
		const [loading, setLoading] = useState(false);
		const [data, setData] = useState({});
		const { selected: selectedCompany } = useSelector(st => st.company);
		const [fy, setFy] = useState('');
		let dispatch = useDispatch();
		const [selectedDate, setSelectedDate] = useState();
		const [form] = Form.useForm();
		const handleDateChange = (date) => {

			setSelectedDate(`${(dayjs(date).subtract(1, 'year').format('YYYY'))}-${(dayjs(date).format('YYYY'))}`);
			setFy((dayjs(date).format('YYYY')));
		};
     
		

	async function submit() {
		setLoading(true);
		try {

			let res1 =
				await _fetch(`${process.env.REACT_APP_API_URL}/client
			`,
					{

						method: "POST",
						body: {
							company_id: selectedCompany._id,
							title: data.title,
							abn: data.abn,
							fy: fy,
							status: "PROPOSED",
						
						}

					})

			let response1 = await res1.json();

			if (res1.status === 200) {
				message.success('Entity requested successfully');
				
				setVisible(false); 
				dispatch(getClients(selectedCompany._id));

			} else {
				if (response1.response === "company_id is required") {
					message.error("Please add a company before adding a client")
				} else {
					message.error(response1.response);
				};
			}
			
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	}

	return (
		<Modal
			visible={visible}
			onCancel={() => { setVisible(false); setFy(null); setData(null); }}
			title={<h6 className="font-semibold m-0">Request Entity</h6>}
			okText="Request Entity" onOk={submit}
			destroyOnClose
			okButtonProps={{ loading: loading }}
		>
			<Form
				layout="vertical"
				{...layout}
				form={form}
				onFinish={submit}
				onChange={(e) => {
					console.log(e)
				}}>
				<Form.Item>
					<label>Entity Name</label>
					<Input className='mb-3' onChange={(ev) => setData(pre => ({ ...pre, title: ev.target.value }))} />
				</Form.Item>
				<Form.Item>
					<label>ABN ID</label>

					<Input className='mb-3' onChange={(ev) => setData(pre => ({ ...pre, abn: ev.target.value }))} />
				</Form.Item>
				<Form.Item>
					<div>
						<style jsx>{`
      .fyyeaR .ant-picker-input > input::placeholder {
        color:#1677ff; 
      }
    `}</style>
						<DatePicker
							className="fyyeaR"
							placeholder="+ Add Fy"
							selected={selectedDate}
							bordered={false}
							suffixIcon=""
							onChange={handleDateChange}
							picker='year'
						/>
						

					</div>
				</Form.Item>
			</Form>
		</Modal>


	);
}
