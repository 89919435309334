const initState = {
  jwtToken: "",
  fullName: "",
  email: "",
  _id: "",
  role: "",
  all: [],
  company_ids:[]
};

export const userReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "REGISTER": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "SET_ALL_USERS":
      return {
        ...state,
        all: action.payload,
      };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};
