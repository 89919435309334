import { Tag, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import useFetch from '../../_fetch';
import moment from 'moment';
import { Buffer } from 'buffer';
import { nanoid } from 'nanoid';
import './styles.css';
import { useDispatch } from 'react-redux';
import { addFiles } from '../../store/action/files';

function UploadSnapshot({ id, refresh, setFilesWaiting, containerId = 'comment' }) {
    let uploadUrl = '';
    const _fetch = useFetch();
    const [filesUpload, setFilesUpload] = useState([]);
    const dispatch = useDispatch();

    const beforeUpload = async (file) => {
        let name = file.name.split('.')[0]
        let ext = file.name.split('.')[1];
        name = `${name}-${moment().valueOf()}.${ext}`
        let url = await _fetch(
            `${process.env.REACT_APP_API_URL}/file/get_upload_url?name=${name}&query=${id}`
        );
        if (url.status === 200) {
            url = await url.json();
            uploadUrl = url.response;;
        } else {
            console.log('url to upload generation failed');
            throw new Error('url to upload generation failed');
        }
    };

    useEffect(() => {
        async function init2() {
            document.getElementById(containerId).onpaste = function (event) {
                var items = (
                    event.clipboardData || event.originalEvent.clipboardData
                ).items;
                // console.log('ps0', { items }); // might give you mime types
                for (var index in items) {
                    var item = items[index];
                    if (item.kind === 'file') {
                        var blob = item.getAsFile();
                        var reader = new FileReader();
                        // eslint-disable-next-line no-loop-func
                        reader.onload = async function (event) {
                            // console.log('ps1', blob)
                            // console.log("ps2", event.target.result); // data url!
                            let callMeLater = async (commentId) => {
                                await beforeUpload(blob);
                                await upload(
                                    event.target.result,
                                    true,
                                    uploadUrl,
                                    commentId
                                );
                                refresh();
                                console.log('refreshing');
                            };
                            let customFileObject = {
                                fileName: blob.name,
                                isCanceled: false,
                                onUpload: callMeLater,
                                onCancel: function () {
                                    console.log(
                                        'cancel pressed for ',
                                        this.fileName
                                    );
                                },
                            };
                            //NOTE: array reference/state immutability/state comparison failing !
                            setFilesUpload((val) => [...val, customFileObject]);
                        };
                        reader.readAsDataURL(blob);
                    }
                }
            };
        }

        try {
            init2();
        } catch {
            console.log('error pasting screenshot');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.myFileUpload = (commentId) => {
            filesUpload.forEach(async (e) => {
                await e.onUpload(commentId);

                // e.onUpload()
            });

            setFilesUpload([]);
        };
    });
    useEffect(
        (e) => {
            setFilesWaiting(Boolean(filesUpload.length));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filesUpload]
    );

    const upload = async (data, screenShot = false, url = uploadUrl, commentId) => {
     
        try {
            let options;
            let contentType = "";
            // check if screenshot
            if (!screenShot) {
                let file = data.file;
                contentType = file.type;
                options = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': contentType,
                    },
                    body: file,
                };
            } else {
                // custom upload for ctrl+V screenShots ,Reason: getting Image in base64 format :AWS different way of upload for base64
                console.log('trying to upload screenshot');
                contentType = 'image/jpeg';
                try {
                    var buf = new Buffer.from(
                        data.replace(/^data:image\/\w+;base64,/, ''),
                        'base64'
                    );
                    options = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': contentType,
                        },
                        body: buf,
                    };
                } catch (e) {
                    console.warn('error uploading screenshot', String(e));
                }
            }

            let res = await fetch(url, options);
            // console.log('url is ', url);
            if (res.status === 200) {
                try {
                    let parsedUrl = new URL(url);
                    let pathname = parsedUrl.pathname.slice(1);
                    // console.log(pathname);
                    let uploadedRes = await _fetch(
                        `${process.env.REACT_APP_API_URL}/file`,
                        {
                            method: 'POST',
                            body: {
                                query: id,
                                path: pathname,
                                commentId: commentId,
                                type: contentType
                            },
                        }
                    );
                    if (uploadedRes.status === 200) {
                        uploadedRes = await uploadedRes.json();
                        console.log(uploadedRes);
                        dispatch(addFiles(uploadedRes.response));
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div>
            <Space>
                {filesUpload.map((e, index) => (
                    <Tag
                        closable
                        key={nanoid()}
                        className='pointer m-1'
                        style={{
                            padding: '9px',
                            borderRadius: '5px',
                            maxWidth: '180px',
                        }}
                        onClose={() =>
                            setFilesUpload((e) => {
                                delete e[index];
                                return e.filter((sm) => Boolean(sm));
                            })
                        }
                    >
                        <span
                            style={{ display: 'inline' }}
                            className='myDIV border'
                        //   className='border'
                        //   style={{ margin: '100px', display: 'block' }}
                        >
                            <text>{e.fileName}</text>
                        </span>
                        {/* <AiFillCloseSquare size={15} className='hide border' /> */}
                    </Tag>
                ))}
            </Space>
        </div>
    );
}

export default UploadSnapshot;
