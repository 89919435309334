import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Drawer } from 'antd';
import Sidenav from './Sidenav';
import Header from './Header';

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {

	const [visible, setVisible] = useState(false);
	const openDrawer = () => setVisible(!visible);

	let { pathname } = useLocation();
	pathname = pathname.replace('/', '');

	return (
		<Layout
			className={`layout-dashboard ${pathname === 'profile' ? 'layout-profile' : ''
				} ${pathname === 'rtl' ? 'layout-dashboard-rtl' : ''}`}
		>
			<Drawer
				title={false}
				placement='left'
				closable={false}
				onClose={() => setVisible(false)}
				open={visible}
				key='right'
				width={300}
				className={`drawer-sidebar ${pathname === 'rtl' ? 'drawer-sidebar-rtl' : ''
					} `}
			>
				<Layout
					className={`layout-dashboard ${pathname === 'rtl' ? 'layout-dashboard-rtl' : ''
						}`}
				>
					<Sider
						trigger={null}
						width={300}
						theme="light"
						className="sider-primary ant-layout-sider-primary"
						style={{ background: "transparent" }}
					>
						<Sidenav />
					</Sider>
				</Layout>
			</Drawer>
			<Sider
				breakpoint="lg"
				collapsedWidth="0"
				onCollapse={(collapsed, type) => {
					console.log(collapsed, type);
				}}
				trigger={null}
				width={300}
				theme="light"
				className="sider-primary ant-layout-sider-primary"
				style={{ background: "transparent" }}
			>
				<Sidenav />
			</Sider>
			<Layout>
				<AntHeader>
					<Header
						onPress={openDrawer}
						name={pathname}
						subName={pathname}
					/>
				</AntHeader>
				<Content className="content-ant pb-4">{children}</Content>
				{/* <Footer /> */}
			</Layout>
		</Layout>
	);
}

export default Main;
