import React, { useState } from "react";
import { Button, Col, Modal, Row, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { getQueries } from "../../store/action/queries";
import CreateCompany from "../../pages/createCompany";
import CreateClient from "../../pages/createClient";
import CreateQuery from "../../pages/createQuery";
import RequestEntity from "../../pages/requestEntity";
import RequestFy from "../../pages/requestFy";
import { user_role } from "../../consts";
import SendDocumentsModal from "../files/SendDocumentsModal";

export default function ToolBar({ color = "#1890ff" }) {
  const dispatch = useDispatch();
  const [showQuery, setShowQuery] = useState(false);
  const [addCompanyModalVisible, setAddCompanyModalVisible] = useState(false);
  const [addClientModalVisible, setAddClientModalVisible] = useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [openEntityModal, setOpenEntityModal] = useState(false);
  const [openFyModal, setOpenFyModal] = useState(false);


  let { role } = useSelector((st) => st.user);
  const { selected: selectedCompany } = useSelector((st) => st.company);
  const { selected } = useSelector((st) => st.fy);
  const [, setSeed] = useState(1);
  const reset = () => {
    setSeed(Math.random());
  };

  const refresh = () => {
    dispatch(getQueries(selectedCompany._id, selected._id));
    reset();
  };


  const Buttons = () => {
    return (
      <>
        {
          <React.Fragment>
            {role === user_role.ADMIN && (
              <Col span={24}>
                <Button
                  type="primary"
                  className="ant-btn-sm ant-btn-block"
                  onClick={() => setAddCompanyModalVisible(true)}
                >
                  Australian Firm
                </Button>
              </Col>
            )}
            {role !== user_role.COMPANY && (
              <Col span={user_role.ADMIN === role ? 12 : 24}>
                <Button
                  style={{ textAlign: "center", flex: 1 }}
                  type="primary"
                  className="ant-btn-sm ant-btn-block"
                  onClick={() => setAddClientModalVisible(true)}
                >
                  Entity
                </Button>
              </Col>
            )}
          </React.Fragment>
        }
        <Col span={user_role.ADMIN === role ? 12 : 24}>
          <Button
            type="primary"
            className="ant-btn-sm ant-btn-block"
            onClick={() => setShowQuery(true)}
          >
            {role === user_role.ADMIN ? "Query" : "Review Points"}
          </Button>
        </Col>
        {role !== user_role.ADMIN && role !== user_role.ACCOUNTANT && (
          <Row gutter={8}>
            <Col span={12}>
              <Button

                type="primary"
                className="ant-btn-sm ant-btn-block"
                onClick={() => setOpenEntityModal(true)}
              >
                Entity
              </Button>
            </Col>
            <Col span={12}>
              <Button

                type="primary"
                className="ant-btn-sm ant-btn-block"
                onClick={() => setOpenFyModal(true)}
              >
                Financial Year
              </Button>
            </Col>
          </Row>
        )}
        <Col span={24}>
          <Button
            style={{ padding: "0px", textAlign: "center" }}
            type="primary"
            className="ant-btn-sm ant-btn-block"
            onClick={() => setOpenDocumentModal(true)}
          >
            Upload Documents
          </Button>
        </Col>


      </>
    );

  }


  return (
    <div style={{ minWidth: '10vw' }} className="aside-footer">
      <div
        className="footer-box"
        style={{
          background: color,
        }}
      >
        <Typography.Title level={4} style={{ color: "white" }}>
          Create <PlusOutlined size={15} />
        </Typography.Title>

        {user_role.ADMIN !== role ? <div className="btn-div" gutter={[8, 8]}>
          <Buttons />
        </div> : <Row gutter={[8, 8]} >
          <Buttons />
        </Row>}

      </div>
      <CreateQuery refresh={refresh} show={showQuery} setShow={setShowQuery} />
      <CreateCompany
        visible={addCompanyModalVisible}
        setVisible={setAddCompanyModalVisible}
      />
      <CreateClient
        visible={addClientModalVisible}
        setVisible={setAddClientModalVisible}
      />
      <SendDocumentsModal
        open={openDocumentModal}
        setOpen={setOpenDocumentModal}
      />
      <RequestEntity
        visible={openEntityModal}
        setVisible={setOpenEntityModal}
      />
      <RequestFy
        visible={openFyModal}
        setVisible={setOpenFyModal}
      />
    </div>
  );
}
