import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVideos, deleteVideo } from "../store/action/video";
import { DeleteOutlined } from "@ant-design/icons";
import { Loader } from "../components/loader";
import VideoListModal from "../components/videoListModal";
import { user_role } from "../consts";

export default function Help() {
  const [isModalOpen, setIsModelOpen] = useState(false);
  const dispatch = useDispatch();

  const { videos, loading, error } = useSelector((state) => state.videos);
  const { role } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getVideos());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      console.log(error);
      message.error(error);
    }
  }, [error]);

  if (!videos || loading) {
    return <Loader />;
  }

  return (
    <div>
      {user_role.ADMIN === role ? (
        <>
          <Button
            type="primary"
            ghost
            htmlType="submit"
            onClick={() => setIsModelOpen(true)}
            className="helpBtn"
          >
            EDIT MODE
          </Button>
          <VideoListModal
            setIsModelOpen={setIsModelOpen}
            isModalOpen={isModalOpen}
          />
        </>
      ) : (
        <></>
      )}

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {videos &&
          videos.map((el, i) => {
            const params = new URL(el.uri)
            let id = "";
            if (params.host === "youtu.be") {
              id = params.pathname
            } else {
              const search = params.searchParams;
              id = search.get("v");
              id = "/" + id;
            }
            return (
              <div style={{ margin: "20px 0px" }} key={el._id}>
                <iframe
                  width="380"
                  height="250"
                  src={`https://www.youtube.com/embed${id}`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
                <div className="d-flex justify-content-around">
                  <h4 style={{ width: "320px" }}>{el.title}</h4>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  );
}
