import { Form, message, Modal, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CommentView from "../components/commentView";
import useFetch from "../_fetch";
export default function SendMail({
    show, setShow
}) {

    useEffect(() => {
    }, [show])

    const _fetch = useFetch();

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const { Option } = Select;

    const { selected: selectedClient, all: all_clients } = useSelector(st => st.client);

    const { selected, grouped: all_fys } = useSelector(st => st.fy);

    async function submit(values) {
        setLoading(true);
        try {
            if (values.cashInValue >= 0 && values.cashInCurrency) {
                const cashIn = {
                    value: values.cashInValue,
                    currency: values.cashInCurrency,
                }
                delete values.cashInValue;
                delete values.cashInCurrency;
                values.cashIn = cashIn;
            }
            if (values.cashOutValue >= 0 && values.cashOutCurrency) {
                const cashOut = {
                    value: values.cashOutValue,
                    currency: values.cashOutCurrency,
                };
                delete values.cashOutValue;
                delete values.cashOutCurrency;
                values.cashOut = cashOut;
            }
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/query`, {
                method: "POST",
                body: {
                    ...values,
                    descriptionV2: values.descriptionV2 ? [{ insert: values.descriptionV2 }] : []
                }
            });
            await res.json();
            if (res.status === 200) {
                message.success('Query created successfully');
                setShow(false);

            } else {
                message.error('Some error occurred while opening query');
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // console.log('Selected Client is:', selectedClient);

    return (
        <Modal
            open={show}
            footer={null}
            title="Send New Email"
            width={700}
            destroyOnClose
            onCancel={() => {
                form.resetFields();
                setShow(false)
            }}
        >
            <Form layout="vertical"
                {...layout}
                onFinish={submit}
                form={form}
                initialValues={{ financialYear: selected?._id, client_id: selectedClient?._id }}
            >
                <Form.Item
                    label="Entity"
                    name="client_id"
                    initialValue={selectedClient?._id}
                >
                    <Select value={selectedClient?._id}>
                        {
                            all_clients.map(e => (
                                <Option value={e._id} key={e._id}>{e.title}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.client_id !== currentValues.client_id}
                >
                    {({ getFieldValue }) =>
                        <>
                            <Form.Item
                                label="Financial Year"
                                name="financialYear"
                                initialValue={selected._id}
                            >
                                <Select value={selected._id}>
                                    {
                                        all_fys.find(e => e._id === getFieldValue('client_id'))?.financial_year.map(e => (
                                            <Option value={e._id} key={e._id}>{moment(e.start).format('YYYY')} - {moment(e.end).format('YY')}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </>
                    }
                </Form.Item>
                <Form.Item
                    shouldUpdate={(prevValues, currentValues) => prevValues.financialYear !== currentValues.financialYear}
                >
                    {({ getFieldValue }) =>
                        <CommentView fy={getFieldValue("financialYear")} setShow={setShow} />
                    }
                </Form.Item>




            </Form>


        </Modal>
    )
}