import { Row, Col, Table, Card, message, Typography, Button, Input, Modal } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateClient } from '../store/action/client';
import RequestFyDoc from './RequestFyInDoc';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { getClients } from '../store/action/client';
import { getQueries } from "../store/action/queries";
import ProposedBadge from '../components/badge';
import Financialyears from "./financial_years";
import { changeSelectedClient } from "../store/action/client";
import SearchBox from '../components/SearchBox';
import DropDown from './DropDown';
import useFetch from "../_fetch";
import { user_role } from '../consts';
import PermissionModal from '../components/PermissionModal';
import { Loader } from "../components/loader";
import { IoRecordingSharp } from 'react-icons/io5';
import { useNavigate } from "react-router-dom";
import {
    getFinancialYears,
} from "../store/action/fy";
import RequestFy from './requestFy';

const normalizeString = (str) => {
    return str?.trim().replace(/\s+/g, '');
};

const normalizeEntityName = (str) => {
    return str?.trim(); // Removes spaces only before and after
};

export default function Entities() {
    let dispatch = useDispatch();
    const [dataLength, setDataLength] = useState(0);
    const placeholder = "Search Entities..."
    const [editTitle, setEditTitle] = useState(false)
    const [editAbn, setEditAbn] = useState(false)
    const [show, setShow] = useState([]);
    const [title, setTitle] = useState("");
    const [abn, setAbn] = useState("");
    const [permissionModalOpen, setPermissionModalOpen] = useState(null);
    const [queries, setQueries] = useState([]);
    const [loading, setLoading] = useState(true);
    const { selected: selectedCompany } = useSelector(st => st.company);
    const { all: client } = useSelector(st => st.client);
    const { selected: selectedClient } = useSelector(st => st.client);
    const [showData, setShowData] = useState([]);
    const { all: fy } = useSelector(st => st.fy);
    const [buttonVisible, setButtonVisible] = useState('')
    const [openFyModal, setOpenFyModal] = useState(false);

    const user = useSelector(st => st.user)
    const _fetch = useFetch()

    const handleEdittitle = (record) => {
        setTitle(record?.title);
        setEditTitle(record?._id);

    };
    const handleEditabn = (record) => {
        setAbn(record?.abn);
        setEditAbn(record?._id);
    };

    const navigate = useNavigate();



    

    const columnData = [
        {
            title: `Entity (${dataLength})`,
            key: "entity",
            width: 250,
            render: (record) => (
                <div className ='pointer custom-blue-text' style={{ fontWeight: "600", color:"#blue"}}>
                   
                    {editTitle === record?._id ? (
                        <Input
                            value={title}
                            size='small'
                            autoFocus
                         
                            style={{
                                fontSize: '14px',
                                width: "100%",
                                border: '1px solid #ccc',
                            }}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            onBlur={() => {
                                setTitle(normalizeEntityName(title));
        
                            }}
                        />
                    ) : (
                        record.status === 'PROPOSED' ? (
                            <div style={{display:'flex'}}>
                                <p className='my-2 mx-2' style={{ color: 'blue', fontWeight: '600' }}>{normalizeString(record?.title)}</p>
                                <ProposedBadge content="proposed" textColor="#CC9900" bgColor="rgba(204, 153, 0, 0.1)" />
                            </div>
                        ) : (
                            <p className='my-2'>{normalizeEntityName(record?.title)}</p>
                        )
                    )}
                </div>
            ),
            onCell: (record) => ({
                onClick: () => {
                  if (!editAbn && !editTitle) {
                    navigate(
                      `/financial_years?entity=${record._id}&company=${selectedCompany._id}`
                    );
                  }
                },
              }),
                },
        
        {
            title: `ABN ID`,
            key: "abn",
            render: (record) => (
                <div className='pointer'>
                    {
                        editAbn === record?._id ? (
                            <Input
                                value={abn}
                                size='small'
                           
                                style={{
                                    fontSize: '14px',
                                    width: "100%",
                                    border: '1px solid #ccc',
                                    
                                }}
                                autoFocus
                                onChange={(e) => {
                                    setAbn(e.target.value);
                                }}
                                onBlur={() => {
                                    setAbn(normalizeString(abn));
                                  
                                }}
                            />
                        ) :
                        <p>
                            {normalizeString(record?.abn)}
                            </p>
                    }
                </div>
            ),
            onCell: (record) => ({
                onClick: () => {
                    if (!editAbn && !editTitle) {
                        navigate(
                            `/financial_years?entity=${record._id}&company=${selectedCompany._id}`
                        );
                    }
                },
            }),

        },
        {
            title: `No. of Financial Years`,
            dataIndex: "fy_count",
            key: "fy_count",
            render: (text, record) => (
                <div className="pointer">
                    <span onClick={() => navigate(`/financial_years?entity=${record?._id}&company=${selectedCompany._id}`)}>
                        {text}
                    </span>
                    <button
                        onClick={() => navigate(`/financial_years?entity=${record?._id}&company=${selectedCompany._id}`)}
                        style={{
                            marginLeft: '40px',
                            padding: '5px 10px',
                            backgroundColor: '#1890ff',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Show FY
                    </button>
                </div>
            ),
            onCell: (record) => ({
                onClick: () => {
                    navigate(`/financial_years?entity=${record?._id}&company=${selectedCompany._id}`);
                },
            }),
        },
        {
            title: `No. of Queries`,
            key: "queries",
            dataIndex: "query_count",
            render: (text, record) => (
                <div className="pointer" onClick={() => navigate(`/financial_years?entity=${record?._id}&company=${selectedCompany._id}`)}>
                    {text}
                </div>
            ),
            onCell: (record) => ({
                onClick: () => {
                    navigate(`/financial_years?entity=${record?._id}&company=${selectedCompany._id}`);
                },
            }),
        },
        {
            title: `Edit`,
            key: "edit",
            render: (text, record) => (
                <div>
                    {
                        user.role === user_role.ADMIN && user_role.ACCOUNTANT && (

                            buttonVisible === record?._id ?
                                <div className='d-flex flex-row align-items-center justify-content-around w-100'>
                                    <Button
                                        onClick={() => {
                                            setEditAbn(false);
                                            setEditTitle(false);
                                            setButtonVisible('');
                                        }}
                                        size='small'
                                        
                                        style={{ fontSize: '10px', marginRight: '5px' }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type='primary'
                                        onClick={() => {
                                            UpdateClient(record?._id);
                                            setEditTitle(false);
                                            setEditAbn(false);
                                            setButtonVisible('');
                                        }}
                                        size='small'
                                        style={{ fontSize: '10px' }}
                                    >
                                        Update
                                    </Button>
                                </div>
                                :
                                 <Button
                                type='text'
                                className='my-1'
                                onClick={
                                    () => {
                                        setButtonVisible(record?._id)
                                        handleEdittitle(record)
                                        handleEditabn(record)
                                        // }
                                    }
                                }                               >
                                    <EditOutlined size={15} />
                                </Button>
                        )
                    }

                </div>
            )
        },

        {
            title: `+ FY`,
            key: "fy",
            render: (record) => (
                <div>

                    {
                        user.role === user_role.ADMIN && (
                            <DropDown client_id={record?._id} />
                        )}

                    {
                        user.role === user_role.ACCOUNTANT && (
                            <DropDown client_id={record?._id} />
                        )}
                    {
                        user.role !== user_role.ADMIN && user_role.ACCOUNTANT && (

                            <RequestFyDoc client_id={record?._id}
                                Abn={record?.abn} />

                        )
                    }
                </div>
            )
        },

    ];

    if (user.role === user_role.ADMIN) {
        columnData.push({

            title: `Trash`,
            key: "trash",
            render: (record) => (
                <div>


                    <Button
                        type='text'
                        className='ms-3'
                        onClick={() => {
                            setPermissionModalOpen(record)
                        }}
                    >
                        <DeleteOutlined size={15} />
                    </Button>


                </div>
            )
        });
    }


    async function initClientAndQuery() {
        if (selectedCompany) {
            try {
                setLoading(true);
                let clients = await dispatch(await getClients(selectedCompany._id));
                await dispatch(await getFinancialYears(Array.isArray(clients) && clients.length ? clients.map(e => e._id) : []));
                let get = await dispatch(getQueries(selectedCompany._id));
                setQueries(get)
            } catch (e) {
                message.error('Some error occurred while fetching data');
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
    }

    const UpdateClient = async (client_id) => {
        try {
            let updatedClient = await _fetch(`${process.env.REACT_APP_API_URL}/client/${client_id}`, {
                method: 'PATCH',
                body: {
                    "title": title,
                    "abn": abn
                }
            });
            let resdata = await updatedClient.json();
            if (resdata.status === 200) {
                message.success('Entity updated successfully')
                dispatch(updateClient(resdata.response));
                setShowData((prevData) =>
                    prevData.map((item) =>
                        item._id === client_id
                            ? { ...item, title: title, abn: abn }
                            : item
                    )
                );
            } else {
                message.error('Some error occurred while updating entity')
            }
        } catch (e) {
            message.error('Some error occurred while updating entity')
            console.log(e);
        }
    }

    async function deleteEntity() {

        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/client`, {
                method: 'DELETE',
                body: {
                    id: permissionModalOpen._id,
                    user_id: user._id
                }
            });
            let resJson = await res.json();
            if (resJson.status === 200) {
                initClientAndQuery();
                message.success('Entity deleted successfully');
            } else {
                message.error(resJson.response);
            }
        } catch (err) {
            message.error('Some error occurred while deleting entity');
            console.log(err);
        } finally {
            setPermissionModalOpen(null);
        }
    }

    useEffect(() => {
        initClientAndQuery();
    }, [selectedCompany])

    useEffect(() => {
        if (show?.length) {
            let mapData = show.map((el) => {
                let fy_count = fy.filter(e => e.client_id === el._id).length;
                let query_count = queries.filter(e => e.client?._id === el._id).length;
                return {
                    ...el,
                    fy_count: fy_count,
                    query_count: query_count,
                    _id: el?._id,
                }
            });

            mapData.sort((a, b) => {
                const titleA = normalizeString(a.title).toLowerCase();
                const titleB = normalizeString(b.title).toLowerCase();
                return titleA.localeCompare(titleB);
            });

            setShowData(mapData)
            setDataLength(show.length)
        }
    }, [show?.length])

    return (
        <>
            {
                loading ? <Loader /> :
                    <div>
                        <Row gutter={[24, 0]}>
                            <Col xs={24} className="mb-<FySidebar 24">
                                <Card
                                    className='header-solid h-full'
                                    bordered={true}
                                    bodyStyle={{ paddingTop: 0 }}
                                    title={[
                                        <div className='d-flex flex-col'>
                                            <div className="flex-fill search d-flex">
                                                <div className="d-flex flex-fill justify-content-center">
                                                    <SearchBox all={client} setshow={setShow} name={placeholder} />
                                                </div>
                                            </div>
                                        </div>
                                    ]}
                                >

                                    <Col>
                                        <Table
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: async (event) => {
                                                        await dispatch(changeSelectedClient(record));
                                                    },
                                                };
                                            }}
                                            columns={columnData}
                                            dataSource={showData}
                                            pagination={false}
                                            sortDirections={["descend", "ascend"]}
                                            loading={loading}
                                        />
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                        <PermissionModal
                            visible={permissionModalOpen !== null}
                            setVisible={setPermissionModalOpen}
                            onSuccess={deleteEntity}
                            child={
                                <p>Are you sure you want to delete this <b>Entity</b>? This will delete all the <b>Financial Years</b>, <b>Queries</b>, and <b>Attachments</b> related to this Entity.</p>
                            }
                        />
                        <RequestFy
                            visible={openFyModal}
                            setVisible={setOpenFyModal}
                        />
                    </div>

            }
        </>

    );

}