export default function Privacy() {
    return (
        <div className="">
            <h1 style={{ fontSize: 16, fontWeight: 600 }}>Privacy Policy for Efiles</h1>
            <p style={{ fontSize: 12 }}>Privacy Policy for EfilesThis Privacy Policy describes how we collect, use, and share information that we obtain from visitors to our website, customers who use our website for queries and document management services, and other individuals/businesses who interact with us (collectively, “you”). We are committed to ensuring that all personal information is handled in accordance with the Australian Privacy Principles (APPs) set out in the Privacy Act 1988 (Cth).</p>
            <h1 style={{ fontSize: 14, fontWeight: 600 }}>1. Information We Collect</h1>
            <h1 style={{ fontSize: 14, fontWeight: 600 }}>We may collect the following types of information:</h1>
            <ul>
                <li style={{ fontSize: 12 }}>Personal Information: We may collect personal information such as your name, email address, phone number, postal address, and other contact details</li >
                <li style={{ fontSize: 12 }}>Business Information: We may collect business information such as your company name, job title, and other professional details.</li>
                <li style={{ fontSize: 12 }}>Usage Information: We may collect information about how you use our website and our services, including the pages you visit, the features you use, and the content you access.</li>
                <li style={{ fontSize: 12 }}>Device Information: We may collect information about the device you use to access our website or our services, including the type of device, its operating system, and other technical details.</li>

            </ul>

            <h1 style={{ fontSize: 14, fontWeight: 600 }}> 2. How We Use Your Information:</h1>
            <h1 style={{ fontSize: 14, fontWeight: 600 }}>We may use your information for the following purposes:</h1>
            <ul>
                <li style={{ fontSize: 12 }}>To provide and improve our services: We use your information to provide you with our document management services, to communicate with you about your account, and to improve our services.</li >
                <li style={{ fontSize: 12 }}>To personalize your experience: We use your information to personalize your experience on our website, such as by showing you content and features that are tailored to your interests.</li>
                <li style={{ fontSize: 12 }}>To send you marketing communications: We may send you marketing communications about our services, promotions, and other news that we think may be of interest to you.</li>
                <li style={{ fontSize: 12 }}>To comply with legal obligations: We may use your information to comply with legal and regulatory requirements, such as tax and accounting obligations.</li>

            </ul>

            <h1 style={{ fontSize: 14, fontWeight: 600 }}> 3.  How We Share Your Information</h1>
            <h1 style={{ fontSize: 14, fontWeight: 600 }}>We may share your information with the following types of third parties:</h1>
            <ul>
                <li style={{ fontSize: 12 }}>Service Providers: We may share your information with service providers who help us to provide our services, such as payment processors, data storage providers, and email service providers.</li >
                <li style={{ fontSize: 12 }}>Business Partners: We may share your information with business partners who provide complementary services, such as document scanning or shredding services.</li>
                <li style={{ fontSize: 12 }}>Legal Obligations: We may share your information in response to a subpoena, court order, or other legal obligation, or to protect our rights, property, or safety or that of our users or others.</li>
                <li style={{ fontSize: 12 }}>Business Transfers: We may share your information in connection with a merger, acquisition, or sale of our business or assets.</li>

            </ul>

            <h1 style={{ fontSize: 14, fontWeight: 600 }}> 4.Your Choices</h1>
            <h1 style={{ fontSize: 14, fontWeight: 600 }}>You may have certain choices about how we use your information:</h1>
            <ul>
                <li style={{ fontSize: 12 }}>Opt-Out of Marketing Communications: You may opt-out of receiving marketing communications from us by following the instructions in the communication.</li >
                <li style={{ fontSize: 12 }}>Do Not Track: Our website does not respond to Do Not Track signals from your browser.</li>
                <li style={{ fontSize: 12 }}>Cookies: You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies.</li>


            </ul>

            <h1 style={{ fontSize: 14, fontWeight: 600 }}> 5. Security</h1>
            <h1 style={{ fontSize: 12 }}>We take reasonable measures to protect your information from unauthorized access, use, and disclosure. However, no method of transmission over the Internet or method of electronic storage is 100% secure.</h1>

            <h1 style={{ fontSize: 14, fontWeight: 600 }}>6. Access to and Correction of Personal Information:</h1>
            <h1 style={{ fontSize: 12 }}>You have the right to access the personal information we hold about you and to request that any inaccuracies be corrected. To request access to your personal information or to make a correction, please contact us.</h1>

            <h1 style={{ fontSize: 14, fontWeight: 600 }}>7. Changes to Our Privacy Policy:</h1>
            <h1 style={{ fontSize: 12 }}>We may update our privacy policy from time to time, and any changes will be posted on our website. We encourage you to review our privacy policy regularly.</h1>

            <h1 style={{ fontSize: 14, fontWeight: 600 }}>8.  Contact Us:</h1>
            <h1 style={{ fontSize: 12 }}>If you have any questions or concerns regarding our privacy policy or the handling of your personal information, please contact us using the contact details provided on our website.</h1>
            <h1 style={{ fontSize: 12 }}>This Privacy Policy was last updated on 20/04/2023</h1>
        </div >
    )
}