import React from 'react';
import { Row, Col, Typography, } from 'antd';

const { Title } = Typography;

export default function Page404() {
	return (
		<Row gutter={[24, 0]} justify="space-around">
			<Col
				xs={{ span: 24, offset: 0 }}
				lg={{ span: 6, offset: 2 }}
				md={{ span: 12 }}
			>
				<Title className="mb-15">404 Not Found</Title>
				<Title
					className="font-regular text-muted"
					level={5}
				>
					The page you requested was not found.
				</Title>
			</Col>
			{/* <Col
				className="sign-img"
				style={{ padding: 12 }}
				xs={{ span: 24 }}
				lg={{ span: 12 }}
				md={{ span: 12 }}
			>
				<img src={signinbg} alt="" />
			</Col> */}
		</Row>
	);
}
