import { Button, List, Skeleton, Typography } from 'antd'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import useFetch from '../_fetch';
import { useNavigate } from 'react-router-dom';

export default function Mail({ canNavigate, openSendMailModal, height, width, setShowInboxPopover }) {
    const _fetch = useFetch();
    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    const limit = 10;

    async function init() {
        let commRes = await _fetch(
            `${process.env.REACT_APP_API_URL}/comment?fy=all&page=${page}&limit=${limit}`
        );


        if (commRes.status === 200) {
            let comment = await commRes.json();
            // console.log("comments", comment)
            setHasMore(comment.response.hasMore);
            setData(a => [...a, ...comment.response.comment]);
        }

    }

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <div class='position-relative'>
            <div class='d-flex justify-content-between' style={{ width: `${width}px` }}>
                <h2>Inbox</h2>
                <Button
                    onClick={() => {
                        openSendMailModal();
                    }}

                >
                    Send New Email
                </Button>
            </div>
            <div style={{ overflow: "auto", height: `${height - 50}px`, width: `${width}px` }} id="scrollableDiv">
                <InfiniteScroll
                    dataLength={data.length}
                    next={() => setPage(page + 1)}
                    hasMore={hasMore}
                    loader={<Skeleton.Input active size="small" block />}
                    scrollableTarget="scrollableDiv"
                >

                    <List
                        dataSource={data}
                        renderItem={(item, i) => (
                            <List.Item key={i}
                                onClick={() => {
                                    if (item.fy !== null && item.fy._id && item.fy.client._id && item.fy.company) {
                                        setShowInboxPopover(false)
                                        // console.log(`/queries?fy=${item.fy._id}&entity=${item.fy.client._id}&company=${item.fy.company}&showEmail=true`)
                                        navigate(`/queries?fy=${item.fy._id}&entity=${item.fy.client._id}&company=${item.fy.company}&showEmail=true`)
                                    }
                                }}
                                className={canNavigate && "pointer"}
                            >
                                {item.created_by.first_name}&nbsp;
                                {item.created_by.last_name
                                }&nbsp; commented &nbsp;"{item.text}"&nbsp; in <span style={{ fontWeight: 600 }}>{item.fy?.client?.title}</span>&nbsp; in
                                <strong> fy {moment(item.fy?.start).format("YYYY") - 1}-{moment(item.fy?.start).format("YYYY")}</strong>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </div >
    )
}