import { useState } from 'react';
import { message } from 'antd';
import useFetch from '../_fetch';
import DropDown from './DropDown';
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedClient, getClients } from '../store/action/client';
import { DatePicker } from 'antd';
import { Form, Input, Modal, Select, Upload } from "antd";
import dayjs from 'dayjs';

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

export default function RequestFy({ visible, setVisible }) {

    const _fetch = useFetch();
    const [selectedDate, setSelectedDate] = useState();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const { selected: selectedCompany } = useSelector(st => st.company);
    const [title_id, setTitle_id] = useState();
    const [Abn_id, setAbn_id] = useState();
    const [Client_id, setClient_id] = useState();
    const [fy, setFy] = useState('');
    let dispatch = useDispatch();
    const [form] = Form.useForm();
    const { selected: selectedClient, all: all_clients } = useSelector(
        (st) => st.client
    );
    const handleDateChange = (date) => {

        setSelectedDate(`${(dayjs(date).subtract(1, 'year').format('YYYY'))}-${(dayjs(date).format('YYYY'))}`);

    };

    async function submit() {
        setLoading(true);
        let start = dayjs(selectedDate).subtract(1, 'year').format("MM/YYYY");
        let end = dayjs(selectedDate).format("YYYY");
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/fy
			`, {
                method: "POST",
                body: {
                    client_id: Client_id,
                    company_id: selectedCompany._id,
                    title: title_id,
                    abn: Abn_id,
                    fy: ` ${dayjs(selectedDate).subtract(1, 'year').format("YYYY")}-${dayjs(selectedDate).format("YYYY")}`,
                    start: start,
                    end: end,
                    fy_status: "PROPOSED"

                }
            })

            let response = await res.json();
            if (res.status === 200) {
                message.success('FY requested successfully');

                dispatch(getClients(selectedCompany._id))

            } else {
                if (response.response === "company_id is required") {
                    message.error("Please add a company before adding a client")
                } else {
                    message.error(response.response);
                };
            }
        }
        catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            visible={visible}
            onCancel={() => { setVisible(false); setFy(null); setData(null); }}
            title={<h6 className="font-semibold m-0">Request Financial Year</h6>}
            okText="Request Financial Year" onOk={submit}
            destroyOnClose
            okButtonProps={{ loading: loading }}
        >

            <Form
                layout="vertical"
                {...layout}
                form={form}
                onFinish={submit}
                onChange={(e) => {
                    console.log(e)
                }}
            >
                <Form.Item
                    label={<div className="finanicalYears" >
                        <p
                            style={{ width: "200px", alignItems: "center", justifyContent: "center", marginBottom: "0px" }}>
                            Entity</p></div>
                    }

                    name="client_id"
                    initialValue={selectedClient?._id}
                >
                    <Select
                        value={selectedClient?._id}
                        onChange={(e) => {
                            let selectedclient = all_clients.filter((data) => e.toString() === data._id?.toString());
                            form.setFieldsValue({ Abn: selectedclient[0].abn });
                            setTitle_id(selectedclient[0].title);
                            setAbn_id(selectedclient[0].abn);
                            let client_id = selectedclient[0]._id;
                            setClient_id(client_id);

                        }
                        }
                        showSearch
                        optionFilterProp="label"
                    >
                        {all_clients
                            ?.filter((el) => el.title)
                            .map((e) => (
                                <Option
                                    value={e._id}
                                    key={e._id}
                                    label={e.title}
                                >

                                    {e.title}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="ABN ID:"
                    name="Abn">
                    <Input
                        readOnly />
                </Form.Item>
                <Form.Item>
                    <div>
                        <style jsx>{`
      .fyyeaR .ant-picker-input > input::placeholder {
        color:#1677ff; 
      }
    `}</style>
                        <DatePicker
                            selected={selectedDate}
                            className="fyyeaR"
                            placeholder="+ Add Fy"
                            onChange={handleDateChange}
                            picker='year'
                            bordered={false}
                            suffixIcon=""
                        />
                        {/* <p>FY: {selectedDate}</p> */}

                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
}