import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export function Loader({ size = 200 }) {

    const spinIcon = <LoadingOutlined style={{ fontSize: size }} className='text-primary' spin />;
    return (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center" style={{ fontSize: 200 }}>
            <Spin indicator={spinIcon} />
        </div>
    )
}
