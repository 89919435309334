const initState = {
    _id: [],
    selected: {},
    data: {},
    all: [],
    grouped: []
}

export const financialYearReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_CLIENT': {
            return ({
                ...state,
                ...action.payload
            });
        }
        case 'UPDATED_FROM_ALL_DATA': {
            let data = state.grouped.map((el) => {
                if (el._id === action.payload.client) {
                    let k = el.financial_year.map((fy) => {
                        if (fy._id === action.payload._id) {
                            console.log("DATA TRIGGER")
                            console.log(fy);
                            return ({
                                ...fy,
                                assigned_to: {
                                    first_name: action.payload.assigned_to.first_name,
                                    last_name: action.payload.assigned_to.last_name,
                                    _id: action.payload.assigned_to._id
                                }
                            });
                        }
                        return fy
                    });
                    return ({ ...el, financial_year: k });
                }
                return el;
            });
            return ({
                ...state,
                grouped: data
            })

        }
        case 'PRIORITY_UPDATE': {
            let data = state.grouped.map((el) => {
                if (el._id === action.payload.client) {
                    let k = el.financial_year.map((fy) => {
                        if (fy._id === action.payload._id) {
                            console.log("DATA TRIGGER")
                            console.log(fy);
                            return ({
                                ...fy,
                                priority: action.payload.priority

                            });
                        }
                        return fy
                    });
                    return ({ ...el, financial_year: k });
                }
                return el;
            });
            return ({
                ...state,
                grouped: data
            })
        }
        case 'STATUS_UPDATE': {
            let data = state.grouped.map((el) => {
                if (el._id === action.payload.client) {
                    let k = el.financial_year.map((fy) => {
                        if (fy._id === action.payload._id) {
                            console.log("DATA TRIGGER")
                            console.log(fy);
                            return ({
                                ...fy,
                                status: action.payload.status

                            });
                        }
                        return fy
                    });
                    return ({ ...el, financial_year: k });
                }
                return el;
            });
            return ({
                ...state,
                grouped: data
            })
        }
        case 'CHANGE_SELECTED_YEAR': {
            return ({
                ...state,
                selected: action.payload
            })
        }
        case 'SET_ALL_YEARS': {
            return ({
                ...state,
                all: action.payload
            })
        }
        case 'SET_ALL_YEARS_GROUPED': {
            return ({
                ...state,
                grouped: action.payload
            })
        }
        default:
            return state;
    }
}