const initState = {
    _id: [],
    selected: "",
    all: []
}

export const companyReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE': {
            return ({
                ...state,
                ...action.payload
            });
        }
        case 'CHANGE_SELECTED_COMPANY': {
            return ({
                ...state,
                selected: action.payload
            });
        }
        case 'SET_ALL_COMPANIES': {
            return ({
                ...state,
                all: action.payload
            });
        }
        default:
            return state;
    }
}