import { _fetch } from "../../_fetch";

export const login = (userInfo) => ({
  type: "LOGIN",
  payload: userInfo,
});

export const signUp = (userInfo) => ({
  type: "REGISTER",
  payload: userInfo,
});

export const logout = () => ({
  type: "LOGOUT",
});

export const getAllUsers = () => {
  return async (dispatch) => {
    try {
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/user`);
      let result = await res.json();
      if (
        res.status === 200 &&
        Array.isArray(result.response) &&
        result.response.length
      ) {
        dispatch({ type: "SET_ALL_USERS", payload: result.response });
        return result.response;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };
};
