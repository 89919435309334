import TextArea from "antd/lib/input/TextArea";
import useFetch from '../_fetch';
import {
    Input,
    Button,
    Select,
    Form,
    message,
    InputNumber,
    DatePicker

} from 'antd';

export default function Subquery({ subQueries, setSubQueries, myRef, query, query_type, onSuccess }) {

    const _fetch = useFetch();

    const [form] = Form.useForm();

    var cc = require('currency-codes');
    const currencyCodes = cc.codes();
    const currencyOptions = currencyCodes.map((code) => {
        return {
            label: code,
            value: code
        };
    });

    const selectAfter = (
        <Form.Item name='cashInCurrency' initialValue={'AUD'} labelCol={{
            span: 24,
        }} wrapperCol={{
            span: 24,
        }}
            style={{
                marginBottom: -2,
            }}
        >
            <Select
                showSearch
                placeholder='Select a currency'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                options={currencyOptions}
                style={{ width: '80px' }}
            />
        </Form.Item>
    );
    const cashout = (
        <Form.Item name='cashOutCurrency' initialValue={'AUD'} labelAlign='right' labelCol={{
            span: 24,
        }} wrapperCol={{
            span: 24,
        }}
            style={{
                marginBottom: -2
            }}
        >
            <Select
                showSearch
                placeholder='Select a currency'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                options={currencyOptions}
                style={{ width: '80px' }}
            />
        </Form.Item>
    )
    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    async function submit(values) {
        try {
            console.log(values)

            if (values.cashInValue >= 0 && values.cashInCurrency) {
                const cashIn = {
                    value: values.cashInValue,
                    currency: values.cashInCurrency,
                }
                delete values.cashInValue;
                delete values.cashInCurrency;
                values.cashIn = cashIn;
            }
            if (values.cashOutValue >= 0 && values.cashOutCurrency) {
                const cashOut = {
                    value: values.cashOutValue,
                    currency: values.cashOutCurrency,
                };
                delete values.cashOutValue;
                delete values.cashOutCurrency;
                values.cashOut = cashOut;
            }
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/query`, {
                method: "POST",
                body: {
                    ...values,
                    descriptionV2: values.descriptionV2 ? [{ insert: values.descriptionV2 }] : [],
                    financialYear: query.financialYear,
                    client_id: query.client,
                    type: query_type.TRANSACTIONAL,
                    parent_id: query._id,
                }
            });
            let data = await res.json();
            if (res.status === 200) {
                console.log(data)
                let new_query = [...subQueries, data.response]
                setSubQueries(new_query)

                message.success('Sub Query added successfully');


            } else {
                message.error('Some error occurred while opening sub query');
            }
        } catch (err) {
            console.log(err);
        } finally {

        }
    }


    return (
        <div style={{ marginTop: "1%", paddingLeft: "2%", paddingRight: "2%" }} ><Form
            {...layout} onFinish={submit} form={form}
        >

            <div style={{ display: "flex", gap: '20px', flexWrap: "wrap" }} className='w-100'>
                <Form.Item

                    label={subQueries.length < 1 ? "Narration" : null}
                    name="title"
                    rules={[{ required: true, message: 'Query title is required' }]}
                >
                    <Input
                        ref={myRef}
                        style={{ width: '120px' }}
                        size='medium'
                        placeholder='Narration'
                        className='mr-5' />
                </Form.Item>


                <Form.Item name='cashInValue' initialValue={0}
                    label={subQueries.length < 1 ? "Cash In" : null}
                    style={{
                        marginTop: "0.4%"

                    }}
                >
                    <InputNumber
                        style={{ width: '120px' }}
                        size='medium'
                        placeholder='Cash In'
                        className='mr-5'
                        addonAfter={selectAfter}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    />
                </Form.Item>
                <Form.Item name='cashOutValue' initialValue={0}
                    label={subQueries.length < 1 ? "Cash Out" : null}
                    style={{
                        marginTop: "0.3%"
                    }}
                >
                    <InputNumber
                        style={{ width: '120px' }}
                        size='medium'
                        addonAfter={cashout}
                        placeholder='Cash Out'
                        className='mr-5'
                    />
                </Form.Item>
                <Form.Item name='Date' label={subQueries.length < 1 ? "Date" : null} initialValue={0} labelAlign='right'
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <DatePicker format="YYYY-MM-DD" style={{ width: '120px' }} />
                </Form.Item>
                <Form.Item

                    label={subQueries.length < 1 ? "Description" : null}
                    name="descriptionV2"
                    placeholder="Description"
                >
                    <TextArea rows={5} style={{ height: "15px", marginTop: "1%", width: "120px" }} placeholder="Description" />
                </Form.Item>
                <Button htmlType="submit" style={{ marginTop: subQueries.length < 1 ? "3%" : null, width: "80px" }} type="primary" onClick={() => {
                    form
                        .validateFields()
                        .then(values => {
                            submit(values);
                            form.resetFields();
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        })

                }}>Create</Button>

            </div>





        </Form></div>
    )
}