/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { IoCopyOutline } from 'react-icons/io5'
import Mail from "../mail";
import {
  Row,
  Col,
  Dropdown,
  List,
  Typography,
  Space,
  Button,
  Popover,
  Breadcrumb,
  Input,
  message,
  Modal,
  Menu,
  Tooltip,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { BsChevronDown } from "react-icons/bs";
import {
  BellOutlined,
  MenuOutlined,
  MailOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import moment from "moment";
import UserInfo from "../userInfo";
import AllowEntity from "../activity/AllowEntity";

import {
  changeSelectedCompany,
  getAllCompanies,
  updateCompany,
} from "../../store/action/company";
import useFetch from "../../_fetch";
import { logout } from "../../store/action/user";
import { user_role, } from "../../consts";
import Activity from "../activity";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import SearchBox from "../SearchBox";
import PermissionModal from "../PermissionModal";
import { changeSelectedFY } from "../../store/action/fy";
import { changeSelectedClient } from "../../store/action/client";
import SendMail from "../../pages/sendMailModal";
import Financialyears from "../../pages/financial_years";

function BreadCrumbComponent() {
  let location = useLocation();
  const path = window.location.pathname
    .split("/")
    .filter((elem) => elem !== "")
    .map((elem) => (elem === "query" ? "queries" : elem));
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  let { query_id } = useParams();

  const { selected } = useSelector((st) => st.fy);
  const { selected: selectedClient } = useSelector(st => st.client);

  const { selected: selectedCompany } = useSelector((st) => st.company);

  if (location.pathname === "/queries") {
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        {selectedCompany && (
          <Breadcrumb.Item>
            <a href="/">{selectedCompany.name}</a>
          </Breadcrumb.Item>
        )}
        {selectedClient && (
          <Breadcrumb.Item >
            <a href={`/financial_years?entity=${selectedClient?._id}&company=${selectedCompany?._id}`}>{selectedClient.title}</a>
          </Breadcrumb.Item>
        )}
        {selected._id && (
          <Breadcrumb.Item>
            {moment(selected.start).format("YYYY")} -{" "}
            {moment(selected.end).format("YY")}
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
    );
  }
  if (location.pathname === "/queries") {
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        {selectedCompany && (
          <Breadcrumb.Item>
            <a href={`/`}>{selectedCompany.name}</a>
          </Breadcrumb.Item>
        )}
        {selectedClient && (
          <Breadcrumb.Item>
            <a href={`/financial_years?entity=${selectedClient?._id}&company=${selectedCompany?._id}`}>{selectedClient.title}</a>
          </Breadcrumb.Item>
        )}
        {selected._id && (
          <Breadcrumb.Item>
            {moment(selected.start).format("YYYY")} -{" "}
            {moment(selected.end).format("YY")}
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
    );
  }

  else if (location.pathname === "/financial_years") {
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/Entities">entities</Link>
        </Breadcrumb.Item>
        {selectedClient && (
          <Breadcrumb.Item>
            <a href={`/`}>{selectedClient.title}</a>
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
    );
  }
  else if (location.pathname.includes("/query")) {
    return (
      <Breadcrumb className="">
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        {selectedCompany && (
          <Breadcrumb.Item>
            <Link to={`/`}>{selectedCompany.name}</Link>
          </Breadcrumb.Item>
        )}
        {selectedClient && (
          <Breadcrumb.Item>
            {selectedClient.title}
          </Breadcrumb.Item>
        )}

        {selected._id && (
          <Breadcrumb.Item>
            <Link
              to={`/queries?company=${selectedCompany._id}&fy=${selected._id}&entity=${selectedClient._id}`}
            >
              {moment(selected.start).format("YYYY")} -{" "}
              {moment(selected.end).format("YY")}
            </Link>
          </Breadcrumb.Item>
        )}
        {selected._id && <Breadcrumb.Item>{query_id}</Breadcrumb.Item>}
      </Breadcrumb>
    );
  } else {
    return (
      <Breadcrumb className="">
        <Breadcrumb.Item>
          <a onClick={() => navigate("/")}>Home</a>
        </Breadcrumb.Item>
        {path.map((crumb, index) => (
          <Breadcrumb.Item key={index}>
            <a
              onClick={() => {
                if (path.length === index + 1) {
                } else navigate(-(index + 1));
              }}
            >
              {crumb}
            </a>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  }
}

function Header({ onPress }) {
  const { Title } = Typography;
  const { _id, role, first_name, last_name, email } = useSelector(
    (st) => st.user
  );
  const { selected: selectedCompany, all } = useSelector((st) => st.company);

  const [firmsToShow, setFirmsToShow] = useState([]);
  const [showInboxPopover, setShowInboxPopover] = useState(false);
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [showFirmDropdown, setShowFirmDropdown] = useState(false);
  const [permissionModalOpen, setPermissionModalOpen] = useState(null);
  const [editFirmTitle, setEditFirmTitle] = useState("");
  const [editedText, setEditedText] = useState("");
  let user = useSelector((st) => st.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _fetch = useFetch();
  const breakpoint = useBreakpoint();

  const [cookie, setCookie, removeCookie] = useCookies(["userInfo"]);

  const copyDomain = async (domain) => {
    navigator.clipboard.writeText(domain);
    message.success("Invite Link Copied To Clipboard");
  }

  useEffect(() => window.scrollTo(0, 0));
  useEffect(() => {
    if (all?.length) setFirmsToShow(all);
  }, [all]);

  async function _logout() {
    try {
      await _fetch(`${process.env.REACT_APP_API_URL}/user/login`, {
        method: "DELETE",
      });
      dispatch(logout());
      removeCookie("userInfo");
      navigate("/sign-in", { replace: true });
    } catch (err) {
      console.log(err);
    }
  }

  async function getCompanies() {
    try {
      let companies = await dispatch(getAllCompanies());
      // console.log("Companies are:", companies);
      if (companies) {
        if (cookie.userInfo?.selectedCompany) {
          dispatch(
            updateCompany({
              ...companies,
              selected: cookie.userInfo.selectedCompany,
            })
          );
        } else {
          let data;
          if (cookie.userInfo !== undefined) {
            // console.log("Cookie in if condition")
            data = cookie.userInfo;
          }
          else {
            // console.log("cookies in els condition");
            data = user;
          }
          setCookie("userInfo", {
            ...data,
            selectedCompany: companies[0],
          });
          dispatch(
            updateCompany({
              ...companies,
              selected: companies[0],
            })
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    // console.log(all)
    for (var i = 0; i < all?.length; i++) {
      all[i].title = `${all[i].name}`;
    }
  }, [all]);

  async function deleteCompany() {
    try {
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/company`, {
        method: "DELETE",
        body: {
          id: permissionModalOpen._id,
          user_id: _id,
        },
      });
      res = await res.json();
      if (res.status === 200) {
        setCookie("userInfo", {
          ...cookie.userInfo,
          selectedCompany:
            selectedCompany._id === permissionModalOpen._id
              ? null
              : selectedCompany,
        });

        await getCompanies();
        dispatch(changeSelectedCompany(all[0]))
        navigate("/", { replace: true });
        message.success(`Australian Firm deleted successfully`);
      } else {
        message.error(res.response);
      }
    } catch (err) {
      message.error("Something went wrong");
      console.log(err);
    } finally {
      setPermissionModalOpen(null);
    }
  }

  const menu = (

    <List
      className="header-companies-dropdown"
      style={{
        maxHeight: "70vh",
        minWidth: "18vw",
        overflow: "auto",
        backgroundColor: "white",
        padding: "0px 10px",
        borderRadius: "10px",
        boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.15)",
      }}
      itemLayout="horizontal"
      dataSource={firmsToShow}
      header={
        <div className="input_box1">
          <SearchBox all={all} setshow={setFirmsToShow} className="move1" />
          {/* <Input
					placeholder="Search Firms..."
					onChange={onSearch}
				/> */}
        </div>
      }
      renderItem={(item) => (
        <List.Item >
          <div style={{ width: "100%" }} >
            <List.Item.Meta
              title={
                <div style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                  <Typography>{item.name}</Typography>
                  {role === user_role.ADMIN && (
                    <div>
                      <Button
                        type="link"
                        onClick={(prevval) => {
                          setShowFirmDropdown(false);
                          setPermissionModalOpen(item);
                        }}
                      >
                        <DeleteOutlined
                          style={{
                            color: "red",
                            fontSize: "15px",
                          }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
              }
              onClick={() => {
                dispatch(changeSelectedFY({}));
                dispatch(changeSelectedClient({}));
                setCookie("userInfo", {
                  ...cookie.userInfo,
                  selectedCompany: item,
                });
                dispatch(changeSelectedCompany(item));
                navigate("/", { replace: true });
              }}
            />
            {/* <List.Item.Meta
            title={
              <div>
                <IoCopyOutline />
                {`${process.env.REACT_APP_HOME}/invite/${item?.domain}`}
              </div>
            }
            onClick={() =>
              copyDomain(`${process.env.REACT_APP_HOME}/invite/${item?.domain}`)
            }
          /> */}
            {item.domain ? <div
              style={{ display: "flex", cursor: "pointer", justifyContent: "center", alignItems: "center", padding: "0.4rem" }}
              onClick={() =>
                copyDomain(
                  `${process.env.REACT_APP_HOME}/invite/${item?.domain}`
                )
              }
            >
              <IoCopyOutline />
              <Typography.Text
                code={true}
                ellipsis={true}
              >
                {`${process.env.REACT_APP_HOME}/invite/${item?.domain}`}
              </Typography.Text>

            </div> : <></>}

          </div>

        </List.Item>
      )}
      onClick={() => setShowFirmDropdown(false)}
    />
  );

  const postNewFirmName = async () => {
    try {
      // console.log(editFirmTitle, editedText);
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/company`, {
        method: "PATCH",
        body: {
          id: editFirmTitle._id,
          name: editedText,
        },
      });
      console.log(res);
      res = await res.json();
      if (res.status >= 200 && res.status < 300) {
        setEditFirmTitle("");
        setEditedText("");
        dispatch(getAllCompanies());
      } else message.error(res.response);
    } catch (e) {
      console.log(e);
      message.error(e.message);
    }
  };

  const accountMenu = (
    <List
      // min-width="100%"
      className="header-companies-dropdown"
      itemLayout="horizontal"
      dataSource={all}
      style={{
        maxHeight: "70vh",
        overflow: "auto",
        backgroundColor: "white",
        paddingLeft: 14,
        paddingRight: 10,
        borderRadius: "10px",
        boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.15)",
      }}
    >
      <List.Item
        className="pointer"
        onClick={() => navigate("/change_password")}
      >
        <List.Item.Meta title="Change Password" />
      </List.Item>
      <List.Item className="pointer" onClick={() => _logout()}>
        <List.Item.Meta title="Logout" />
      </List.Item>
    </List>
  );

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const openSendMailModal = () => {
    setShowInboxPopover(false);
    setShowSendMailModal(true);
  };

  return (
    <>
      <Row style={{ justifyContent: "space-between" }} gutter={[5, 0]}>
        {!breakpoint.lg && (
          <Col md={6}>
            <Button
              type="link"
              className="sidebar-toggler"
              onClick={() => onPress()}
            >
              <MenuOutlined style={{ fontSize: "20px" }} />
            </Button>
          </Col>
        )}
        <Col
          className="d-flex align-items-center justify-content-start"
          lg={12}
          xs={24}
        >
          <BreadCrumbComponent />
        </Col>

        <Col lg={12} sm={24}>
          <div className="d-flex justify-content-end align-items-center">
            <Space>
              {user_role.ADMIN === role ? (
                <NavLink to="/approve-documents">
                  <Button type="link">Approval</Button>
                </NavLink>
              ) : <></>}

              <Popover
                onOpenChange={(e) => {
                  if (!e) {
                    setShowInboxPopover(false);
                  }
                }}
                open={showInboxPopover}
                trigger="click"
                content={
                  <div style={{ height: "500px", width: "400px" }}>
                    <Mail
                      setShowInboxPopover={setShowInboxPopover}
                      canNavigate={true}
                      openSendMailModal={openSendMailModal}
                      height={500}
                      width={400}
                    />
                  </div>
                }
                destroyTooltipOnHide
              >
                <Tooltip title="Mail" color="rgb(0 0 0 / 44%)" fontSize="small">
                  <Button
                    onClick={() => setShowInboxPopover(true)}
                    style={{
                      borderRadius: "10px",
                      borderColor: "transparent",
                      backgroundImage: "linear-gradient(#4CB8C4, #3CD3AD)",
                      boxShadow: "0 0 10px #4CB8C4",
                    }}
                    type="primary"
                    icon={<MailOutlined />}
                    size="large"
                  />
                </Tooltip>
                {/* <h1 style={{ marginTop: "10px" }}>Mail</h1> */}
              </Popover>

              <Popover
                trigger="click"
                content={
                  <div
                    style={{
                      height: "500px",
                      width: "400px",
                      overflow: "auto",
                    }}
                    id="scrollableDiv"
                  >
                    <Activity canNavigate={true} />
                  </div>
                }
                destroyTooltipOnHide
              >
                <Tooltip
                  title="Notification"
                  color="rgb(0 0 0 / 44%)"
                  fontSize="small"
                >
                  <Button
                    style={{ borderRadius: "10px" }}
                    type="primary"
                    icon={<BellOutlined />}
                    size="large"
                  />
                </Tooltip>
              </Popover>
              {role !== user_role.COMPANY ? (
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  onOpenChange={() => {
                    if (showFirmDropdown === true) {
                      setShowFirmDropdown(false);
                    }
                    else {
                      setShowFirmDropdown(true);
                    }

                  }}
                  destroyPopupOnHide={true}
                  open={showFirmDropdown}
                >
                  <Button
                    className="my-auto"
                    style={{ minWidth: "150px" }}
                    type="primary"
                  >
                    <div className="d-flex justify-content-between">
                      <Title level={4} className="my-auto text-white mx-1">
                        {selectedCompany.name}
                      </Title>
                      <BsChevronDown size={20} className="my-auto" />
                    </div>
                  </Button>
                </Dropdown>
              ) : (
                <Button
                  className="my-auto"
                  style={{ minWidth: "150px" }}
                  type="primary"
                >
                  <div className="d-flex justify-content-between">
                    <Title level={4} className="my-auto text-white mx-1">
                      {selectedCompany.name}
                    </Title>
                  </div>
                </Button>
              )}
              <Dropdown overlay={accountMenu} trigger={["click"]}>
                <div className="pointer d-flex items-center">
                  <UserInfo
                    firstName={first_name}
                    lastName={last_name}
                    email={email}
                    avatraProps={{
                      size: "large",
                      style: { backgroundColor: "#1890ff" },
                    }}
                  />
                </div>
              </Dropdown>
            </Space>
          </div>
        </Col>
      </Row>
      <PermissionModal
        visible={permissionModalOpen !== null}
        setVisible={setPermissionModalOpen}
        onSuccess={deleteCompany}
        child={
          <p>
            Are you sure you want to delete this <b>Australian Firm</b>? This
            will delete all the <b>Entities</b>, <b>Financial Years</b>,{" "}
            <b>Queries</b>, and <b>Attachments</b> related to this Australian
            Firm.
          </p>
        }
      />
      <SendMail show={showSendMailModal} setShow={setShowSendMailModal} />
      <Modal
        open={!!editFirmTitle}
        onCancel={() => setEditFirmTitle(false)}
        title="Edit Australian Firm"
        centered
        onOk={postNewFirmName}
        destroyOnClose
      >
        <Input
          defaultValue={editFirmTitle.name}
          onChange={(e) => setEditedText(e.target.value)}
        />
      </Modal>
    </>
  );
}

export default Header;
