import { Avatar,List, Tag, Input, Button, Image, message } from 'antd';
import { _fetch } from '../../_fetch';
import moment from 'moment';
import { Comment } from '@ant-design/compatible';
import { Select } from 'antd';
import { user_role } from "../../consts";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UploadSnapshot from '../../components/uploadSnapshot';
import UserInfo from '../userInfo';

export default function CommentView({ query_id = null, comments = null, setComments = null, fy = null, onCommentAdded = null, show = true, setShow }) {
    const files = useSelector((st) => st.file);
    const { Option } = Select;
    const [newComment, setNewComment] = useState('');
    const [toggle, setTogle] = useState(false)
    const [filesWaiting, setFilesWaiting] = useState(false);
    const { all: all_users } = useSelector((st) => st.user);
    const { selected } = useSelector((st) => st.company);
    const [, setSeed] = useState(1);

    const [user, setUser] = useState([]);
    const reset = () => {
        setSeed(Math.random());
    };
    useEffect(() => {
        console.log(fy)
        console.log(comments);
    }, [fy, comments])

    const handleChange = (value) => {
        setUser([...value])
    };

    useEffect(() => {
        if (newComment?.length === 0) {
            setUser([])
            setTogle(false)
        }

    }, [newComment])

    async function handleNewComment() {
        var payload = {}
        if (query_id) {
            payload = {
                query_id: query_id,
                text: newComment,
            }
        }
        if (fy) {

            payload = {
                fy: fy,
                text: newComment,
                arr: user
            }

        }
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/comment`, {
                method: 'POST',
                body: {
                    ...payload
                },
            });
            if (res.status === 200) {
                let result = await res.json();
                message.success("Mail sent successfully")
                setNewComment('');
                setComments((c) => [result.response, ...c]);
                setUser([])
                setTogle(false)
                onCommentAdded && onCommentAdded({ status: "OPEN" });
                setShow(false)
                return result.response._id;
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>

            <div>


                <div>
                    <Input.TextArea
                        id='comment'
                        placeholder='type your comment...'
                        autoSize={{
                            minRows: 5,
                            maxRows: 10,
                        }}
                        value={newComment}
                        onChange={(e) =>
                            setNewComment(
                                e.target.value
                            )
                        }
                    />
                    <UploadSnapshot
                        setFilesWaiting={
                            setFilesWaiting
                        }
                        refresh={reset}
                        id={query_id}
                    />
                </div>

                {(newComment || filesWaiting) && (
                    <div className='d-flex align-items-center justify-content-start my-2'>
                        <Button
                            type='default'
                            className='me-2'
                            onClick={() => {
                                setNewComment("");
                                setUser([]);
                                setTogle(false);
                            }}
                            size='large'
                        >
                            Cancel
                        </Button>

                        {toggle && <Select
                            mode="multiple"
                            style={{ minWidth: "400px" }}
                            className="me-2"
                            size="large"
                            placeholder="Select User"
                            defaultValue={user}
                            onChange={handleChange}
                            optionLabelProp="label"

                        >
                            {

                                all_users?.filter(
                                    (e) =>
                                        e.role === user_role.ADMIN ||
                                        e.company_ids.some((f) => f._id === selected._id)
                                ).map((elem) => <Option label={elem.first_name + ' ' + elem.last_name} value={elem._id} key={elem._id}>

                                    <UserInfo firstName={elem.first_name} lastName={elem.last_name} email={elem.email} />

                                </Option>)}



                        </Select>}




                        {<Button

                            type='primary'
                            disabled={toggle && !user?.length}
                            onClick={async () => {

                                if (!toggle && fy) {
                                    setTogle(true)
                                }
                                else {
                                    let commentId =
                                        await handleNewComment();
                                    window.myFileUpload(
                                        commentId
                                    );
                                }

                            }}

                        >
                            {fy ? "Send Email" : "Submit"}
                        </Button>}


                    </div>
                )}

            </div>
            {comments ?
                <List
                    className='comment-list w-100 px-3'
                    itemLayout='horizontal'
                    dataSource={comments}
                    renderItem={(item) => (
                        <Comment
                            style={{ margin: "9px 0px", backgroundColor: "#FAFAFA", paddingLeft: "10px" }}
                            author={
                                <div>
                                    <span>
                                        <b>{item?.created_by?.first_name} {item?.created_by?.last_name}</b>
                                        <span className='ms-2'>
                                            {moment(item?.createdAt).format("DD MMM YYYY HH:mm")}
                                        </span>
                                    </span>
                                </div>
                            }
                            avatar={
                                <Avatar shape='square'>
                                    {item?.created_by?.first_name[0]}
                                    {item?.created_by?.last_name[0]}
                                </Avatar>
                            }
                            content={(<div style={{ backgroundColor: "#FAFAFA" }}>
                                <span style={{ whiteSpace: "pre-line", fontSize: "12px", fontWeight: "400" }}> {item?.text} {" "}</span>
                                {item.created_for.length !== 0 &&
                                    <p>
                                        <span style={{ color: "#6FBAFF", fontWeight: "600", fontSize: "12px" }}>Recipients</span>
                                    </p>
                                }
                                <span style={{ color: "#00000073", fontWeight: "700", fontSize: "12px" }}>
                                    {item?.created_for?.map((el, i) => el?.first_name + " " + el?.last_name).join(", ")}
                                </span>
                                <div>

                                    {files?.filter((s) => s.commentId === item._id)
                                        ?.length
                                        ? files
                                            ?.filter(
                                                (s) => s.commentId === item._id
                                            )
                                            .map((e) => (
                                                <Tag
                                                    className='pointer m-1'
                                                    style={{
                                                        padding: '9px',
                                                        borderRadius: '5px',
                                                        maxWidth: '180px',
                                                    }}
                                                >

                                                    <Image src={e.path} />


                                                    <div>
                                                        {(() => {
                                                            // making our custom unique identifier vanish ex-(seperated by --- ,filename.png---gedy2u1et6 ) to filename.png
                                                            e.name = e.name.split('---')[0];
                                                            const changedName =
                                                                e?.name?.length <= 15
                                                                    ? `${e.name}`
                                                                    : `${e.name
                                                                        .split('.')[0]
                                                                        .slice(0, 12)}...${e.name.split('.')[1]
                                                                    }`;

                                                            return changedName;
                                                        })()}
                                                    </div>
                                                    <div>{moment.utc(e.createdAt).format("DD MMM YYYY HH:mm")}</div>
                                                </Tag>
                                            ))
                                        : null}
                                </div>
                            </div>)}
                        />
                    )}
                /> : null}
        </React.Fragment>
    );
}
