import { Form, Input, message, Modal } from "antd";
import { _fetch } from "../../_fetch";

export default function CreateDocument({ open, setOpen, fy_id }) {
    const [form] = Form.useForm();
    const submit = async (value) => {
        if (value.name === "") {
            message('Please Fill Properly')
        }
        let body = {
            name: value.name,
            fy_id
        }
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/folder`,
                {
                    method: 'POST',
                    body
                }
            );
            let data = await res.json();
            if (data.success === true) {
                message("Folder Created");
                form.resetFields();
                setOpen(false);
            }
            setOpen(false);
        }
        catch (e) {
            console.log(e);
            form.resetFields();
            setOpen(false);
        }
    }
    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            okText="Submit"
            destroyOnClose
            title="Submit New Documents"
            onOk={form.submit}
        >
            <Form form={form}
                onFinish={submit}>
                <Form.Item name="name"
                    label="Create Folder"
                >
                    <Input />
                </Form.Item>
            </Form>

        </Modal>
    )
}