import React, { useEffect, useState } from "react";
import { Modal, Input, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { VideoList } from "./VideoList";
import { addVideo, reorderVideo } from "../store/action/video";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const VideoListModal = ({ setIsModelOpen, isModalOpen }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");
  const { videos, loading } = useSelector((state) => state.videos);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(videos);
  }, [videos]);

  const submit = () => {
    if (selected) {
      const params = new URL(selected);
      console.log(params.host);
      if (params.host !== "youtu.be" && params.host !== "www.youtube.com") {
        message.error("Invalid Link");
        return;
      }
      dispatch(addVideo(selected));
    }
  };

  const cancelHandler = () => {
    setIsModelOpen(false);
  };

  function handleDragEnd(event) {
    if (!event.destination) {
      return;
    }

    const oldIndex = event.source.index;
    const newIndex = event.destination.index;
    if (oldIndex === newIndex) {
      return;
    }
    setItems((items) => {
      const newItems = [...items];
      const [removed] = newItems.splice(oldIndex, 1);
      newItems.splice(newIndex, 0, removed);
      const reorderedItems = newItems.map((e, i) => {
        return { ...e, videoIndex: i + 1 };
      });
      dispatch(
        reorderVideo(reorderedItems)
      );
      console.log(newItems);
      return newItems;
    });
  }

  return (
    <Modal
      open={isModalOpen}
      width={600}
      onCancel={cancelHandler}
      title={
        <h6 className="font-semibold m-0">Add new Video to help center</h6>
      }
      okText="ADD VIDEO"
      onOk={submit}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ loading: loading }}
      footer={<></>}
    >
      <label>Video Url</label>
      <div className="d-flex gap-4">
        <Input
          value={selected}
          onChange={(ev) => setSelected(ev.target.value)}
        />
        <Button onClick={submit} type="primary">
          Add Video
        </Button>
      </div>
      <DragDropContext
        onDragEnd={handleDragEnd}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((el, index) => (
                <VideoList
                  el={el}
                  index={index}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  );
};

export default VideoListModal;
