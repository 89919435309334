import { Form, Input, message, Modal, Select, InputNumber, Row, Col, Typography, DatePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { query_type, user_role } from "../consts";
import useFetch from "../_fetch";

var cc = require('currency-codes');
export default function CreateQuery({
    show, setShow, refresh, initialTypeValue = query_type.NORMAL
}) {

    const _fetch = useFetch();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [isTransactional, setIsTransactional] = useState(false);
    const currencyCodes = cc.codes();
    let { role } = useSelector((st) => st.user);
    const currencyOptions = currencyCodes.map((code) => {
        return {
            label: code,
            value: code
        };
    });
    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const { Option } = Select;

    const { selected: selectedClient, all: all_clients } = useSelector(st => st.client);
    const { selected, grouped: all_fys } = useSelector(st => st.fy);
    const { queries } = useSelector(st => st.queries);

    async function submit(values) {
        setLoading(true);
        console.log(values)
        try {
            if (values.cashInValue >= 0 && values.cashInCurrency) {
                const cashIn = {
                    value: values.cashInValue,
                    currency: values.cashInCurrency,
                }
                delete values.cashInValue;
                delete values.cashInCurrency;
                values.cashIn = cashIn;
            }
            if (values.cashOutValue >= 0 && values.cashOutCurrency) {
                const cashOut = {
                    value: values.cashOutValue,
                    currency: values.cashOutCurrency,
                };
                delete values.cashOutValue;
                delete values.cashOutCurrency;
                values.cashOut = cashOut;
            }
            // console.log(values)
            let type = user_role.COMPANY === role ? { type: "REVIEW_POINTS" } : null;
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/query`, {
                method: "POST",
                body: {
                    ...values,
                    ...type,
                    descriptionV2: values.descriptionV2 ? [{ insert: values.descriptionV2 }] : []

                }
            });
            await res.json();
            if (res.status === 200) {
                user_role.COMPANY === role ? message.success('Review Points created successfully') : message.success('Query created successfully');
                setShow(false);
                refresh()

            } else {
                message.error('Some error occurred while opening query');
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal visible={show}
            title={user_role.COMPANY === role ? "Create Review Points" : "Create Query"}
            // title="Create Query"
            destroyOnClose
            onCancel={() => {
                form.resetFields();
                setIsTransactional(false);
                setShow(false)
            }}
            okText={user_role.COMPANY === role ? "Add Review Points" : 'Open Query'}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        submit(values);
                        form.resetFields();
                        setIsTransactional(false);
                        // setTimeout(() => { console.log("component refreshed"); refresh() }, 5000);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            okButtonProps={{ loading: loading, htmlType: "submit" }}
            cancelButtonProps={{ loading }}
        >
            <Form layout="vertical" {...layout} onFinish={submit} form={form} initialValues={{ financialYear: selected?._id, client_id: selectedClient?._id }}>
                <Form.Item
                    label="Entity"
                    name="client_id"
                    initialValue={selectedClient?._id}
                >
                    <Select value={selectedClient?._id} showSearch optionFilterProp="label">
                        {
                            all_clients.map(e => (
                                <Option value={e._id} key={e._id} label={e.title}>{e.title}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.client_id !== currentValues.client_id}
                >
                    {({ getFieldValue }) =>
                        <Form.Item
                            label="Financial Year"
                            name="financialYear"
                            initialValue={selected._id}
                        >
                            <Select value={selected._id}>
                                {
                                    all_fys.find(e => e._id === getFieldValue('client_id'))?.financial_year.map(e => (
                                        <Option value={e._id} key={e._id}>{moment(e.start).format('YYYY')} - {moment(e.end).format('YY')}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    }
                </Form.Item>
                {user_role.COMPANY !== role &&
                    <Form.Item
                        label="Query Type"
                        name="type"
                        shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                        initialValue={initialTypeValue}
                    >
                        <Select
                            value={initialTypeValue}
                            onChange={(val) => {
                                if (val === query_type.TRANSACTIONAL) {
                                    setIsTransactional(true);
                                } else {
                                    setIsTransactional(false);
                                }
                            }}
                        >
                            <Option value={query_type.NORMAL} key={query_type.NORMAL} >Normal</Option>
                            <Option value={query_type.TRANSACTIONAL} key={query_type.TRANSACTIONAL} >Transactional</Option>
                            <Option value={query_type.REVIEW_POINTS} key={query_type.REVIEW_POINTS} >Review Points</Option>
                        </Select>
                    </Form.Item>
                }
                {
                    isTransactional && (
                        <div className='w-100'>
                            <Typography.Title level={5}>Transaction Details</Typography.Title>
                            <Row
                                justify={'space-evenly'}
                                gutter={[16, 0]}
                            >
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                >
                                    <Form.Item name='cashInValue' label='Cash In' initialValue={0} labelAlign='right' labelCol={{
                                        span: 8,
                                    }} wrapperCol={{
                                        span: 16,
                                    }}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <InputNumber
                                            style={{ width: '200px' }}
                                            size='medium'
                                            placeholder='Cash In'
                                            className='mr-5'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                >
                                    <Form.Item name='cashInCurrency' label='Cash In Currency' initialValue={'AUD'} labelAlign='right' labelCol={{
                                        span: 24,
                                    }} wrapperCol={{
                                        span: 24,
                                    }}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Select
                                            showSearch
                                            placeholder='Select a currency'
                                            optionFilterProp='children'
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            options={currencyOptions}
                                            style={{ width: '200px' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row
                                justify={'space-evenly'}
                                gutter={[16, 0]}
                            >
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                >
                                    <Form.Item name='cashOutValue' label='Cash Out' initialValue={0} labelAlign='right' labelCol={{
                                        span: 8,
                                    }} wrapperCol={{
                                        span: 16,
                                    }}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <InputNumber
                                            style={{ width: '200px' }}
                                            size='medium'
                                            placeholder='Cash Out'
                                            className='mr-5'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                >
                                    <Form.Item name='cashOutCurrency' label='Cash Out Currency' initialValue={'AUD'} labelAlign='right' labelCol={{
                                        span: 24,
                                    }} wrapperCol={{
                                        span: 24,
                                    }}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Select
                                            showSearch
                                            placeholder='Select a currency'
                                            optionFilterProp='children'
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            options={currencyOptions}
                                            style={{ width: '200px' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                >
                                    <Form.Item name='Date' label='Date' initialValue={0} labelAlign='right' labelCol={{
                                        span: 24,
                                    }} wrapperCol={{
                                        span: 124,
                                    }}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <DatePicker format="YYYY-MM-DD" style={{ width: '200px' }} />
                                    </Form.Item>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => {
                                    return (prevValues.client_id !== currentValues.client_id) || (prevValues.financialYear !== currentValues.financialYear) || (prevValues.type !== currentValues.type)
                                }}
                            >
                                {({ getFieldValue }) =>
                                    <Form.Item
                                        label="Parent Query"
                                        name="parent_id"
                                        rules={[{ required: true, message: 'Parent query is required' }]}
                                    >
                                        <Select>
                                            {
                                                queries.filter(e => e.client._id === getFieldValue('client_id') && e.financialYear === getFieldValue('financialYear') && e.type === query_type.NORMAL).map(e => (
                                                    <Option value={e._id} key={e._id}>{e.title}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                }
                            </Form.Item>
                        </div>
                    )
                }
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Query title is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="descriptionV2"
                >
                    <TextArea rows={5} />
                </Form.Item>
            </Form>
        </Modal>
    )
}