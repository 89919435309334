import {
  Card,
  Table,
  Button,
  Modal,
  Tabs,
  Typography,
  Row,
  message,
  Tooltip,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import SearchBox from "../components/SearchBox";
import React, { useEffect, useRef, useState } from "react";
import { Loader } from "../components/loader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedClient, getClients } from "../store/action/client";
import { changeSelectedFY, getFinancialYears } from "../store/action/fy";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { BsPlus } from "react-icons/bs";
import useFetch from "../_fetch";
import CreateQuery from "./createQuery";
import { getQueries } from "../store/action/queries";
import Query from "./Query";
import Fyassign from "../components//querySidebar/fyAsing";
import CommentView from "../components/commentView";
import { changeSelectedCompany } from "../store/action/company";
import PermissionModal from "../components/PermissionModal";
import { user_role, fy_status_order, query_type } from "../consts";
import Documents from "./Documents";
import SendDocumentsModal from "../components/files/SendDocumentsModal";
import ReviewPoints from "../components/Tabs/ReviewPoints";
import RQuill from "../components/quill_editor";
var QuillDeltaToHtmlConverter =
  require("quill-delta-to-html").QuillDeltaToHtmlConverter;

export function useQuery(search) {
  const location = useLocation();
  return React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
}

const queryOwnerFilters = (dat) => {
  let map = {};
  let filter = [];
  dat.forEach((e) => {
    let name = `${e.created_by.first_name} ${e.created_by.last_name}`;
    if (!map[name]) {
      map[name] = 1;
      filter.push({ text: name, value: name });
    }
  });
  return filter;
};

const statusFilters = fy_status_order.map((e) => ({ text: e, value: e }));
const titleFilters = (dat) => {
  let map = {};
  let filter = [];
  dat.forEach((e) => {
    if (!map[e.title]) {
      map[e.title] = 1;
      filter.push({ text: e.title, value: e.title });
    }
  });
  return filter;
};
const entityFilters = (dat) => {
  let map = {};
  let filter = [];
  dat.forEach(({ client: { title } }) => {
    if (!map[title]) {
      map[title] = 1;
      filter.push({ text: title, value: title });
    }
  });
  return filter;
};

export default function AllQueries() {
  const [isMailModalOpen, setIsMailModalOpen] = useState(false);
  const [showDocs, setShowDocs] = useState(false);
  const [modalQueryId, setModalQueryId] = useState(null);
  const [show, setShow] = useState([]);
  const mailRef = useRef();
  const [comments, setComments] = useState([]);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [, setUpdatedquery] = useState([]);

  const [loading, setLoading] = useState(true);
  const placeholder = "Search Queries";
  const { selected, all: allFys, grouped: fy } = useSelector((st) => st.fy);
  const { selected: selectedClient, all: allClients } = useSelector(
    (st) => st.client
  );
  const { role } = useSelector((st) => st.user);
  const { selected: selectedCompany } = useSelector((st) => st.company);
  const user = useSelector((st) => st.user);
  const { queries } = useSelector((st) => st.queries);
  const { all: client } = useSelector((st) => st.client);
  const [showQuery, setShowQuery] = useState(false);
  const [showReviewQuery, setShowReviewQuery] = useState(false);
  const [reviewQueryRefreshToggle, setReviewQueryRefreshToggle] =
    useState(false);
  const [, setQuery] = useState([]);
  const [seed, setSeed] = useState(1);
  const [permissionModalOpen, setPermissionModalOpen] = useState(null);
  let query = useQuery("search");
  const _fetch = useFetch();
  const [cookie, setCookie] = useCookies("userInfo");
  const [toggle, setToggle] = useState(true);
 

  const reset = () => {
    setSeed(Math.random());
  };

  const showModal = () => {
    setIsMailModalOpen(true);
  };

  const handleClick = () => {
    // const subject = `Entitiy Name - ${queries[0].client.title} and FY ${moment(
    //   selected.start
    // ).format("YYYY")} - ${moment(selected.end).format("YY")}`;

    let body = ``;
    queries.forEach((e, i) => {
      var converter = new QuillDeltaToHtmlConverter(e.descriptionV2, []);
      let htmlConverted = converter.convert();
      let html = `
      
      <p style="font-size:12px">${i + 1}.${e.title}</p>${htmlConverted}`;
      body += html;
    });
    body = `<p style="font-size:12px" >Dear Client</p>
      <p style="font-size:12px" >We are in the process of completing the <b>${moment(
        selected.start
      ).format("YYYY")} - ${moment(selected.end).format(
      "YY"
    )}</b>  tax return for <b>${
      selectedClient?.title
    }</b>. Please provide the information requested in the following queries at your earliest convenience.</p> ${body}`;
    console.log(body);
    displayDesc.current = body;
    showModal();
  };

  async function _getQueries() {
    setLoading(true);
    try {
      let clients;
      if (query.get("company") === selectedCompany._id) {
        if (!(Array.isArray(allClients) && allClients.length)) {
          clients = await dispatch(getClients(selectedCompany._id));
        }

        if (clients && !(Array.isArray(allFys) && allFys.length)) {
          dispatch(getFinancialYears(clients.map((e) => e._id)));
        }

        if (query.get("company") && query.get("fy")) {
          let get = await dispatch(
            getQueries(query.get("company"), query.get("fy"))
          );
          setQuery(get);
          setCookie("userInfo", {
            ...cookie.userInfo,
            selectedCompany: selectedCompany,
          });
          await dispatch(changeSelectedCompany(selectedCompany));
          let res = fy?.filter(
            (elem) => elem.client_id === query.get("entity")
          );
          let filteredFy = res[0].financial_year.filter(
            (elem) => elem?._id === query.get("fy")
          );
          let changedClient = client?.filter(
            (elem) => elem._id === query.get("entity")
          );
          await dispatch(changeSelectedClient(changedClient[0]));
          await dispatch(changeSelectedFY(filteredFy[0]));
        }
      }
      if (selected && !query.get("company") && !query.get("fy")) {
        let get = await dispatch(getQueries(selectedCompany._id, selected._id));
        setQuery(get);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "relative_id",
      key: "relative_id",
      width: "1%",
      sorter: (a, b) => a.relative_id - b.relative_id,
      className: query.get("entity") ? "" : "d-none",
    },
    {
      title: "Title",
      dataIndex: "title",
      filters: titleFilters(show),
      key: "title",
      width: "20%",
      sorter: (a, b) => {
        return `${a.title.toLowerCase()}` > `${b.title.toLowerCase()}`
          ? 10
          : -10;
      },
      onFilter: (value, record) => record.title === value,
    },
    {
      title: "Entity",
      key: "client",
      filters: entityFilters(show),
      sorter: (a, b) => {
        const title = (record) => {
          let client = allFys?.find(
            (e) => e._id === record.financialYear
          )?.client_id;
          if (client) {
            client = allClients.find((e) => e._id === client);
          }
          return client.title;
        };
        const aa = title(a);
        const bb = title(b);
        return `${aa.toLowerCase()}` > `${bb.toLowerCase()}` ? 10 : -10;
      },
      render: (record) => {
        return record?.client?.title;
      },
      onFilter: (value, record) => record.client.title === value,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      sorter: (a, b) => {
        return `${a.status.toLowerCase()}` > `${b.status.toLowerCase()}`
          ? 10
          : -10;
      },
      // eslint-disable-next-line array-callback-return
      render(record) {
        return (
          <div
            style={{
              padding: "5px",
              borderRadius: "10px",
              textAlign: "center",
              backgroundColor: record === "CLOSED" ? "green" : null,
              color: record === "CLOSED" ? "white" : "",
            }}
          >
            {record}
          </div>
        );
      },
      filters: statusFilters,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Created On",

      key: "createdAt",
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      render: (record) => moment(record.createdAt).format("DD MMM YYYY"),
    },

    {
      title: "Query Owner",
      key: "createdBy",
      sorter: (a, b) => {
        return `${a.created_by?.first_name} ${a.created_by?.last_name}`.toLowerCase() >
          `${b.created_by?.first_name} ${b.created_by?.last_name}`.toLowerCase()
          ? 10
          : -10;
      },
      render: (record) =>
        `${record.created_by?.first_name} ${record.created_by?.last_name}`,
      filters: queryOwnerFilters(show),
      onFilter: (value, record) =>
        `${record.created_by.first_name} ${record.created_by.last_name}` ===
        value,
    },
    {
      title: "",
      key: "actions",
      render: (record) => (
        <Row style={{ alignItems: "center" }}>
          <Button
            type="primary"
            onClick={() => navigate(`/query/${record.query_id}`)}
          >
            View Query
          </Button>
        </Row>
      ),
    },
  ];

  const init = async () => {
    try {
      if (query.get("fy")) {
        let commRes = await _fetch(
          `${process.env.REACT_APP_API_URL}/comment?fy=${query.get("fy")}`
        );
        commRes = await commRes.json();
        setComments(commRes.response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, seed]);

  useEffect(() => {
    _getQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, seed, query, fy]);

  const handleFyUpdate = async (update) => {
    const payload = {
      ...update,
      fy: query.get("fy"),
    };
    let updatedQuery = await _fetch(`${process.env.REACT_APP_API_URL}/fy`, {
      method: "PATCH",
      body: payload,
    });
    let resJson = await updatedQuery.json();

    if (resJson.status === 200) {
      dispatch(changeSelectedFY(resJson.response));
    }
  };

  const refresh = () => {
    dispatch(getQueries(query.get("company"), query.get("fy")));

    reset();
  };

  async function deleteFY() {
    try {
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/fy`, {
        method: "DELETE",
        body: {
          id: permissionModalOpen,
          user_id: user._id,
        },
      });
      res = await res.json();
      if (res.status === 200) {
        message.success("Financial Year deleted successfully");
        navigate("/", { replace: true });
      } else {
        message.error(res.response);
      }
    } catch (err) {
      message.error("Something went wrong");
      console.log(err);
    } finally {
      setPermissionModalOpen(null);
    }
  }

  const [showButton, setShowButton] = useState("query");

  const displayDesc = useRef({ content: {}, html: "" });

  const handleOk = () => {
    const htmlCode = mailRef?.current.innerHTML;
    if (!htmlCode) {
      message.error("No queries found");
      return;
    }
    const blobInput = new Blob([htmlCode], { type: "text/html" });
    navigator.clipboard.write([new ClipboardItem({ "text/html": blobInput })]);
    // const html = await navigator.clipboard.readText()
    // window.location.href = `mailto:someone@example.com?body=${blobInput}`;
    message.success("Email Body copied to clipboard");
  };

  const handleCancel = () => {
    setIsMailModalOpen(false);
  };

  const copySubject = (text) => {
    navigator.clipboard.writeText(text);
    message.success("Email Subject copied to clipboard");
  };
 
  
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="tabled">
          <Modal
            style={{
              top: 0,
            }}
            width="70vw"
            destroyOnClose
            open={modalQueryId}
            centered
            footer={null}
            onOk={() => setModalQueryId(null)}
            onCancel={() => setModalQueryId(null)}
          >
            <Query
              query={modalQueryId}
              change={setUpdatedquery}
              setModalQueryId={setModalQueryId}
              onSuccess={refresh}
            />
          </Modal>
          <Modal
            open={isMailModalOpen}
            style={{ top: 15, minWidth: 800 }}
            bodyStyle={{ height: 480, width: "100%", overflowY: "scroll" }}
            onCancel={handleCancel}
            footer={[<></>]}
          >
            <Card
              style={{ marginBottom: 10 }}
              headStyle={{ fontSize: "1rem" }}
              title=<div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography.Title style={{ fontSize: "1.2rem" }}>
                  Email Subject
                </Typography.Title>
                <Button
                  type="primary"
                  key="back"
                  onClick={() =>
                    copySubject(
                      `Entity Name - ${selectedClient?.title} and FY ${moment(
                        selected?.start
                      ).format("YYYY")} - ${moment(selected?.end).format("YY")}`
                    )
                  }
                >
                  Copy Subject
                </Button>
              </div>
            >
              <Typography.Title level={5} >{`Entitiy Name - ${
                selectedClient?.title
              } and FY ${moment(selected?.start).format("YYYY")} - ${moment(
                selected?.end
              ).format("YY")}`}</Typography.Title>
            </Card>
            <Card
              title=<div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography.Title style={{ fontSize: "1.2rem" }}>
                  Email Body
                </Typography.Title>
                <Button type="primary" key="back" onClick={handleOk}>
                  Copy Body
                </Button>
              </div>
            >
              <div className="d-flex flex-column align-items-start my-4">
                <div
                  ref={mailRef}
                  dangerouslySetInnerHTML={{
                    __html: displayDesc.current,
                  }}
                  style={{ whiteSpace: "pre-line" }}
                />
              </div>
            </Card>
          </Modal>
          <Card bordered={false} className="circle_box table_space mb-24 w-100">
            <Tabs
              type="card"
              defaultActiveKey={query.get("showEmail") ? "comments" : ""}
              tabBarExtraContent={
                <div
                  style={{
                    display: "flex",
                    padding: "5px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={handleClick}
                    disabled={queries?.length === 0 || !query.get("fy")}
                  >
                    Draft Email
                  </Button>
                  {user.role === user_role.ADMIN && query.get("fy") && (
                    <Button
                      type="link"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        setPermissionModalOpen(query.get("fy"));
                      }}
                    >
                      <DeleteOutlined
                        style={{
                          color: "red",
                          fontSize: "20px",
                        }}
                      />
                    </Button>
                  )}
                  {query.get("fy") && (
                    <Fyassign
                      query={selected}
                      className="p-2"
                      handleFyUpdate={handleFyUpdate}
                      role={""}
                    />
                  )}{" "}
                  {showButton === "query" && user_role.COMPANY !== role && (
                    <Tooltip
                      title={
                        selected.status === "CLOSED"
                          ? "Please reopen the FY to add a query"
                          : null
                      }
                    >
                      <Button
                        type="link"
                        onClick={() => setShowQuery(true)}
                        disabled={selected.status === "CLOSED"}
                      >
                        <BsPlus size={20} /> Add Query
                      </Button>
                    </Tooltip>
                  )}
                  {showButton === "review" && (
                    <Tooltip
                      title={
                        selected.status === "CLOSED"
                          ? "Please reopen the FY to add a review points"
                          : null
                      }
                    >
                      <Button
                        type="link"
                        onClick={() => setShowReviewQuery(true)}
                        disabled={selected.status === "CLOSED"}
                      >
                        <BsPlus size={20} /> Add Review Points
                      </Button>
                    </Tooltip>
                  )}
                </div>
              }
            >
              <items
                key="queries"
                tab={
                  <Typography.Title
                    level={5}
                    className="align-self-start"
                    onClick={() => setShowButton("query")}
                  >
                    <div className="flex-fill search d-flex">
                      {query.get("entity") ? (
                        <span className="me-3">Queries</span>
                      ) : (
                        "All Queries"
                      )}
                    </div>
                  </Typography.Title>
                }
              >
                <div className="table-responsive">
                  <div
                    className="d-flex flex-fill justify-justify-center"
                    id="fySearch"
                    style={{ padding: "0px 50px" }}
                  >
                    <SearchBox
                      all={queries}
                      setshow={setShow}
                      name={placeholder}
                    />
                  </div>

                  <Table
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          setModalQueryId(record.query_id);
                        }, // click row
                      };
                    }}
                    sortDirections={["ascend", "descend", "ascend"]}
                    columns={columns}
                    dataSource={show}
                    pagination={false}
                    className="ant-border-space"
                    // rowSelection={{ type: 'radio' }}
                  />
                </div>
              </items>
              {query.get("fy") && (
                <>
                  <items
                    tab={
                      <Typography.Title
                        level={5}
                        className="align-self-start"
                        onClick={() => {
                          setShowButton("review");
                        }}
                      >
                        Review Points
                      </Typography.Title>
                    }
                    key="review-points"
                  >
                    <ReviewPoints
                      fy_id={query.get("fy")}
                      toggle={reviewQueryRefreshToggle}
                    />
                  </items>
                  <items
                    tab={
                      <Typography.Title
                        level={5}
                        className="align-self-start"
                        onClick={() => setShowButton("doc")}
                      >
                        Documents
                      </Typography.Title>
                    }
                    key="documents"
                  >
                    <Documents
                      fy_id={query.get("fy")}
                      toggle={toggle}
                      company_id={selectedCompany._id}
                      setToggle={setToggle}
                    />
                  </items>
                  <items
                    tab={
                      <Typography.Title
                        level={5}
                        className="align-self-start"
                        style={{
                          background: "linear-gradient(#4CB8C4, #3CD3AD)",
                          "-webkit-background-clip": "text",
                          "-webkit-text-fill-color": "transparent",
                          textShadow: "0 0 5px #4CB8C460",
                        }}
                      >
                        Send Email
                      </Typography.Title>
                    }
                    key="comments"
                  >
                    <div style={{ padding: "0px 20px" }}>
                      <CommentView
                        comments={comments}
                        fy={query.get("fy")}
                        show={true}
                        setComments={setComments}
                        data={selected}
                      />
                    </div>
                  </items>
                </>
              )}
            </Tabs>
          </Card>
        </div>
      )}
      <CreateQuery refresh={refresh} show={showQuery} setShow={setShowQuery} />
      <CreateQuery
        refresh={() => {
          setReviewQueryRefreshToggle(!reviewQueryRefreshToggle);
        }}
        show={showReviewQuery}
        setShow={setShowReviewQuery}
        initialTypeValue={query_type.REVIEW_POINTS}
      />
      <SendDocumentsModal
        open={showDocs}
        setOpen={setShowDocs}
        _getQueries={_getQueries}
        setToggle={setToggle}
      />
      <PermissionModal
        visible={permissionModalOpen !== null}
        setVisible={setPermissionModalOpen}
        onSuccess={deleteFY}
        child={
          <p>
            Are you sure you want to delete this <b>Financial Year</b>? This
            will delete all the <b>Queries</b>, and <b>Attachments</b> related
            to this Financial Year.
          </p>
        }
      />
    </>
  );
}
