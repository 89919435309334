const initialState = {
  videos: [],
  loading: false,
  error: null,
};

export const VideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "VIDEOS_REQ":
      return {
        ...state,
        loading: true,
      };

    case "VIDEOS_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "GET_VIDEOS_SUCCESS":
      return {
        ...state,
        loading: false,
        videos: action.payload,
      };
    case "ADD_VIDEOS_SUCCESS":
      return {
        ...state,
        loading: false,
        videos: [...state.videos, action.payload],
      };
    case "DELETE_VIDEO_SUCCESS":
      return {
        ...state,
        loading: false,
        videos: state.videos.filter((e) => e._id !== action.payload),
      };
    case "REORDER_VIDEOS_SUCCESS":
      console.log(action.payload);
      return {
        ...state,
        loading:false,
        videos:action.payload
      }
    default:
      return state;
  }
};
