import { Layout, Row, Col } from "antd";
import heart from '../../assets/images/heart.png'
function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <div className="flex justify-center">
        <div style={{ paddingTop: 15, marginLeft: '210px', marginRight: 'auto' }} className="text-center">
          COPYRIGHT © 2023 EFILES WEBSITE - ALL RIGHTS RESERVED<br />
          <div className="flex justify-center mt-3">
            <a className="text-secondary mx-2" href="/termsandcondition">Terms and Conditions</a>  
            <a className="text-secondary mx-2" href="/privacy">Privacy Policy</a>
          </div>
          {/* <a href="https://hypercode.tech/" target="_blank" className="text-secondary flex justify-center"> 
            Developed with 
            <img className="mx-1" src={heart} alt="icon" width={20} height={17}/> 
            by HyperCode
          </a> */}
        </div>
      </div>
    </AntFooter>
  );
  
}

export default Footer;
