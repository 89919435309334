import { Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./components/layout/Footer";
import Home from "./pages/Home";
import AllQueries from "./pages/AllQueries";
import SignIn from "./pages/SignIn";
import Terms from "./pages/terms";
import Query from "./pages/Query";
import Entities from "./pages/Entities";
import Main from "./components/layout/Main";
import { Loader } from "./components/loader";
import { getAllUsers, login } from "./store/action/user";

// import "antd/dist/antd.min.css";
import "./assets/styles/main.css";
import "./assets/styles/bootstrap.scss";
import "./assets/styles/responsive.css";
import Page404 from "./pages/404";
import LayoutAlt from "./components/layout2/Main";
import Users from "./pages/users";
import ChangePassword from "./pages/changePassword";
import { user_role } from "./consts";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ForgotPassword from "./pages/forgotpassword";
import Privacy from "./pages/privacy";
import AllowEntity from "./components/activity/AllowEntity";
import Help from "./pages/Help";
import mixpanel from "mixpanel-browser";
import Invite from "./pages/Invite";
import Financialyears from './pages/financial_years';
mixpanel.init("4a0b00365d809c96957f6c8086de8071", {
  debug: true,
});

function LayoutWrapper({ children }) {
  let { email } = useSelector((st) => st.user);
  if (!email) return <Navigate to="/sign-in" />;
  return <Main>{children}</Main>;
}

function App() {
  const dispatch = useDispatch();
  const [cookie, setCookie] = useCookies(["userInfo"]);
  const [loading, setLoading] = useState(true);
  let { role, _id } = useSelector((st) => st.user);
  useEffect(() => {
    // console.log('Cookie Data:', cookie.userInfo);
    if (cookie.userInfo?._id) {
      const getUserInfo = async () => {
        try {
          let res = await fetch(
            `${process.env.REACT_APP_API_URL}/user/${cookie.userInfo?._id}`,
            { credentials: "include" }
          );
          let status = res.status;
          res = await res.json();
          // console.log(res);
          if (status === 200) {
            dispatch(login(res.response));
            // console.log(cookie.userInfo.selectedCompany);
            setCookie("userInfo", {
              ...res.response,
              selectedCompany: cookie.userInfo?.selectedCompany,
            });
            dispatch(getAllUsers());
          }
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      };
      getUserInfo();
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div className="App">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <LayoutWrapper>
                <Home />
              </LayoutWrapper>
            }
          />
          {role === user_role.ADMIN && (
            <Route
              exact
              path="/approve-documents"
              element={
                <LayoutWrapper>
                  <AllowEntity />
                </LayoutWrapper>
              }
            />
          )}
          <Route
            exact
            path="/queries"
            element={
              <LayoutWrapper>
                <AllQueries />
              </LayoutWrapper>
            }
          />
          <Route
            exact
            path="/financial_years"
            element={
              <LayoutWrapper>
                <Financialyears/>
              </LayoutWrapper>
            }
          />
          <Route
            exact
            path="/privacy"
            element={
              _id ? (
                <LayoutWrapper>
                  <Privacy />
                </LayoutWrapper>
              ) : (
                <LayoutAlt>
                  <Privacy />
                </LayoutAlt>
              )
            }
          />
          <Route
            exact
            path="/termsandcondition"
            element={
              _id ? (
                <LayoutWrapper>
                  <Terms />
                </LayoutWrapper>
              ) : (
                <LayoutAlt>
                  <Terms />
                </LayoutAlt>
              )
            }
          />
          {role === user_role.ADMIN && (
            <Route
              exact
              path="/users"
              element={
                <LayoutWrapper>
                  <Users />
                </LayoutWrapper>
              }
            />
          )}
            <Route
              exact
              path="/Entities"
              element={
                <LayoutWrapper>
                  <Entities />
                </LayoutWrapper>
              }
            />
          
          <Route
            exact
            path="/query/:query_id"
            element={
              <LayoutWrapper>
                <Query />
              </LayoutWrapper>
            }
          />
          {/* <Route
						exact
						path="/check"
						element={<LayoutWrapper>< /></LayoutWrapper>}
					/> */}
          <Route
            exact
            path="/help-center"
            element={
              <LayoutWrapper>
                <Help />
              </LayoutWrapper>
            }
          />
          <Route
            exact
            path="/change_password"
            element={
              <LayoutWrapper>
                <ChangePassword />
              </LayoutWrapper>
            }
          />

          <Route
            path="/sign-in"
            exact
            element={
              <LayoutAlt>
                <GoogleReCaptchaProvider
                  reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                >
                  <SignIn />
                </GoogleReCaptchaProvider>
              </LayoutAlt>
            }
          />

          <Route
            path="/forgot-password"
            exact
            element={
              <LayoutAlt>
                <ForgotPassword />
              </LayoutAlt>
            }
          />
          <Route
            path="/invite/:domain"
            element={
              <LayoutAlt>
                <Invite />
              </LayoutAlt>
            }
          />
          <Route
            path="*"
            exact
            element={
              <LayoutAlt>
                <Page404 />
              </LayoutAlt>
            }
          />
        </Routes>
        <Footer />
      </div>
    </React.Fragment>
  );
}

class AppWrapper extends React.Component {
  componentDidCatch(err) {
    console.log(err);
  }

  render() {
    return <App />;
  }
}

export default AppWrapper;
