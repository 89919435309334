import { _fetch } from "../../_fetch";

export const updateFY = (companyId) => ({
    type: 'UPDATE',
    payload: companyId
});

export const changeSelectedFY = (selected) => ({
    type: 'CHANGE_SELECTED_YEAR',
    payload: selected
});

export const updateData = (fy) => {
    return {
        type: 'UPDATED_FROM_ALL_DATA',
        payload: fy
    }
}

export const priorityUpdate = (fy) => {
    return {
        type: 'PRIORITY_UPDATE',
        payload: fy
    }
}

export const statusUpdate = (fy) => {
    return {
        type: 'STATUS_UPDATE',
        payload: fy
    }
}

export const getFinancialYears = (clientIds) => {
    // console.log(clientIds);
    return async dispatch => {
        if (!clientIds.length) {
            dispatch({ type: 'SET_ALL_YEARS_GROUPED', payload: [] });
            dispatch({ type: 'SET_ALL_YEARS', payload: [] });
            return null;
        }
        let res = await _fetch(`${process.env.REACT_APP_API_URL}/fy?client_id=${clientIds}`)
        let result = await res.json();
        if (res.status === 200 && Array.isArray(result.response) && result.response.length) {
            dispatch({ type: 'SET_ALL_YEARS_GROUPED', payload: result.response });
            let fys = result.response.map(e => e.financial_year.map(f => ({ ...f, client_id: e._id }))).flat();
            dispatch({ type: 'SET_ALL_YEARS', payload: fys });
            return fys;
        } else {
            dispatch({ type: 'SET_ALL_YEARS_GROUPED', payload: [] });
            dispatch({ type: 'SET_ALL_YEARS', payload: [] });
            return null;
        }
    }
}
