import { useState } from "react";
import { Input, message, Modal, Typography } from "antd";
import useFetch from "../_fetch";
import { getAllCompanies } from "../store/action/company";
import { useDispatch } from "react-redux";

export default function CreateCompany({ visible, setVisible }) {
  const _fetch = useFetch();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const [domain, setDomain] = useState("");
  let dispatch = useDispatch();

  function submit() {
    setLoading(true);
    _fetch(`${process.env.REACT_APP_API_URL}/company`, {
      method: "POST",
      body: { name: selected, domain: domain },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          console.log("123s")
          message.success("Australian Firm added successfully");
          dispatch(getAllCompanies());
          setVisible(false);
        } else {
          // console.log(res);
          message.error(res.response);
        }
      })
      .catch((err) => {
        message.error("Some error occurred while adding company");
        console.log(err);
      })
      .finally(() => setLoading(false));
  }

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      title={<h6 className="font-semibold m-0">Add new Australian Firm</h6>}
      okText="Add Australian Firm"
      onOk={submit}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ loading: loading }}
    >
      <label>Australian Firm Name</label>
      <Input value={selected} onChange={(ev) => setSelected(ev.target.value)} />
      <label>Domain</label>
      <Input placeholder="example.com" value={domain} onChange={(ev) => setDomain(ev.target.value)} />
      <div style={{marginTop:"0.35rem"}}>
      {domain?<Typography.Text
          code={true}
        >{`${process.env.REACT_APP_HOME}/invite/${domain}`}</Typography.Text>:<></>}
      </div>
    </Modal>
  );
}
