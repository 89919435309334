const initState = {
    _id: [],
    selected: "",
    data: {},
    all: [],
    selectedFY: {} // Added selectedFY to the state
  };
  
  export const clientReducer = (state = initState, action) => {
    switch (action.type) {
      case 'UPDATE_CLIENT': {
        return {
          ...state,
          all: state.all.map((e) => {
            if (e._id === action.payload._id) {
              return action.payload;
            }
            return e;
          }),
        };
      }
  
      case 'CHANGE_SELECTED_CLIENT': {
        return {
          ...state,
          selected: action.payload,
        };
      }
  
      case 'SET_ALL_CLIENTS': {
        return {
          ...state,
          all: action.payload,
        };
      }
  
      case 'SET_SELECTED_FINANCIAL_YEAR': { // Added case for setting selected financial year
        console.log('SET_SELECTED_FINANCIAL_YEAR action payload:', action.payload);
        return {
          ...state,
          selectedFY: action.payload,
        };
      }
  
      default:
        return state;
    }
  };
  