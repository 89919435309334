/* eslint-disable no-loop-func */
import { Button, Tag, Upload, Space, Image, Typography, message } from 'antd';
import pdflogo from "../../assets/images/download.svg"
import text from "../../assets/images/text.svg"
import zip from "../../assets/images/zip.svg"
import xlsx from "../../assets/images/xlsx.svg"
import xls from "../../assets/images/xls.svg"
import CSV from "../../assets/images/CSV.svg"
import { DeleteOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { storeFiles } from '../../store/action/files';
import { CgAttachment } from 'react-icons/cg';
import useFetch from '../../_fetch';
import PermissionModal from '../PermissionModal';
import { Buffer } from 'buffer';
import { user_role } from '../../consts';
import './styles.css';
import FileItem from '../FilesItem';


export default function Files({ setQuery, query_id, id, query, queryStatus }) {
    const [loading, setLoading] = useState(false);
    const _fetch = useFetch();
    const dispatch = useDispatch();
    const { _id, role } = useSelector((st) => st.user);
    const [queryFiles, setQueryFile] = useState([]);
    const [filesUpload, setFilesUpload] = useState([]);
    const [permissionModal, setPermissionModal] = useState(null);
    const uploadUrl = useRef();
    const Reload = (ev) => {
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';

    }

    const handleQueryStatusUpdate = async (update) => {
        if (update?.status === queryStatus) return;
        try {
            setLoading(true);
            let body = update;
            let updatedQuery = await _fetch(`${process.env.REACT_APP_API_URL}/query/${query_id}`, {
                method: 'PATCH',
                body,
            });
            let resJson = await updatedQuery.json();
            if (resJson.status === 200) {
                console.log(resJson.response)
                message.success('Status Updated');
                setQuery(resJson.response)
            } else {
                message.error('Status Update Failed');
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }


    const beforeUpload = async (file) => {
        try {
            // console.log('beforeUpload ran with file', file);
            const fileParts = file.name.split(".");
            fileParts.pop();
            let name = fileParts.join(".");
            let ext = file.name.split('.').pop();
            name = `${name}-${moment().valueOf()}.${ext}`
            console.log(name);
            let url = await _fetch(
                `${process.env.REACT_APP_API_URL}/file/get_upload_url?name=${name}&query=${id}`
            );
            if (url.status === 200) {

                url = await url.json();
                uploadUrl.current = url.response;
                console.log(
                    'url to upload generation Success with url ',
                    uploadUrl
                );
            } else {
                console.log('url to upload generation failed');
                throw new Error('url to upload generation failed');
            }
        } catch (e) {
            console.log(e);
        }
    };
    async function init() {
        try {
            let files = await _fetch(
                `${process.env.REACT_APP_API_URL}/file?query=${id}`
            );
            if (files.status === 200) {
                files = await files.json();

                setQueryFile(files.response);
                dispatch(storeFiles(files.response));
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const upload = async (data, screenShot = false) => {
        setLoading(true)
        window.addEventListener("beforeunload", Reload)
        console.log('trying to upload an image on url:', uploadUrl.current);
        console.log('uploading data', { screenShot, data });
        try {
            let options;
            let contentType = "";
            // check if screenshot
            if (!screenShot) {
                let file = data.file;
                contentType = file.type;
                console.log(file);
                options = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': contentType,
                    },
                    body: file,
                };
            } else {
                // custom upload for ctrl+V screenShots ,Reason: getting Image in base64 format :AWS different way of upload for base64
                console.log('trying to upload screenshot');
                contentType = 'image/jpeg';
                try {
                    var buf = new Buffer.from(
                        data.replace(/^data:image\/\w+;base64,/, ''),
                        'base64'
                    );
                    options = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': contentType,
                        },
                        body: buf,
                    };
                } catch (e) {
                    console.warn('error uploading screenshot', String(e));
                }
            }

            let res = await fetch(uploadUrl.current, options);
            // console.log('url is ', url);
            if (res.status === 200) {
                try {
                    let parsedUrl = new URL(uploadUrl.current);
                    // console.log(parsedUrl);
                    let pathname = parsedUrl.pathname.slice(1);
                    // console.log(pathname);
                    let uploadedRes = await _fetch(
                        `${process.env.REACT_APP_API_URL}/file`,
                        {
                            method: 'POST',
                            body: {
                                query: id,
                                path: pathname,
                                type: contentType
                            },
                        }
                    );
                    if (uploadedRes.status === 200) {

                        handleQueryStatusUpdate({ status: "OPEN" })
                        setLoading(false);
                        window.removeEventListener("beforeunload", Reload)

                        uploadedRes = await uploadedRes.json();
                        setQueryFile((f) => [uploadedRes.response, ...f]);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };
    const deleteFile = async () => {
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/file`, {
                method: 'DELETE',
                body: {
                    id: permissionModal._id,
                    user_id: _id
                }
            });
            let resJson = await res.json();
            if (resJson.status === 200) {
                message.success('File deleted successfully');
                init();
            } else {
                message.error(resJson.response);
            }
        } catch (e) {
            message.error('Error deleting file');
            console.log(e);
        } finally {
            setPermissionModal(null);
        }
    }
    return (
        <div>
            <div>
                {queryFiles.filter((s) => s.commentId === null).length
                    ? queryFiles
                        .filter((s) => s.commentId === null)
                        .map((e) => {
                            return <FileItem
                                file={e} allowDelete={role === user_role.ADMIN}
                                deleteClicked={(event) => {
                                    event.stopPropagation();
                                    if (queryStatus === 'CLOSED') {
                                        message.error('Cannot delete file from closed query');
                                        return;
                                    }
                                    setPermissionModal(e);
                                }} />
                        })
                    : null}
            </div>

            <Space className='mt-2 mx-2'>
                <Upload
                    beforeUpload={beforeUpload}
                    customRequest={upload}
                    showUploadList={false}
                >
                    <Button loading={loading} icon={<CgAttachment size={15} />} className='me-1' >
                        Attach File
                    </Button>

                </Upload>


                {filesUpload.map((e, index) => (
                    <Tag
                        className='pointer m-1'
                        style={{
                            padding: '4px',
                            borderRadius: '5px',
                            maxWidth: '180px',
                        }}
                        onClick={() =>
                            setFilesUpload((e) => {
                                delete e[index];
                                return e.filter((sm) => Boolean(sm));
                            })
                        }
                    >
                        <span
                            style={{ display: 'inline' }}
                            className='myDIV border'
                        >
                            {e.fileName}
                        </span>
                        {/* <AiFillCloseSquare style={{}} className='hide border' /> */}
                    </Tag>
                ))}
            </Space>
            <PermissionModal
                visible={permissionModal !== null}
                setVisible={setPermissionModal}
                onSuccess={deleteFile}
                child={
                    <p>Are you sure you want to delete this <b>Attachment</b>? </p>
                }
            />
        </div >
    );
}
