import React, { useEffect, useState, useCallback,useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { _fetch } from "../_fetch";
import { Loader } from "../components/loader";
import { login, signUp } from "../store/action/user";
import { useCookies } from "react-cookie";
import {
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Modal,
  message,
} from "antd";

const { Title } = Typography;
const Invite = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [company_id, setComapny_id] = useState("");
  const navigate = useNavigate();
  const [, setCookie] = useCookies(["userInfo"]);
  const dispatch = useDispatch();
  
  const { domain } = useParams();
  
  const inputRef = useRef(null);

   const sharedProps = {
     defaultValue: `@${domain}`,
     ref: inputRef,
   };
  const showModal = () => setOpen(true);

  const checkDomain = useCallback(async () => {
    const resp = await _fetch(
      `${process.env.REACT_APP_API_URL}/company/verify/domain?domain=${domain}`
    );
    const data = await resp.json();
    setLoading(false);
    if (data.status === 400) {
      alert("Not Found");
      setTimeout(() => {
        navigate("/help-center");
      }, 1000);
    }

    console.log(data);
    setComapny_id(data.response._id);
  }, [domain]);

  useEffect(() => {
    setLoading(true);
    checkDomain();
  }, []);

  const handleOk = async () => {
    // window.ps = document.getElementById('otpInput');
    let otp = document.getElementById("otpInput").value;
    console.log(
      "this is otp value:",
      document.getElementById("otpInput").value
    );
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}/user/otp`;
      console.log("fetching , url");
      let res = await _fetch(url, {
        method: "POST",
        body: {
          email: `${window.email}`,
          otp,
        },
      });
      let resData;
      if (res.status === 200) {
        resData = await res.json();
        console.log("got response of otp submit", resData);
        dispatch(login({ ...resData.response }));
        setCookie(
          "userInfo",
          { ...resData.response },
          {
            maxAge: process.env.REACT_APP_USER_COOKIE_EXPIRY * 3600000,
          }
        );
        navigate("/sign-in");
      } else {
        console.log({ res, resData: await res.json() });
        message.error("entered OTP is incorrect");
      }
    } catch (e) {
      console.error("otp submit post failed", e);
      // message.error('entered OTP is incorrect');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const _signUp = async (values) => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/user/company-register`;
      const resp = await _fetch(url, {
        method: "POST",
        body: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          company_id: company_id,
        },
      });
      var resData;
      if (resp.status === 200) {
        resData = await resp.json();
        console.log(resData);
        message.success("OTP Sent to your Email");
        window.email = values.email;
        showModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Modal
        width={200}
        destroyOnClose
        open={open}
        title="Enter OTP"
        onOk={form.submit}
        onCancel={handleCancel}
        centered
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
          // <Button
          // 	key="link"
          // 	href="https://google.com"
          // 	type="primary"
          // 	loading={loading}
          // 	onClick={handleOk}
          // >
          // 	Search on Google
          // </Button>,
        ]}
      >
        <Form form={form} onFinish={handleOk}>
          <Input id="otpInput" label="otp" />
        </Form>
      </Modal>
      <Row gutter={[24, 0]} justify="space-around">
        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 10, offset: 2 }}
          md={{ span: 12 }}
        >
          <Title className="mb-15">Sign Up</Title>

          <Form
            onFinish={_signUp}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
            name="login"
          >
            <Form.Item
              className="username"
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              className="username"
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please input your last name!",
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              className="username"
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input
                {...sharedProps}
                type="text"
                placeholder="Email"
                onFocus={() => {
                  inputRef.current.focus({
                    cursor: "start",
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              className="username"
              style={{ marginBottom: "5px" }}
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                SIGN UP
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Invite;
