import { Row, Col, Card, message, Table, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { changeSelectedClient } from "../store/action/client";
import { useNavigate } from "react-router-dom";

import { getClients } from "../store/action/client";
import {
  changeSelectedFY,
  getFinancialYears,
  priorityUpdate,
  statusUpdate,
  updateData,
} from "../store/action/fy";

import SearchBox from "../components/SearchBox";
import Fyassign from "../components/querySidebar/fyAsing";
import FYPrioritySelector from "../components/prioritySelector";
import FYStatusSelector from "../components/FYStatus";
import useFetch from "../_fetch";
import { user_role, priority_order, fy_status_order } from "../consts";
import { Loader } from "../components/loader";
import { ChangeQuery } from "../store/action/queries";

const priorityFilters = priority_order.map((e) => ({ text: e, value: e }));
const statusFilters = fy_status_order.map((e) => ({ text: e, value: e }));
const entityFilters = (dat) => {
  let map = {};
  let filter = [];
  dat.forEach((e) => {
    if (!map[e.title]) {
      map[e.title] = 1;
      filter.push({ text: e.title, value: e.title });
    }
  });
  return filter;
};
const assigneeFilters = (dat) => {
  let map = {};
  let filter = [];
  dat.forEach((e) => {
    let name = `${e.first_name} ${e.last_name}`;
    if (!map[name]) {
      map[name] = 1;
      filter.push({ text: name, value: name });
    }
  });
  return filter;
};

export default function Home() {
  const navigate = useNavigate();
  let dispatch = useDispatch();

  const [fyy, setFyy] = useState("");
  const placeholder = "Search Entities...";
  const [toggle, setTogle] = useState(false);
  const [show, setShow] = useState([]);
  const [flatData, setFlatData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  const { selected: selectedCompany } = useSelector((st) => st.company);
  const { all: client } = useSelector((st) => st.client);

  const { grouped: fy, all } = useSelector((st) => st.fy);
  const user = useSelector((st) => st.user);
  const _fetch = useFetch();

  useEffect(() => {
    getClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, toggle]);

  const columns = [
    {
      title: `Entity (${dataLength})`,
      dataIndex: "title",
      key: "entity",
      render: (item) => <span className="pointer">{item}</span>,
      sorter: (a, b) => {
        a = a.title;
        b = b.title;
        return `${a?.toLowerCase()}` > `${b?.toLowerCase()}` ? 10 : -10;
      },
      filters: entityFilters(show),
      onCell: (record) => {
        return {
          onClick: (event) => {
            let changedClient = client.find((e) => e._id === record.client_id);
            let changedFY = {
              _id: record.fy_id,
              start: record.start,
              end: record.end,
              priority: record.priority,
              assigned_to: {
                first_name: record.first_name,
                last_name: record.last_name,
              },
            };
            dispatch(changeSelectedClient(changedClient));
            dispatch(changeSelectedFY(changedFY));
            navigate(
              `/queries?fy=${record.fy_id}&entity=${record.client_id}&company=${selectedCompany._id}`
            );
          },
        };
      },
      onFilter: (value, record) => record.title === value,
    },
    {
      title: `Financial Year`,
      key: "financialYear",
      render: (record) => (
        <span style={{ fontWeight: "600" }} className="pointer">
          {moment(record.start).format("YYYY")} -{" "}
          {moment(record.end).format("YY")}
        </span>
      ),
      sorter: (a, b) => {
        const aa = moment(a.start);
        const bb = moment(b.start);
        return aa.isAfter(bb) ? 10 : -10;
      },
      onCell: (record) => {
        return {
          onClick: (event) => {
            let changedClient = client.find((e) => e._id === record.client_id);
            let changedFY = {
              _id: record.fy_id,
              start: record.start,
              end: record.end,
              priority: record.priority,
              assigned_to: {
                first_name: record.first_name,
                last_name: record.last_name,
              },
            };
            dispatch(changeSelectedClient(changedClient));
            dispatch(changeSelectedFY(changedFY));
            navigate(
              `/queries?fy=${record.fy_id}&entity=${record.client_id}&company=${selectedCompany._id}`
            );
          },
        };
      },
    },
    {
      title: "Last Updated (Date)",
      key: "lastQueryAdded",
      render: (record) => (
        <>
          <span className="pointer">
            {" "}
            {record.latest_query ? (
              moment(record.latest_query).format("DD/MM/YYYY")
            ) : (
              <p
                style={{
                  color: "grey",
                }}
              >
                No Update
              </p>
            )}
          </span>
        </>
      ),
      sorter: (a, b) => {
        const aa = moment(a.latest_query);
        const bb = moment(b.latest_query);
        return aa.isBefore(bb) ? -10 : 10;
      },
    },
    {
      title: "Priority",
      key: "priority",
      sorter: (a, b) => {
        const aa = priority_order.indexOf(a?.priority);
        const bb = priority_order.indexOf(b?.priority);
        return aa - bb;
      },
      render: (record) => (
        <FYPrioritySelector
          item={{
            _id: record.fy_id,
            priority: record.priority,
          }}
          handlePriorityUpdate={handlePriorityUpdate}
          isDisabled={user.role === user_role.COMPANY}
        />
      ),
      filters: priorityFilters,
      onFilter: (value, record) => record.priority === value,
    },
    {
      title: "Status",
      key: "status",
      sorter: (a, b) => {
        const aa = fy_status_order.indexOf(a?.status);
        const bb = fy_status_order.indexOf(b?.status);
        return aa - bb;
      },
      render: (record) => (
        <FYStatusSelector
          item={{
            _id: record.fy_id,
            status: record.status,
          }}
          handleStatusUpdate={handleStatusUpdate}
          isDisabled={user.role === user_role.COMPANY}
        />
      ),
      filters: statusFilters,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Assigned To",
      key: "assigned_to",
      render: (record) => (
        <div
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
          onClick={() => setFyy(record.fy_id)}
        >
          <Fyassign
            query={{
              assigned_to: {
                first_name: record.first_name,
                last_name: record.last_name,
              },
            }}
            className="p-2"
            handleFyUpdate={handleFyUpdate}
            role={""}
          />
        </div>
      ),
      filters: assigneeFilters(show),
      onFilter: (value, record) =>
        `${record.first_name} ${record.last_name}` === value,
    },
  ];

  useEffect(() => {
    handleDataChange();
  }, [client, fy]);

	async function getClient() {
    let check = user.company_ids?.find((e)=>e===selectedCompany?._id);
		if ((check || user.role===user_role.ADMIN) && selectedCompany) {
			try {
				setLoading(true);
				let clients = await dispatch(await getClients(selectedCompany._id));
				await dispatch(await getFinancialYears(Array.isArray(clients) && clients.length ? clients.map(e => e._id) : []));
			} catch (e) {
				message.error('Some error occurred while fetching data');
				console.log(e);
			} finally {
				setLoading(false)
			}
		}
	}

  function handleDataChange(t = true) {
    try {
      let temp = fy
        ?.reduce((acc, cur, ind) => {
          return [
            ...acc,
            ...cur.financial_year?.map((item, index) => {
              let latest;
              if (
                item.latest_query[0]?.updatedAt === undefined &&
                item.latest_documents[0]?.updatedAt !== undefined
              ) {
                latest = item.latest_documents[0]?.updatedAt;
              } else if (
                item.latest_query[0]?.updatedAt !== undefined &&
                item.latest_documents[0]?.updatedAt === undefined
              ) {
                latest = item.latest_query[0]?.updatedAt;
              } else {
                latest =
                  item.latest_query[0]?.updatedAt >=
                  item.latest_documents[0]?.updatedAt
                    ? item.latest_query[0]?.updatedAt
                    : item.latest_documents[0]?.updatedAt;
              }

              return {
                start: item.start,
                end: item.end,
                fy_id: item._id,
                priority: item.priority,
                status: item.status,
                first_name: item.assigned_to?.first_name,
                last_name: item.assigned_to?.last_name,
                client_id: cur.client_id,
                latest_query: latest,
                title: client.find((e) => e._id === cur.client_id)?.title,
              };
            }),
          ];
        }, [])
        .filter((item) =>
          t ? item.status !== "CLOSED" : item.status === "CLOSED"
        )
        .sort((a, b) => {
          if (a.title?.toUpperCase() < b.title?.toUpperCase()) {
            return -1;
          }
          if (a.title?.toUpperCase() > b.title?.toUpperCase()) {
            return 1;
          }
          const aa = moment(a.start).format("LL");
          const bb = moment(b.start).format("LL");
          return `${aa?.toLowerCase()}` > `${bb?.toLowerCase()}` ? 10 : -10;
        });
      setShow(temp);
      setDataLength(temp.length);
      setFlatData(temp);
    } catch (e) {
      console.log(e);
    }
  }

  const handleFyUpdate = async (update) => {
    try {
      const payload = {
        ...update,
        fy: fyy,
      };
      let updatedQuery = await _fetch(`${process.env.REACT_APP_API_URL}/fy`, {
        method: "PATCH",
        body: payload,
      });
      let resJson = await updatedQuery.json();
      if (resJson.status === 200) {
        dispatch(await updateData(resJson.response));
        message.success(`Assigned to ${resJson.response.assigned_to.first_name} ${resJson.response.assigned_to.last_name} successfully`);
      } else {
        message.error("Some error occurred while updating assigned to");
      }
    } catch (e) {
      console.log(e);
      message.error("Some error occurred while updating assigned to");
    }
  };
  const handlePriorityUpdate = async (item, newPriority) => {
    if (item.priority !== newPriority) {
      try {
        const payLoad = {
          fy: item._id,
          priority: newPriority,
        };
        let updatedQuery = await _fetch(`${process.env.REACT_APP_API_URL}/fy`, {
          method: "PATCH",
          body: payLoad,
        });
        let resJson = await updatedQuery.json();
        if (resJson.status === 200) {
          dispatch(await priorityUpdate(resJson.response));
          message.success("Priority updated successfully");
        } else {
          message.error("Some error occurred while updating priority");
        }
      } catch {
        message.error("Some error occurred while updating priority");
      }
    }
    return;
  };

  const handleStatusUpdate = async (item, newStatus) => {
    if (item.status !== newStatus) {
      try {
        const payLoad = {
          fy: item._id,
          status: newStatus,
        };
        let updatedQuery = await _fetch(`${process.env.REACT_APP_API_URL}/fy`, {
          method: "PATCH",
          body: payLoad,
        });
        let resJson = await updatedQuery.json();
        if (resJson.status === 200) {
          dispatch(await statusUpdate(resJson.response));
          message.success("Status updated successfully");
          await dispatch(ChangeQuery(resJson.response));
        } else {
          message.error("Some error occurred while updating status");
        }
      } catch {
        message.error("Some error occurred while updating status");
      }
    }
    return;
  };

  const callbackTabClicked = (key, event) => {
    if (key === "tab1") {
      handleDataChange(true);
    }
    if (key === "tab2") {
      handleDataChange(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Tabs type="card" onTabClick={callbackTabClicked}>
            <items
              tab={
                <Typography.Title level={5} className="align-self-start">
                  <div className="flex-fill search d-flex">
                    <span className="me-3">Open Financial Years</span>
                  </div>
                </Typography.Title>
              }
              key="tab1"
            >
              {
                <div className="table-responsive">
                  <Row gutter={[24, 0]}>
                    <Col xs={24} className="mb-<FySidebar 24">
                      <Card
                        className="header-solid h-full"
                        bordered={false}
                        title={[
                          <div className="d-flex">
                            <div className="flex-fill search d-flex">
                              <div className="d-flex flex-fill justify-content-center">
                                <SearchBox
                                  all={flatData}
                                  setshow={setShow}
                                  name={placeholder}
                                />
                              </div>
                            </div>
                          </div>,
                        ]}
                        bodyStyle={{ paddingTop: "0" }}
                      >
                        {
                          <Table
                            columns={columns}
                            dataSource={[...show]}
                            pagination={false}
                            sortDirections={["descend", "ascend"]}
                            size="small"
                            loading={loading}
                            onChange={(pagination, filters, sorter, extra) => {
                              setDataLength(extra.currentDataSource.length);
                            }}
                          />
                        }
                      </Card>
                    </Col>
                  </Row>
                </div>
              }
            </items>
            {
              <items
                tab={
                  <Typography.Title level={5} className="align-self-start">
                    Closed Financial Years
                  </Typography.Title>
                }
                key="tab2"
              >
                <div className="table-responsive">
                  <Row gutter={[24, 0]}>
                    <Col xs={24} className="mb-<FySidebar 24">
                      <Card
                        className="header-solid h-full"
                        bordered={false}
                        title={[
                          <div className="d-flex">
                            <div className="flex-fill search d-flex">
                              <div className="d-flex flex-fill justify-content-center">
                                <SearchBox
                                  all={flatData}
                                  setshow={setShow}
                                  name={placeholder}
                                />
                              </div>
                            </div>
                          </div>,
                        ]}
                        bodyStyle={{ paddingTop: "0" }}
                      >
                        <Table
                          columns={columns}
                          dataSource={show}
                          pagination={false}
                          sortDirections={["descend", "ascend"]}
                          size="small"
                          loading={loading}
                          onChange={(pagination, filters, sorter, extra) => {
                            setDataLength(extra.currentDataSource.length);
                          }}
                        />
                      </Card>
                    </Col>
                  </Row>
                </div>
              </items>
            }
          </Tabs>
        </div>
      )}
    </>
  );
}
