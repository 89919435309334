export default function Terms() {
    return (
        <div>
            <h1 style={{ fontSize: 16, fontWeight: 600 }}>Terms & Conditions – Efiles</h1>
            <p style={{ fontSize: 12 }}>These Terms and Conditions ("Terms") govern your access to and use of the efiles.com.au (the "Website") provided by Auslink ("we", "us" or "our"). Auslink is located in India but efiles is hosted on Australian Server secured email 2FA . By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Website and ask us to suspend your account.</p>
            <h1 style={{ fontSize: 14, fontWeight: 600 }}>1. Use of the Website</h1>
            <p style={{ fontSize: 12 }}>1.1 You may only use the Website in accordance with these Terms, applicable laws and regulations</p >
            <p style={{ fontSize: 12 }}>1.2 You must provide accurate and complete registration information to access and use the Website. You are solely responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account</p>
            <p style={{ fontSize: 12 }}>1.3. You agree not to use the Website in any manner that violates any applicable law or regulation, including, but not limited to, intellectual property rights, privacy rights, or any other rights of any person or entity.</p>
            <p style={{ fontSize: 12 }}>1.4. You agree not to use the Website to transmit any material that is defamatory, obscene, or offensive, or that promotes or facilitates any illegal activity.</p>
            <p style={{ fontSize: 12 }}>1.5. You agree not to use the Website to transmit any viruses, worms, or other harmful code</p>
            <p style={{ fontSize: 12 }}>1.6 You agree not to use the Website in any manner that could damage, disable, overburden, or impair the Website or interfere with any other party's use and enjoyment of the Website</p>
            <p style={{ fontSize: 12 }}>1.7. We reserve the right to suspend or terminate your access to the Website at any time for any reason, without notice or liability to you</p><br />

            <h1 style={{ fontSize: 14, fontWeight: 600 }}>2. Ownership and Intellectual Property</h1>
            <p style={{ fontSize: 12 }}>2.1  We own all right, title, and interest in and to the Website, including all intellectual property rights</p >
            <p style={{ fontSize: 12 }}>2.2 You acknowledge that you have no ownership rights in or to the Website or any of its content.</p>
            <p style={{ fontSize: 12 }}>2.3. You may not use any of our trademarks or logos without our prior written consent.</p><br />

            <h1 style={{ fontSize: 14, fontWeight: 600 }}>3. Privacy Policy</h1>
            <p style={{ fontSize: 12 }}>3.1  Our Privacy Policy is incorporated into these Terms and governs our collection, use, and disclosure of your personal information.</p >
            <p style={{ fontSize: 12 }}>3.2 By using the Website, you consent to our collection, use, and disclosure of your personal information in accordance with our Privacy Policy</p>



        </div >
    )
}