import React from 'react';
import "../../src/assets/styles/main.css";

const ProposedBadge = ({ content, top, left, right, bottom, textColor, bgColor, position }) => {
    const style = {
        top: top,
        left: left,
        right: right,
        bottom: bottom,
        position: position, 
        color: textColor,      
        background: bgColor    
    };

    return (
        <div className="proposed-tag" style={style}>
            {content}
        </div>
    );
};

export default ProposedBadge;
