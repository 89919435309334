import { useState } from 'react';
import { Input, message, Modal, Typography } from 'antd';
import useFetch from '../_fetch';
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedClient, getClients, setSelectedFinancialYear } from '../store/action/client';
import DropDown from './DropDown';
import { AiFillCloseCircle } from 'react-icons/ai';
import moment from 'moment';
import { changeSelectedFY } from '../store/action/fy';
import * as dayjs from 'dayjs'
export default function CreateClient({ visible, setVisible }) {

	const dispatch = useDispatch();
	const _fetch = useFetch();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const { selected: selectedCompany } = useSelector(st => st.company);
	const [fy, setFy] = useState('');

	async function submit() {
		setLoading(true);
		try {
			let res = await _fetch(`${process.env.REACT_APP_API_URL}/client`, {
				method: "POST", body: {
					company_id: selectedCompany._id,
					title: data.title,
					abn: data.abn,
					fy: moment(fy, 'YYYY').year(),
				}
			})
			let response = await res.json();
			console.log(response, 'api response');
			if (res.status === 200) {
				message.success('Entity added successfully');
				dispatch(getClients(selectedCompany._id))
				dispatch(changeSelectedClient(response.response.client));

				// if (response.success && response.response && response.response.financialYear) {
				// 	console.log('Selected Financial Year:', financialYearId);
					
					
					dispatch(changeSelectedFY(response.response.financialYear));
				// } else {
				//   console.log('No financial year data found in response');
				//   // Handle case where financial year data is missing
				// }
			  
				// if (response.response && response.response.financialYear) {
				// 	dispatch(changeSelectedFY(response.response.financialYear));
				// 	setFy(response.response.financialYear._id);
				//   }
				setVisible(false);
			  } else {
				if (response.response === "company_id is required") {
				  message.error("Please add a company before adding a client");
				} else {
				  message.error(response.response);
				}
			  }
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	}

	return (
		<Modal
			visible={visible}
			onCancel={() => { setVisible(false); setFy(null); setData(null); }}
			title={<h6 className="font-semibold m-0">Add new Entity</h6>}
			okText="Add Entity" onOk={submit}
			destroyOnClose
			okButtonProps={{ loading: loading }}
		>
			<label>Entity Name</label>
			<Input className='mb-3' onChange={(ev) => setData(pre => ({ ...pre, title: ev.target.value }))} />
			<label>ABN ID</label>
			<Input className='mb-3' onChange={(ev) => setData(pre => ({ ...pre, abn: ev.target.value }))} />
			{fy ?
				<Typography.Text className='d-flex justify-content-between'>
					<span><strong>Financial Year: </strong> {dayjs(fy).subtract(1, 'year').format('YYYY')} -{dayjs(fy).format('YY')}</span>
					<AiFillCloseCircle onClick={() => setFy('')} />
				</Typography.Text> :
				<DropDown canSubmit={false} returnSelected={(e) => { console.log(e); setFy(e) }} style={{ marginTop: "20px" }} />
			}
		</Modal>
	);
}
