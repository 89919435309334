import React, { useState, useEffect } from "react";
import { Card, Col, DatePicker, Button, Form, Input, message } from "antd";
import useFetch from "../../_fetch";
import { Loader } from "../loader/index";
import { useDispatch } from 'react-redux';
import { updateClient } from '../../store/action/client';
import moment from "moment";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

export default function AllowEntity(_id) {
  const [loading, setLoading] = useState(false);

  const _fetch = useFetch();
  let dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [Fy_id, setFy_id] =useState('');
  const [cookie, setCookie] = useCookies(["userInfo"]);

  useEffect(() => {
    try {
      setLoading(true);
      let res = _fetch(
      `${process.env.REACT_APP_API_URL}/client?company_id=${cookie?.userInfo.selectedCompany?._id}&status=PROPOSED`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data?.response[0].clients);
          setLoading(false);
        });



        let res1 = _fetch(
          `${process.env.REACT_APP_API_URL}/proposedFY?fy_status=PROPOSED`,
          {
          method: "GET",
        }
        )
        .then((res1) => res1.json())
        .then((data1) => {
          setData1(data1.response);
         
          setLoading(false);
        });
    }
    catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  }, []);


  const onFinish = async (d,data) => {
    if(!d?.id){
      message.error("Client not found");
      return;
    }
    setLoading(true);
    const body = {
      status: 'approved',
    };
    console.log(body)
    let final = await _fetch(
      `${process.env.REACT_APP_API_URL}/client/${d?.id}`,
      {
        method: "PATCH",
       
       body
      }
    );
    let resdata = await final.json();
    if (resdata.status === 200) {
        message.success('Entity updated successfully')
        dispatch(updateClient(resdata.response))
        setLoading(false);
    } else {
        message.error('Some error occurred while updating entity')
    }
    
    
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish1 = async (d1,data1) => {

    if (data1 && data1._id) {
        setFy_id(data1._id); 
        console.log('data1._id',data1?._id)
    } else {
        console.error('data1 is undefined or does not have an _id property');
    }

    setLoading(true);
    const body = {
      fy_status: 'approved',
       fy: data1?._id
    };
    console.log(body)
    let final = await _fetch(
      `${process.env.REACT_APP_API_URL}/fy`,
      {
        method: "PATCH",
       
       body
      }
    );
    let resdata = await final.json();
    if (resdata.status === 200) {
        message.success('fy updated successfully')
        dispatch(updateClient(resdata.response))
        setLoading(false);
    } else {
        message.error('Some error occurred while updating fy')
    }
    
    
  };
  const onFinish1Failed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <h1>Proposals</h1>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {(data) && data?.map((d) => {
            return (
            <Col span={8}>
              <Card
                style={{ margin: "10px" }}
                title='Proposed entity'
                bordered={false}
              >
                <Form
                  name="basic"
                  labelCol={{
                    span: 4,
                  }}
                  wrapperCol={{
                    span: 15,
                  }}
                  style={{
                    maxWidth: 400,
                  }}
                  initialValues={{
                    entityname: d?.title,
                    fy: dayjs(d?.proposed_fy),
                    // company: d?.company?.name,
                    id: d._id,
                    abn:d?.abn,
                    client_id: d?.client_id,
                    
                  }}
                  onFinish={(e) => onFinish(e,d)}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item label="Entity" name="entityname">
                  
                      <Input required={true} defaultValue={d?.title}  />
                  </Form.Item>

                  <Form.Item label="FY" name="fy">
                    <DatePicker picker="year" size="large" />
                  </Form.Item>
                  <Form.Item hidden={true} label="id" name="id">
                    <Input />
                  </Form.Item>
                  <Form.Item hidden={true} label="client_id" name="client_id">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="abn"
                    name="abn"
                    rules={[
                      {
                        required: true,
                        message: "Please input abn!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button type="primary" htmlType="submit" >
                      Finalize
                    </Button>
                  </Form.Item>
                  
                </Form>
              </Card>
            </Col>
          )})}
      </div>
      
       <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        { (data1) && data1?.map((d1) =>  {
            return (
            <Col span={8}>
              <Card
                style={{ margin: "10px" }}
                title={'proposed FY'} 
                bordered={false}
              >
                <Form
                  name="basic"
                  labelCol={{
                    span: 4,
                  }}
                  wrapperCol={{
                    span: 15,
                  }}
                  style={{
                    maxWidth: 400,
                  }}
                  initialValues={{
                    entityname: d1?.client,
                    fy: dayjs(d1.end),
                    company: d1?.company,
                    id: d1._id,
                    client_id: d1?.client_id?._id,
                  }}
                  onFinish={(e) => onFinish1(e,d1)}
                  onFinishFailed={onFinish1Failed}
                  autoComplete="off"
                >
                  <Form.Item label="Entity" name="entityname">
                  
                      {d1?.client_title}  
                  </Form.Item>

                  <Form.Item label="FY" name="fy">
                    <DatePicker picker="year" size="large" />
                  </Form.Item>
                  <Form.Item hidden={true} label="company" name="company">
                    <Input />
                  </Form.Item>
                  <Form.Item hidden={true} label="id" name="id">
                    <Input />
                  </Form.Item>
                  <Form.Item hidden={true} label="client_id" name="client_id">
                    <Input />
                  </Form.Item>
                  
                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button type="primary" htmlType="submit" >
                      Finalize
                    </Button>
                  </Form.Item>
                  
                </Form>
              </Card>
            </Col>
          )})}
      </div>

    </div>
  );
}
