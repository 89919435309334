module.exports = {
	user_status: {
		ACTIVE: 'ACTIVE',
		SUSPENDED: 'SUSPENDED',
	},
	user_role: {
		ADMIN: 'ADMIN',
		ACCOUNTANT: 'ACCOUNTANT',
		COMPANY: 'COMPANY'
	},
	query_type: {
		NORMAL: 'NORMAL',
		TRANSACTIONAL: 'TRANSACTIONAL',
		REVIEW_POINTS: 'REVIEW_POINTS'
	},
	fy_status: {
		OPEN: 'OPEN',
		QUERIES_PENDING: 'QUERIES PENDING',
		QUERIES_RESOLVED: 'QUERIES RESOLVED',
		CLOSED: 'CLOSED'
	},
	priority_order: ['NORMAL', 'HIGH', 'HIGHEST', 'URGENT', 'MOST URGENT'],
	fy_status_order: ['OPEN', 'QUERIES PENDING', 'QUERIES RESOLVED', 'CLOSED']
};
