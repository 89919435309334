import { _fetch } from "../../_fetch";

export const getVideos = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "VIDEOS_REQ" });
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/video`);
      let result = await res.json();
      if (result.success === false) {
        dispatch({ type: "VIDEOS_FAIL", payload: result.response });
      } else {
        let arr = result.response;
        const apiPromises = arr.map((e) =>
          fetch(`https://www.youtube.com/oembed?url=${e.uri}&format=json`)
        );
        let newArr = [];
        for (let i = 0; i < apiPromises.length; i++) {
          try {
            const response = await apiPromises[i];
            const data = await response.json();
            newArr.push({ title: data.title, thumbnail:data.thumbnail_url ,...arr[i] })
          } catch (error) {
            newArr.push({ title: "Invalid Video", thumbnail:"",...arr[i] })
          }
        }
        dispatch({ type: "GET_VIDEOS_SUCCESS", payload: newArr });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "VIDEOS_FAIL", payload: error });
    }
    
  };
};


export const addVideo = (uri) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "VIDEOS_REQ" });
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/video`,{method:'POST',body:{videoUri:uri}});
      let result = await res.json();
      if (result.success === false) {
        dispatch({ type: "VIDEOS_FAIL", payload: result.response });
      } else {
        let title = await fetch(`https://www.youtube.com/oembed?url=${result.response.uri}&format=json`);
        const resp = await title.json();
        dispatch({type:'ADD_VIDEOS_SUCCESS',payload: {...result.response,title:resp.title,thumbnail:resp.thumbnail_url}});
      }
    } catch (error) {
      dispatch({ type: "ADD_VIDEOS_SUCCESS", payload: error });
    }
    
  };
};


export const deleteVideo = (id)=>{
    return async(dispatch)=>{
        dispatch({ type: "VIDEOS_REQ" });
        let res = await _fetch(`${process.env.REACT_APP_API_URL}/video/${id}`,{method:'DELETE'});
        const result = await res.json();
        if (result.success === false) {
            dispatch({ type: "VIDEOS_FAIL", payload: result.response });
        }else {
            dispatch({type:'DELETE_VIDEO_SUCCESS',payload: result.response._id})
        }
    }
}

export const reorderVideo = (reorderedItems)=>{
  return async (dispatch,getState)=>{
    try {
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/video/reorder`,{method:'POST', body:{
        reorderedVideos: reorderedItems
      }});
      let result = await res.json();
      if (result.success === false) {
        dispatch({ type: "VIDEOS_FAIL", payload: result.response });
      }else{
        let arr = result.response;
        const apiPromises = arr.map((e) =>
          fetch(`https://www.youtube.com/oembed?url=${e.uri}&format=json`)
        );
        let payload = [];
        for (let i = 0; i < apiPromises.length; i++) {
          try {
            const response = await apiPromises[i];
            const data = await response.json();
            payload.push({ title: data.title, thumbnail:data.thumbnail_url ,...arr[i] })
          } catch (error) {
            payload.push({ title: "Invalid Video", thumbnail:"",...arr[i] })
          }
        }
        dispatch({ type: "REORDER_VIDEOS_SUCCESS", payload: payload });        
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: "VIDEOS_FAIL", payload: error });
    }
  }
}