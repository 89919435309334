import { Button } from "antd";
import { useDispatch } from "react-redux";
import { deleteVideo } from "../store/action/video";
import { MdDragIndicator } from "react-icons/md";
import { DeleteTwoTone } from "@ant-design/icons";
import { Draggable } from "react-beautiful-dnd";

export const VideoList = ({ el, index }) => {
  const dispatch = useDispatch();

  function getItemStyle(isDragging, draggableStyle){
    const style = {
      userSelect: "none",
      border: isDragging ? "1px solid #1890ff" : "1px solid #e8e8e8",
    }
    return {
      ...style,
      ...draggableStyle,
    }
  }

  return (
    <Draggable key={el._id} draggableId={el._id} index={index}>
      {(provided, snapshot) => (
        <div
          className="d-flex rounded p-2 my-3"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <div className="d-flex justify-content-center gap-3">
            <img
              src={el.thumbnail}
              alt="thumbnail"
              width={100}
              height={100}
              className="rounded"
            />
            <div className="d-flex">
              <h4 style={{ width: "320px" }}>{el.title}</h4>
              <MdDragIndicator cursor={"move"} />
            </div>
          </div>
          <Button
            type="text"
            className="ms-3 mr-10"
            onClick={() => {
              dispatch(deleteVideo(el._id));
            }}
          >
            <DeleteTwoTone size={15} twoToneColor="red" />
          </Button>
        </div>
      )}
    </Draggable>
  );
};
