import React, { useState } from 'react';
import useFetch from '../_fetch';
import { message, Modal } from 'antd';
import {
    Button,
    Row,
    Col,
    Typography,
    Form,
    Input
} from 'antd';

const { Title } = Typography;
export default function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [open, setOpen] = useState(false);

    let _fetch = useFetch();

    const verifyMe = async () => {
        try {
            console.log(email);
            setLoading(true);
            let res = await _fetch(
                `${process.env.REACT_APP_API_URL}/user/verifyme?email=${email}`);
            let result = await res.json();
            console.log(result);
            if (res.status === 200) {
                message.success("OTP Sent!")
                setOpen(true);
            } else {
                message.error(result.response);
            }
        } catch (err) {
            console.log(err);
            message.error(
                'You are offline, please check your network connection'
            );
        } finally {
            setLoading(false);
        }
    };

    const handleOk = async (values) => {
        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_URL}/user/forgotpassword`;
            console.log('fetching , url');
            let res = await _fetch(url, {
                method: 'POST',
                body: {
                    new_password: values.new_password,
                    otp: values.otp,
                    email
                },
            });
            res = await res.json();
            if (res.status === 200) {
                message.success("Password Changed Successfully")
                setOpen(false);
            } else {
                message.error(res.response);
            }
        } catch (e) {
            console.error('otp submit post failed', e);
            // message.error('entered OTP is incorrect');
        } finally {
            setLoading(false);
        }
    };
    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Row gutter={[24, 0]} justify="space-around">
            <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 12, offset: 2 }}
                md={{ span: 24 }}
            >
                <Title className="mb-15">Enter Email</Title>
                <div className='d-flex flex-column align-items-start'>
                    <Input placeholder='email' type="email" onChange={(e) => setEmail(e.target.value)} className="mb-3" />
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        onClick={verifyMe}
                    >
                        Send Verification Code
                    </Button>
                </div>
            </Col>
            <Modal
                width={300}
                destroyOnClose
                open={open}
                title='Reset Email'
                onCancel={handleCancel}
                centered
                footer={''}
            >
                <Form
                    onFinish={handleOk}
                    layout="vertical"
                    className="row-col"
                >
                    <Form.Item
                        label="New Password"
                        name="new_password"
                    >
                        <Input label="New Password" type="password"/>
                    </Form.Item>
                    <Form.Item
                        label="Verification Code"
                        name="otp"
                    >
                        <Input id='otpInput' label='otp' />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            key='submit'
                            type='primary'
                            loading={loading}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}
