// import { useState } from "react";
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import ToolBar from './toolBar';
import { AiOutlineUser, AiOutlineOrderedList, AiOutlineDashboard, AiOutlineSetting } from 'react-icons/ai';
import { FiHelpCircle } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedFY } from '../../store/action/fy';
import { user_role } from '../../consts';

function Sidenav({ color = '#1890ff' }) {

	const { pathname } = useLocation();
	const page = pathname.replace('/', '');
	let dispatch = useDispatch();

	let { role } = useSelector(st => st.user);

	return (
		<>
			<div className="brand text-center" >
				<img src={logo} alt="AusLink" />
			</div>
			<hr />
			<Menu theme="light" mode="inline" style={{minWidth:'10vw'}}>
				<Menu.Item key="1">
					<NavLink to="/">
						<span
							className="icon"
							style={{
								background: page === 'dashboard' ? color : '',
		
							}}
						>
							<AiOutlineDashboard />
						</span>
						<span className="label">Dashboard</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key="2">
					<NavLink to="/queries" onClick={() => {
						
					}}>
						<span
							className="icon"
							style={{
								background: page === 'tables' ? color : '',
							}}
						>
							<AiOutlineOrderedList />
						</span>
						<span className="label">All Queries</span>
					</NavLink>
				</Menu.Item>
				
					<Menu.Item key="3">
						<NavLink to="/entities">
							<span
								className="icon"
								style={{
									minWidth: "32px",
									background: page === 'entities' ? color : '',
								}}
							>
								<AiOutlineSetting />
							</span>
							<span className="label"> Entities</span>
						</NavLink>
					</Menu.Item>
				
				{
					role === user_role.ADMIN &&
					<Menu.Item key="4">
						<NavLink to="/users">
							<span
								className="icon"
								style={{
									background: page === 'users' ? color : '',
								}}
							>
								<AiOutlineUser />
							</span>
							<span className="label">Users</span>
						</NavLink>
					</Menu.Item>
				}

				<Menu.Item key="54">
					<NavLink to="/help-center">
						<span
							className="icon"
							style={{
								background: page === 'help-center' ? color : '',
							}}
						>
							<FiHelpCircle style={{ color: '#ced4da' }} />
						</span>
						<span className="label">Help</span>
					</NavLink>
				</Menu.Item>
			</Menu>
			<ToolBar />
		</>
	);
}

export default Sidenav;
