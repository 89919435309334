import { Image, Tag, Typography } from "antd";
import React, { useEffect, useRef } from "react";
import pdflogo from "../assets/images/download.svg"
import text from "../assets/images/text.svg"
import zip from "../assets/images/zip.svg"
import xlsx from "../assets/images/xlsx.svg"
import xls from "../assets/images/xls.svg"
import CSV from "../assets/images/CSV.svg"
import moment from "moment";
import { DeleteOutlined } from '@ant-design/icons';

export default function FileItem({ file, allowDelete = false, deleteClicked }) {

    const handleFileClick = async (file) => {
        window.open(file.path, '_blank', 'noopener,noreferrer');
    };

    let name = file?.name.split('.');
    let ext = name[name.length - 1]

    return (
        <Tag
            className='pointer mx-2 my-1'
            style={{
                padding: '9px',
                borderRadius: '5px',
                maxWidth: '180px',
                marginLeft: '110px',
                position: 'relative',
            }}
        >
            <div className="d-flex flex-column">
                {(file?.type === "image/jpeg" || file?.type === "image/png" || file?.type === "image/jpg") ?
                    <Image src={file?.path} height={50} width={"90%"} style={{ objectFit: "cover" }} /> : null
                }


                <div className="d-flex flex-column" onClick={() => handleFileClick(file)}>
                    {(ext === "txt") ?
                        <Image preview={false} height={50} src={text} /> : null
                    }

                    {(ext === "xlsx") ?
                        <Image preview={false} height={50} src={xlsx} /> : null
                    }

                    {(ext === "xls") ?
                        <Image preview={false} height={50} src={xls} /> : null
                    }

                    {(ext === "csv") ?
                        <Image preview={false} height={50} src={CSV} /> : null
                    }
                    {(ext === "zip") ?
                        <Image preview={false} height={50} src={zip} /> : null
                    }
                    {(ext === "pdf") ?
                        <Image preview={false} height={50} src={pdflogo} /> : null
                    }
                    <div className='d-flex'>
                        <div className='elipText'>{decodeURIComponent(file?.name)}</div>
                        <div className='elipText' style={{ maxWidth: "50px" }}>.{ext}</div>
                    </div>
                    <Typography.Text>{moment.utc(file?.createdAt).format("DD MMM YYYY HH:mm")}</Typography.Text>
                </div>
            </div>
            {
                allowDelete ?
                    <div style={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        transform: 'translate(30%, -30%)',
                        zIndex: 1,
                        backgroundColor: '#1890ff',
                        borderRadius: '50%',
                    }}>

                        <DeleteOutlined
                            style={{
                                color: 'white',
                                fontSize: '18px',
                                padding: '5px',
                            }}
                            onClick={deleteClicked}
                        />
                    </div>
                    : null
            }
        </Tag>
    )
}