import { List, Skeleton, Typography } from 'antd'
import { useEffect, useState } from 'react'
import useFetch from "../../_fetch";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function Activity({ query_id, canNavigate }) {
    const _fetch = useFetch();
    const [activity, setActivity] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const navigate = useNavigate();
    const { selected: selecteCompany } = useSelector(st => st.company);
    const [page, setPage] = useState(1);
    const limit = 10;
    console.log("SDfsj");

    async function init() {
        let activity = await _fetch(
            `${process.env.REACT_APP_API_URL}/activity?company_id=${selecteCompany._id}&page=${page}&limit=${limit}${query_id ? `&query_id=${query_id}` : ""}`
        )
        if (activity.status === 200) {
            activity = await activity.json();
            setHasMore(activity.response.hasMore);
            setActivity(a => [...a, ...activity.response.activities]);
        }
    }

    useEffect(() => {
        console.log("DSvsv")
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])
    return (
        <div>
            <h2>Notifications</h2>
            <InfiniteScroll
                dataLength={activity.length}
                next={() => setPage(page + 1)}
                hasMore={hasMore}
                loader={<Skeleton.Input active size="small" />}
                scrollableTarget="scrollableDiv"
            >
                <List
                    dataSource={activity}
                    renderItem={item => (
                        <List.Item key={item._id}
                            onClick={() => canNavigate && navigate(`/query/${item.query_id}`)}
                            className={canNavigate && "pointer"}
                        >
                            <div>
                                {item.message}
                                <Typography.Text type="secondary"
                                    className='mx-2'
                                    style={{ fontSize: 12 }}
                                >
                                    {moment(item.createdAt).format("DD MMM YYYY HH:mm")}
                                </Typography.Text>
                            </div>
                        </List.Item>
                    )}
                />
            </InfiniteScroll>
        </div>
    )
}