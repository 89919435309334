import { Avatar, Button, Image, Input, List, Typography, message } from "antd";
import moment from "moment";
import { Comment } from '@ant-design/compatible';
import React, { useEffect, useState } from "react";
import useFetch from "../../_fetch";
import FileItem from "../../components/FilesItem";
import { saveAs } from 'file-saver';
import { RollbackOutlined, EditOutlined } from '@ant-design/icons';

export default function FolderContent({ id, name, setShowFolderContent, toggle, setToggle }) {

    const _fetch = useFetch();
    const [docsWithURL, setDocsWithURL] = useState([]);
    const [docs, setDocs] = useState([]);
    const [edit, setEdit] = useState(false);
    const [folderName, setFolderName] = useState(name);

    async function getDocumnetIds() {
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/documents?folder_id=${id}`)
            res = await res.json();
            if (res.status >= 200 && res.status < 300) {
                setDocs(res.response);
                return res.response
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    async function init() {
        let k = await getDocumnetIds();
        // if (k !== 'oK') return;
        let tempDoc = [];

        for (let i = 0; i < k.length; i += 1) {
            console.log("fgkjdfhd");
            tempDoc[i] = k[i];
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/file?document_id=${tempDoc[i]._id}`)
            res = await res.json();
            if (res.status >= 200 && res.status < 300)
                tempDoc[i].files = res.response;

        }
        console.log("Doc", tempDoc);
        setDocsWithURL(tempDoc);
    }

    async function downloadZip() {
        let res = await _fetch(`${process.env.REACT_APP_API_URL}/file/documents/zip?document_ids=${docs.map(d => d._id).join(',')}`)
        res.blob().then(blob => {
            console.log(blob);
            saveAs(blob, `efiles_${name}.zip`)
        });
    }

    const handleEdit = async () => {
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/folder`, {
                method: "PUT",
                body: { id: id, name: folderName }
            })
            res = await res.json();
            if (res.status >= 200 && res.status < 300) {
                setToggle((pre) => !pre)
                setEdit(false)
                message.success("Folder Name edited successfully")
            }

        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        init()
        getDocumnetIds()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggle])

    return (
        <React.Fragment>
            <div class="w-100 d-flex justify-content-between px-3">
                <Button type="link"
                    onClick={() => setShowFolderContent({})}
                >
                    <RollbackOutlined /> Back
                </Button>

                {edit === false ?
                    <Typography.Title level={4}>
                        {folderName}
                        {" "}
                        <span><EditOutlined style={{ color: '#53acff' }}
                            onClick={() => setEdit(true)}
                        /></span> </Typography.Title> :
                    <Typography.Title level={4}>
                        <Input
                            autoFocus
                            bordered={false}
                            value={folderName}
                            style={{ width: '40%' }}
                            onChange={(e) => setFolderName(e.target.value)} />
                        <Button onClick={handleEdit}>Submit</Button>
                    </Typography.Title>}


                <Button onClick={() => downloadZip()} type="primary">Download</Button>
            </div>
            <List
                className="p-4"
                dataSource={docsWithURL}
                renderItem={(doc) => (
                    <Comment
                        author={`${doc.created_by?.first_name} ${doc.created_by?.last_name}`}
                        avatar={
                            <Avatar shape='square'>
                                {doc.created_by?.first_name[0]}
                                {doc.created_by?.last_name[0]}
                            </Avatar>
                        }
                        content={doc.comment}
                        datetime={moment(doc.createdAt).format("DD/MM/YYYY hh:mm A")}
                    >
                        <div>
                            <br />
                            <Image.PreviewGroup>
                                {
                                    doc.files.map(file => (
                                        <FileItem file={file} />
                                    ))
                                }
                            </Image.PreviewGroup>
                        </div>
                    </Comment>
                )}
            />
        </React.Fragment>
    )
}