import React, { useState } from "react";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import useFetch from "../_fetch";
import { message } from "antd";
import { Button, Row, Col, Typography, Form, Input } from "antd";

const { Title } = Typography;
export default function ChangePassword() {
  const [loading, setLoading] = useState(false);

  let _fetch = useFetch();
  let navigate = useNavigate();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const changePassword = async (values) => {
    if (values.new_password !== values.retry_password) {
      message.error("New Password and Re-Enter Password doesn't match!");
      return;
    }
    try {
      setLoading(true);
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/user/password`, {
        method: "POST",
        body: {
          old_password: values.old_password,
          new_password: values.new_password,
        },
      });
      let result = await res.json();
      console.log(result);
      if (res.status === 200) {
        message.success("Password Changed Successfully!");
        navigate("/");
      } else {
        message.error(result.response);
      }
    } catch (err) {
      console.log(err);
      message.error("You are offline, please check your network connection");
    } finally {
      setLoading(false);
    }
  };

  const passwordValidator = (value) => {
    return validator.isStrongPassword(value);
  };

  return (
    <Row gutter={[24, 0]} justify="space-around">
      <Col
        xs={{ span: 24, offset: 0 }}
        lg={{ span: 12, offset: 2 }}
        md={{ span: 24 }}
      >
        <Title className="mb-15">Change Password</Title>
        <Form
          onFinish={changePassword}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          className="row-col"
          name="login"
        >
          <Form.Item
            className="username"
            label="Current Password"
            name="old_password"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <Input.Password placeholder="Current Password" type="password" />
          </Form.Item>

          <Form.Item
            className="username"
            label="New Password"
            name="new_password"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
              {
                validator: (rule, value) => {
                  if (passwordValidator(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Password must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character"
                  );
                },
              },
            ]}
          >
            <Input.Password placeholder="New Password" type="password" />
          </Form.Item>

          <Form.Item
            className="username"
            label="Re-Enter Password"
            name="retry_password"
            rules={[
              {
                required: true,
                message: "Please re-enter your new password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Re-Enter New Password"
              type="password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              SUBMIT
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
