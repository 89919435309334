import React from 'react';
import { Modal, Form, Input, message, Typography, Row, Col, Select, InputNumber } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import useFetch from '../../_fetch';
import { query_type } from '../../consts';

var cc = require('currency-codes');
export default function SubQueryModal({ visible, setVisible, onSuccess, query }) {
    const _fetch = useFetch();
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const currencyCodes = cc.codes();
    const currencyOptions = currencyCodes.map((code) => {
        return {
            label: code,
            value: code
        };
    });

    const layout = {
        labelCol: { span: 16 },
        wrapperCol: { span: 16 },
    };

    async function submit(values) {
        try {
            setLoading(true);
            if (values.cashInValue >= 0 && values.cashInCurrency) {
                const cashIn = {
                    value: values.cashInValue,
                    currency: values.cashInCurrency,
                }
                delete values.cashInValue;
                delete values.cashInCurrency;
                values.cashIn = cashIn;
            }
            if (values.cashOutValue >= 0 && values.cashOutCurrency) {
                const cashOut = {
                    value: values.cashOutValue,
                    currency: values.cashOutCurrency,
                };
                delete values.cashOutValue;
                delete values.cashOutCurrency;
                values.cashOut = cashOut;
            }
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/query`, {
                method: "POST",
                body: {
                    ...values,
                    descriptionV2: values.descriptionV2 ? [{ insert: values.descriptionV2 }] : [],
                    financialYear: query.financialYear,
                    client_id: query.client,
                    type: query_type.TRANSACTIONAL,
                    parent_id: query._id,
                }
            });
            await res.json();
            if (res.status === 200) {
                message.success('Sub Query added successfully');
                setVisible(false);
                onSuccess()
            } else {
                message.error('Some error occurred while opening sub query');
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            title="Add Sub Query"
            centered
            open={visible}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        submit(values);
                        form.resetFields();
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    })
            }}
            onCancel={() => {
                form.resetFields();
                setVisible(false);
            }}
            okText="Open Sub Query"
            destroyOnClose
            okButtonProps={{ loading: loading, htmlType: "submit" }}
            cancelButtonProps={{ loading }}
        >
            <Form
                layout='horizontal' {...layout} onFinish={submit} form={form}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Query title is required' }]}
                >
                    <Input />
                </Form.Item>
                <div className='w-100'>
                    <Typography.Title level={5}>Transaction Details</Typography.Title>
                    <Row
                        justify={'space-evenly'}
                        gutter={[16, 0]}
                    >
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                        >
                            <Form.Item name='cashInValue' label='Cash In' initialValue={0} labelAlign='right' labelCol={{
                                span: 8,
                            }} wrapperCol={{
                                span: 16,
                            }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <InputNumber
                                    style={{ width: '200px' }}
                                    size='medium'
                                    placeholder='Cash In'
                                    className='mr-5'
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                        >
                            <Form.Item name='cashInCurrency' label='Cash In Currency' initialValue={'AUD'} labelAlign='right' labelCol={{
                                span: 24,
                            }} wrapperCol={{
                                span: 24,
                            }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Select
                                    showSearch
                                    placeholder='Select a currency'
                                    optionFilterProp='children'
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    options={currencyOptions}
                                    style={{ width: '200px' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row
                        justify={'space-evenly'}
                        gutter={[16, 0]}
                    >
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                        >
                            <Form.Item name='cashOutValue' label='Cash Out' initialValue={0} labelAlign='right' labelCol={{
                                span: 8,
                            }} wrapperCol={{
                                span: 16,
                            }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <InputNumber
                                    style={{ width: '200px' }}
                                    size='medium'
                                    placeholder='Cash Out'
                                    className='mr-5'
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                        >
                            <Form.Item name='cashOutCurrency' label='Cash Out Currency' initialValue={'AUD'} labelAlign='right' labelCol={{
                                span: 24,
                            }} wrapperCol={{
                                span: 24,
                            }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Select
                                    showSearch
                                    placeholder='Select a currency'
                                    optionFilterProp='children'
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    options={currencyOptions}
                                    style={{ width: '200px' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <Form.Item
                    label="Description"
                    name="descriptionV2"
                >
                    <TextArea rows={5} />
                </Form.Item>

            </Form>
        </Modal>
    );
}