import moment from "moment";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getFinancialYears } from "../store/action/fy";
import { useEffect, useRef, useState } from "react";
import useFetch from "../_fetch";
import { Modal } from "antd";

import { DatePicker } from "antd";

export default function DropDown({ Client_id, setClient_id, client_id, returnSelected = () => { }, canSubmit = true }) {
  const [modal1Open, setModal1Open] = useState(false);
  const _fetch = useFetch();
  const [, setLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [selected, setSelected] = useState("");
  let dispatch = useDispatch();
  let { all } = useSelector((st) => st.client);
  let [toggle, setToggle] = useState(false);
  let dateRef = useRef();

  useEffect(() => {
    if (!client_id) {
      return;
    }

    const init = async () => {
      try {
        let res = await _fetch(
          `${process.env.REACT_APP_API_URL}/fy?client_id=${client_id}`
        );
        let result = await res.json();

        setYears(result.response[0]);
        // }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_id, toggle]);

  function submit(e) {
    let end = `${dayjs(selected).format("YYYY")}`
    setLoading(true);
    let body = {};
    if (Client_id !== undefined) {
      body = {
        client_id: Client_id,
        end: end,
      };
    }
    else {
      body = {
        client_id: client_id,
        end: end,
      };
    }
    _fetch(`${process.env.REACT_APP_API_URL}/fy`, {
      method: "POST",
      body,
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(getFinancialYears(all?.map((e) => e._id)?.join(",")));
        setYears((prevYears) =>{
          return [...prevYears, res.response];
        });
        setClient_id(Client_id); // Trigger re-fetch of financial years
        returnSelected(res.response?._id);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setToggle((pre) => !pre);
        setModal1Open(false);
      });
  }

  const openModel = (e) => {
    setSelected(e);
    console.log(e);
    if (canSubmit)
      setModal1Open(true);
    else returnSelected(dayjs(e).format("YYYY"));
  };

  return (

    <>
    
      <style jsx="true">{`
      .fyyeaR .ant-picker-input > input::placeholder {
        color:#1677ff; 
      }
    `}</style>
      <DatePicker

        className="fyyeaR"
        placeholder="+ Add Fy"
        picker={"year"}
        suffixIcon=""
        bordered={false}
        value={""}
        ref={dateRef}
        type="primary"
        onChange={openModel}
        disabledDate={(c) => {
          if (
            years?.financial_year?.find(
              (f) => { 
                return dayjs(f.end).year() === dayjs(c).year()}
            
            )
          ) {
            return true;
          }
          return false;
        }}
      />

      <Modal
        title="Are you sure ?"
        centered
        open={modal1Open}
        onOk={submit}
        onCancel={() => setModal1Open(false)}
      >
        <p>
          Are You sure you want to add
          <span style={{ fontWeight: "bold", margin: "8px" }}>
            {dayjs(selected).format("YYYY") - 1} - {" "}
            {dayjs(selected).format("YY")}
          </span>
          financial year ?
        </p>
      </Modal>
    </>
  );
}
