import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllUsers, login } from '../store/action/user';
import useFetch from '../_fetch';
import { useCookies } from 'react-cookie';
import { message, Modal } from 'antd';
import { Button, Row, Col, Typography, Form, Input } from 'antd';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ReCAPTCHA from "react-google-recaptcha";
import { grecaptcha } from 'react-google-recaptcha';
import mixpanel from 'mixpanel-browser';


const { Title } = Typography;
export default function SignIn() {
  const [, setCookie] = useCookies(['userInfo']);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptchaRef = React.useRef(null);
  const [showV2, setShowV2] = useState(false);
  const [V2Token, setV2Token] = useState('');
  const [form] = Form.useForm();

  let _fetch = useFetch();
  let navigate = useNavigate();

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    let otp = document.getElementById('otpInput').value;
    console.log(
      'this is otp value:',
      document.getElementById('otpInput').value
    );
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_API_URL}/user/otp`;
      console.log('fetching , url');
      let res = await _fetch(url, {
        method: 'POST',
        body: {
          email: window.email,
          otp,
        },
      });
      let resData;
      if (res.status === 200) {
        resData = await res.json();
        console.log('got response of otp submit', resData);
        dispatch(login({ ...resData.response }));
        setCookie(
          'userInfo',
          { ...resData.response },
          {
            maxAge:
              process.env.REACT_APP_USER_COOKIE_EXPIRY * 3600000,
          }
        );
        mixpanel.track("Login", {
          name: resData.response?.first_name + " " + resData.response?.last_name,
          role: resData.response?.role,
          email: resData.response?.email,
        })
        dispatch(getAllUsers());
        navigate('/')
      } else {
        console.log({ res, resData: await res.json() })
        message.error('entered OTP is incorrect');
      }
    } catch (e) {
      console.error('otp submit post failed', e);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };
  const handleCancel = () => {
    setOpen(false);
    recaptchaRef.current.reset();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = () => {
    console.log()
  }

  const _login = async (values) => {
    try {
      let recaptchaToken;
      if (showV2) {
        recaptchaToken = recaptchaRef.current.getValue();
        console.log(recaptchaToken, 234);
      } else {
        recaptchaToken = await executeRecaptcha();
      }

      setLoading(true);
      let body = {
        email: values.email,
        password: values.password,
        showV2,
        recaptchaToken
      }
      let res = await _fetch(
        `${process.env.REACT_APP_API_URL}/user/login`,
        {
          method: 'POST',
          body
        }
      );
      var result = await res.json();
      console.log(result, 'result');

      if (result.success === true) {
        message.success("OTP Sent to your Email")
        window.email = values.email;

        showModal();

      } else {
        if (showV2 && result?.openV2) {
          console.log('No alert');
        } else {
          recaptchaRef?.current?.reset();
          message.error(result.error || result.response);
        }
      }
    } catch (err) {
      recaptchaRef?.current?.reset();
      console.log(err)
      message.error(
        'You are offline, please check your network connection'
      );
    } finally {
      console.log(result);
      if (result?.openV2) {
        setShowV2(true);
      }
      setLoading(false);
    }
  };

  return (
    <>

      <Modal
        width={200}
        destroyOnClose
        open={open}
        title="Enter OTP"
        onOk={form.submit}
        onCancel={handleCancel}
        centered
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,

        ]}
      >
        <Form form={form} onFinish={handleOk}>
          <Input id="otpInput" label="otp" />
        </Form>
      </Modal>

      <Row gutter={[24, 0]} justify="space-around">
        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 10, offset: 2 }}
          md={{ span: 12 }}
        >
          <Title className="mb-15">Sign In</Title>
          <Title className="font-regular text-muted" level={5}>
            Enter your email and password to sign in
          </Title>
          <Form
            onFinish={_login}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
            name="login"
          >
            <Form.Item
              className="username"
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              className="username"
              style={{ marginBottom: "5px" }}
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>

            <Button
              type="link"
              className="mb-3"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password?
            </Button>
            {showV2 && (
              <Form.Item>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  isolated={true}
                  size="normal"
                  sitekey={process.env.REACT_APP_RECAPTCHAV2_SITEKEY}
                  onChange={onChange}
                />
              </Form.Item>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                SIGN IN
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
