import { useEffect, useState } from "react";
import { Input } from "antd";

export default function SearchBox({ all, setshow, name }) {
	const [text, setText] = useState("");

	useEffect(()=>{
		setText('')
	}, [all])

	useEffect(() => {
		if (all.length) {
			let filtered = all.filter(f => `${f.title}`.toLowerCase().includes(text.toLowerCase()));
			setshow(filtered);

		} else {
			setshow([]);
		}
	}, [all, text])

	const onSearch = (e) => {
		setText(e.target.value)
	};


	return <Input
		value={text}
		placeholder={name}
		onChange={onSearch}
	/>

}

