import React,{useEffect} from 'react';
import { Modal } from 'antd';

export default function PermissionModal({ visible, setVisible, onSuccess = ()=> {}, child = null}){
   useEffect(()=>{
   },[])
    return (
        <Modal
        title="Are you sure?"
        centered
        open={visible}
        onOk={onSuccess}
        onCancel={() => setVisible(null)}
        destroyOnClose
    >
        {child}
    </Modal>
    );
};