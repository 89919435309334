import { Form, Input, Modal, Select, Upload, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import { user_role } from '../../consts';
import DropDown from '../../pages/DropDown';
import CreateClient from "../../pages/createClient";
// import { DatePicker } from "antd";
import { Button, Divider, Space } from "antd";
import RequestEntity from "../../pages/requestEntity";
import RequestFyDoc from "../../pages/RequestFyInDoc";


import useFetch from "../../_fetch";

const { Dragger } = Upload;
const { Option } = Select;



const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

let index = 0;
export default function SendDocumentsModal({ open, setOpen, setToggle }) {


  const { selected: selecteCompany } = useSelector(st => st.company);
  // const [entitydisplay, setEntitydisplay] = useState("none");
  const [oldentity, setOldentity] = useState("block");
  const [addnewentity, setAddnewentity] = useState("block");
  // const [addoldentity, setAddoldentiy] = useState("none");
  const [financialYear, setFinancialYear] = useState(true);
  const [fy_id, setFy_id] = useState('');
  const [folderData, setFolderData] = useState([]);
  const [newFolder, setNewFolder] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [addClientModalVisible, setAddClientModalVisible] = useState(false);
  const [openEntityModal, setOpenEntityModal] = useState(false);
  const [openFyModal, setOpenFyModal] = useState(false);
  const [Client, setClient] = useState('');
  const [abn, setAbn] = useState('');
  const user = useSelector(st => st.user);
  const selectedFinancialYear = useSelector(state => state.selectedFinancialYear);

  const { selected: selectedClient, all: all_clients } = useSelector(
    (st) => st.client
  );

  const { selected, grouped: all_fys } = useSelector((st) => st.fy);
  const filesRef = useRef([]);
  const [loading, setLoading] = useState(false);
  const _fetch = useFetch();
  const [form] = Form.useForm();
  const selectedFY = useSelector(state => state.client.selectedFY);
 
  const Reload = (ev) => {
    ev.preventDefault();
    return (ev.returnValue = "Are you sure you want to close?");
  };

  const upload = async (file, url, document_id, resolve, reject) => {
    try {
      let options;
      let contentType = "";
      contentType = file.type;
      options = {
        method: "PUT",
        headers: {
          "Content-Type": contentType,
        },
        body: file,
      };




      let res = await fetch(url, options);
      if (res.status === 200) {
        try {
          let parsedUrl = new URL(url);
          let pathname = parsedUrl.pathname.slice(1);
          let uploadedRes = await _fetch(
            `${process.env.REACT_APP_API_URL}/file`,
            {
              method: "POST",
              body: {
                document_id,
                path: pathname,
                type: contentType,
              },
            }
          );
          if (uploadedRes.status === 200) {
            uploadedRes = await uploadedRes.json();
            resolve(file.uid);
            setToggle((pre) => !pre)
          }
        } catch (e) {
          console.log(e);
          reject(e);
        }
      }
    } catch (e) {
      console.log(e);
      reject(e);
    }
  };

  async function getUploadUrl(file, id, d_id, status) {
    const fileParts = file.name.split(".");
    fileParts.pop();
    let name = fileParts.join(".");
    let ext = file.name.split(".").pop();
    name = `${name}-${moment().valueOf()}.${ext}`;
    let url;
    url = await _fetch(
      `${process.env.REACT_APP_API_URL}/file/get_upload_url?name=${name}&fy=${id}`
    )
    if (url.status === 200) {
      url = await url.json();
      return url.response;
    } else {
      console.log("url to upload generation failed");
      throw new Error("url to upload generation failed");
    }
  }



  async function getFolder(name) {
    let body = {
      name: name,
      fy_id
    }
    try {
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/folder`,
        {
          method: 'POST',
          body
        }
      );
      let data = await res.json();
      if (data.success === true) {
        return data.response._id
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  async function submit(values) {
    setLoading(true);

    if (!values?.files?.fileList?.length) {
      message.info("Upload A Document");
      setLoading(false);
      return
    }
    if ((!values.financialYear && !values.proposedfy && !values.proposefinancialYear) ||
      (!values.new_folder && !values.folder_id) ||
      (!values.proposedentity && !values.client_id)
    ) {
      message.info("Fill Properly");
      setLoading(false);
      return
    }

    let folder
    if (values.new_folder && values.financialYear) {
      folder = await getFolder(values.new_folder);
    }
    else {
      folder = values.new_folder;
    }
    let k = {
      fy_id: values.financialYear,
      // status: "confirmed",
      company: selecteCompany?.name,
      company_id: selecteCompany?._id,
      folder_id: !values.new_folder ? values.folder_id : folder,
      folderName: values?.new_folder,
      abn: abn.val,
      client_id: Client
    }
    const body = {
      ...k,
      comment: values.comment,
    }
    
    window.addEventListener("beforeunload", Reload);
    try {
      let document_id = "";
      let createdDocument = await _fetch(
        `${process.env.REACT_APP_API_URL}/documents`,
        {
          method: "POST",
          body
        }
      );
      createdDocument = await createdDocument.json();
      if (createdDocument.status >= 200 && createdDocument.status < 300) {
        document_id = createdDocument.response._id;
        let promises = [];
        for (let i = 0; i < filesRef.current.length; i += 1) {
          let file = filesRef.current[i];
          promises[i] = new Promise(async function (resolve, reject) {
            let url = await getUploadUrl(file, values.financialYear, document_id);
  
            if (url) upload(file, url, document_id, resolve, reject);
          });
          Promise.all(promises)
            .then((values) => {
              if (values.length === filesRef.current.length) {
                message.success("Documents Submitted");
                form.resetFields();
                window.removeEventListener("beforeunload", Reload);
                setLoading(false);
                setOpen(false);
                filesRef.current = []
              }
            })
            .catch((e) => {
              message.error(e);
              window.removeEventListener("beforeunload", Reload);
              setLoading(false);
              form.resetFields();
              filesRef.current = []
            });
        }
      } else {
        message.error(createdDocument.response);
        setLoading(false);
        form.resetFields();
        filesRef.current = []
      }

    } catch (e) {
      message.error(e);
      window.removeEventListener("beforeunload", Reload);
      setLoading(false);
      form.resetFields();
      filesRef.current = []
    }
  }

  const props = {
    multiple: true,
    showUploadList: {
      showPreviewIcon: false,
    },
    listType: "picture-card",
    beforeUpload: async (file) => {
      filesRef.current.push(file);
      return false;
    },
    onRemove: (file) => {
      filesRef.current = filesRef.current.filter((e) => e.uid !== file.uid);

    },
  };

  const folderDataFetch = async () => {
    if (!fy_id || !open || newFolder) return
    try {
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/folder?fy_id=${fy_id}`)
      res = await res.json();

      if (res.status >= 200 && res.status < 300) {
        setFolderData(res.response)
      }
       else {
        message.error(res.response);
      }
    } catch (e) {
      message.error("Something went wrong")
      console.log(e);
    }
  }


  
  useEffect(() => {
    folderDataFetch()
  }, [fy_id])

  useEffect(() => {
    let clientData = all_clients?.filter((el) => el.title);
    setClientData(clientData)
  }, [all_clients])

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      okText="Submit"
      onOk={form.submit}
      confirmLoading={loading}
      afterClose={() => form.resetFields()}
      destroyOnClose={true}
      title="Submit New Documents">
      <Form

        preserve={false}
        layout="vertical"
        {...layout}
        form={form}
        onFinish={submit}>

        <Form.Item name="files">

          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload documents
            </p>
          </Dragger>
        </Form.Item>

        <div style={{ display: `${oldentity}` }}>
          <Form.Item
            key={selectedClient._id}
            label={<div className="finanicalYears" ><p
              style={{ width: "200px", display: `${addnewentity}`, alignItems: "center", justifyContent: "center", marginBottom: "0px" }}>
              Entity</p>
            </div>
            }
            name="client_id"
            initialValue={selectedClient?._id}
          >
            <Select
              value={selectedClient?._id}
              showSearch
              onChange={(value, val) => {
                setClient(value);
                setAbn(val);
              }}


              optionFilterProp="label"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  {
                    user.role === user_role.ADMIN && user_role.ACCOUNTANT && (
                      <button className="m-2" style={{ color: '#1677ff', border: 'none', background: 'transparent' }}
                        onClick={() => setAddClientModalVisible(true)}
                      >
                        Add Entity
                      </button>

                    )
                  }
                  {
                    user.role !== user_role.ADMIN && user_role.ACCOUNTANT && (
                      <button className="m-2" style={{ color: '#1677ff', border: 'none', background: 'transparent' }}
                        onClick={() => setOpenEntityModal(true)}
                      >
                        Request Entity
                      </button>
                    )}
                </>
              )}
            >
              {clientData.map((e) => (
                <Option
                  value={e._id}
                  val={e.abn}
                  key={e._id}
                  label={e.title}>
                  {e.title}

                </Option>
              ))}
            </Select>
          </Form.Item>


          <Form.Item
            key={selected._id}
            style={{ width: "150px" }}
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.client_id !== currentValues.client_id
            }>

            {({ getFieldValue, setFieldsValue }) => {
              let value = all_fys.find((e) => e?._id === getFieldValue("client_id"));
              return (


                <Form.Item
                  label={<div className="finanicalYears" ><p
                    style={{ width: "200px", display: `${addnewentity}`, alignItems: "center", justifyContent: "center", marginBottom: "0px" }}>
                    Financial Year</p>

                  </div>}
                  name="financialYear"
                  initialValue={selected?._id}>

                  <Select value={ selected?._id}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: '8px 0',
                          }}
                        />
                        {
                          user.role === user_role.ADMIN && user_role.ACCOUNTANT && (
                            <DropDown Client_id={Client}
                              setClient_id={setClient}
                              returnSelected={(end) => setFieldsValue({ financialYear: end })} />

                          )
                        }
                        {
                          user.role !== user_role.ADMIN && user.role !== user_role.ACCOUNTANT && (
                            <RequestFyDoc
                              abn={abn}
                              setAbn={setAbn}
                              Client_id={Client}
                              setClient_id={setClient}

                            />

                          )
                        }
                      </>
                    )}
                  >
                    {
                      all_fys
                        .find((e) => e._id === getFieldValue("client_id"))
                        ?.financial_year.map((e) => (
                          <Option
                            value={e._id}
                            key={e._id}
                            label={e.financial_year}
                          >

                            {moment(e.start).format("YYYY")} -{" "}
                            {moment(e.end).format("YY")}
                          </Option>

                        ))}
                  </Select>
                </Form.Item>
              )
            }}
          </Form.Item>
        </div>

        {
          !newFolder && financialYear ?
            <Form.Item
              style={{ width: "150px" }}
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues.financialYear !== currentValues.financialYear
              }}
            >
              {({ getFieldValue }) => {
                setFy_id(getFieldValue('financialYear'));
                return (
                  <Form.Item
                    label={<div className="finanicalYears" ><p
                      style={{ width: "200px", display: `${addnewentity}`, alignItems: "center", justifyContent: "center", marginBottom: "0px" }}>
                      Folder</p>

                    </div>}
                    name="folder_id"
                    initialValue={folderData[0] !== undefined ? folderData[0]._id : null}
                  >
                    <Select
                      value={folderData[0] !== undefined ? folderData[0]._id : null}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: '8px 0',
                            }}
                          />
                          <button className="m-2" style={{ color: '#1677ff', border: 'none', background: 'transparent' }}
                            onClick={() => setNewFolder(true)}>Create folder
                          </button>
                        </>
                      )}
                    >
                      {folderData && folderData.map((e) => (
                        <Option
                          value={e._id}
                          key={e._id}
                          label={e.name}>
                          {e.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )
              }}
            </Form.Item>
            :

            <Form.Item
              label={
                <div className="finanicalYears" ><p
                  style={{ width: "200px", display: 'block', alignItems: "center", justifyContent: "center", marginBottom: "0px" }}>
                  Create Folder</p>

                </div>
              }
              name="new_folder"
            >
              <Input />
            </Form.Item>

        }
        <Form.Item
          label="Comment"
          name="comment">
          <Input.TextArea
            placeholder="type your comment..."
            autoSize={{
              minRows: 5,
              maxRows: 10,
            }}
          />
        </Form.Item>
      </Form>

      <RequestEntity
        visible={openEntityModal}
        setVisible={setOpenEntityModal}
      />

      <CreateClient
        visible={addClientModalVisible}
        setVisible={setAddClientModalVisible}
      />

    </Modal >


  );
}
