import {
    Button,
    Dropdown,
    Menu,
} from "antd";
import { BsChevronDown } from "react-icons/bs";
import { fy_status } from "../../consts";

export default function FYStatusSelector({
    item, handleStatusUpdate, isDisabled = true
}) {
    const statusMenu = (
        <Menu defaultValue={[item.status]}>
            <Menu.Item
                value={fy_status.OPEN}
                onClick={() => {
                    handleStatusUpdate(item, fy_status.OPEN)
                }}
            >{
                    fy_status.OPEN
                }</Menu.Item>
            <Menu.Item
                value={fy_status.QUERIES_PENDING}
                onClick={() => {
                    handleStatusUpdate(item, fy_status.QUERIES_PENDING)
                }
                }>{fy_status.QUERIES_PENDING}</Menu.Item>
            <Menu.Item
                value={fy_status.QUERIES_RESOLVED}
                onClick={() => {
                    handleStatusUpdate(item, fy_status.QUERIES_RESOLVED)
                }
                }>{fy_status.QUERIES_RESOLVED}</Menu.Item>
            <Menu.Item
                value={fy_status.CLOSED}
                onClick={() => {
                    handleStatusUpdate(item, fy_status.CLOSED)
                }
                }>{fy_status.CLOSED}</Menu.Item>
        </Menu>
    );
    return (
        <Dropdown
            trigger={["click"]}
            overlay={statusMenu}
            arrow
            disabled={isDisabled}
        >
            <Button
                type="text"
                style={{
                    padding: "0px 10px",
                    width: "100%",
                    color: item.status === fy_status.OPEN ? "#1890ff" : item.status === fy_status.QUERIES_PENDING ? "orange" : item.status === fy_status.QUERIES_RESOLVED ? "green" : item.status === fy_status.CLOSED ? "red" : "grey",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "10px",

                    }}
                >
                    {
                        item.status ? item.status : "Set Status"
                    }
                    {isDisabled ? null : <BsChevronDown />}
                </div>
            </Button>
        </Dropdown>
    );
}