import { _fetch } from "../../_fetch";

export const updateCompany = (companyId) => ({
    type: 'UPDATE',
    payload: companyId
});
export const changeSelectedCompany = (selected) => ({
    type: 'CHANGE_SELECTED_COMPANY',
    payload: selected
});
export const getAllCompanies = () => {
    return async dispatch => {
        let res = await _fetch(`${process.env.REACT_APP_API_URL}/company`)
        let result = await res.json();
        if (res.status === 200 && Array.isArray(result.response) && result.response.length) {
            dispatch({ type: 'SET_ALL_COMPANIES', payload: result.response });
            return result.response;
        }
    }
}
