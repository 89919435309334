import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { Loader } from './components/loader';

if (process.env.NODE_ENV === 'production') {
	console.log = () => { }
	console.error = () => { }
	console.debug = () => { }
}

ReactDOM.render(
	<Suspense
		fallback={
			<div className="h-100 w-100 d-flex">
				<Loader />
			</div>
		}
	>
		<React.StrictMode>
			<Provider store={store}>
				<Router>
					<App />
				</Router>
			</Provider>
		</React.StrictMode>
	</Suspense>,
	document.getElementById('root')
);
