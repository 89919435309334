import { useState, React, useEffect } from 'react';
import { Button, Card, Input, message, Modal, Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import validator from 'validator';
import SearchBox from '../components/SearchBox';
import { getAllUsers } from '../store/action/user';
import { Loader } from '../components/loader';
import CreateUsers from './createUser';
import UserInfo from '../components/userInfo';
import useFetch from '../_fetch';

const user_roles = {
    'COMPANY': 'Australian staff',
    'ACCOUNTANT': 'Accountant',
    'ADMIN': 'Admin'
};
function Users() {
    let dispatch = useDispatch();
    const { all } = useSelector(st => st.user);

    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [showResetPassword, setShowResetPassword] = useState('');
    const [show, setShow] = useState([]);
    const [text, setText] = useState("");
    const placeholder = "Search Users..."
    const [resetPassword, setResetPssword] = useState('');

    const _fetch = useFetch();
    useEffect(() => {
        for (var i = 0; i < all.length; i++) {
            all[i].title = `${all[i].first_name} ${all[i].last_name}`
        }
    }, [all])

    function selectUser(id) {
        setSelectedUser(id);
        setShowModal(true);
    }

    function CloseModal() {
        setShowModal(false);
        setSelectedUser(null);
    }

    // useEffect(() => {
    //     if (!text) setFirmsToShow(all);
    //     let filtered = all.filter(f => `${f.first_name} ${f.last_name}`.toLowerCase().includes(text.toLowerCase()));
    //     setUsersdata(filtered);
    //     console.log(filtered);

    // },[all,text])

    const onSearch = (e) => {
        setText(e.target.value)
        // if (!text) setFirmsToShow(all);
        // let filtered = all.filter(f => f.first_name.toLowerCase().includes(e.target.value))
        // setUsersdata(filtered);
        // console.log(filtered)
    };

    const passwordValidator = () => {
        return validator.isStrongPassword(resetPassword);
    }

    const getUsers = async () => {
        try {
            setLoading(true);
            await dispatch(getAllUsers());
        } catch {
            message.error("Something went wrong");
        } finally {
            setLoading(false);
        }
    }

    async function handleResetPassword() {

        if (!passwordValidator()) {
            message.error("Password must be 8 characters long, with at least one uppercase, one lowercase, one number and one symbol");
            return;
        }

        let res = await _fetch(`${process.env.REACT_APP_API_URL}/user/password`, {
            method: "PATCH",
            body: {
                new_password: resetPassword,
                user_id: showResetPassword
            }
        })
        res = await res.json();
        if (res.status === 200) {
            setShowResetPassword('');
            setResetPssword('');
            message.success("Password reset successfully");
        } else {
            setResetPssword('');
            message.error(res.response);
        }
    }

    const columns = [
        {
            title: "USER",
            key: "name",
            width: "25%",
            render: (record) => (
                <UserInfo firstName={record.first_name}
                    lastName={record.last_name} email={record.email}
                    avatraProps={{ size: 40, style: { backgroundColor: '#1890ff' } }}
                />
            )
        },
        {
            title: "FUNCTION",
            key: "function",
            width: "15%",
            render: (record) => (
                <div className="author-info">
                    <h5>{user_roles[record.role]}</h5>
                    <p>{record.company_ids.map(e => e?.name)?.join(', ')}</p>
                </div>
            )
        },
        {
            title: "STATUS",
            key: "status",
            width: "15%",
            dataIndex: "status",
        },
        {
            title: "Created On",
            key: "employed",
            width: "15%",
            render: (record) => moment(record.createdAt).format('DD MMM YYYY')
        },
        {
            title: "",
            key: "action",
            width: "15%",
            render: (record) => (
                <div>
                    <Button type='text' onClick={() => setShowResetPassword(record._id)}>Reset Password</Button>
                    <Button type='text' onClick={() => selectUser(record._id)}>Edit</Button>
                </div>
            )
        }
    ];

    useEffect(() => {
        getUsers();
    }, [])

    return (
        <div>


            <Card

                bordered={false}
                className="circle_box table_space"

                title={
                    <div className="d-flex justify-content-start">


                        <div className="flex-fill search d-flex">
                            <span className="me-3">All Users</span>
                            <div className="d-flex flex-fill justify-content-center">
                                <SearchBox all={all} setshow={setShow} name={placeholder} />
                            </div>
                        </div>
                    </div>
                }
                extra={<Button type='primary' onClick={() => setShowModal(true)}>Add User</Button>}
            >
                {
                    loading ?
                        <Loader />
                        :
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                dataSource={show?.map(e => ({ ...e, key: e._id }))}
                                pagination={false}
                                className="ant-border-space"
                            />
                        </div>
                }
            </Card>
            <CreateUsers show={showModal} setShow={CloseModal} selectedUser={selectedUser} />
            <Modal
                title="Reset Password"
                open={showResetPassword}
                onCancel={() => setShowResetPassword(false)}
                okText="Reset"
                onOk={() => handleResetPassword()}
                destroyOnClose={true}
            >
                <div>
                    <Typography.Text
                    >
                        New Password should be at least 8 character long and must contain at least 1 lowercase character, 1 upper case character, 1 numeric and 1 special symbol.
                    </Typography.Text>
                    <Input placeholder="*******" type='password'
                        className='my-2'
                        value={resetPassword}
                        onChange={(e) => setResetPssword(e.target.value)}
                    />
                </div>
            </Modal>
        </div>
    );
}

export default Users;
