import { useEffect, useRef, useState } from "react";
import Subquery from "../components/subqueryadd";
import RQuill from "../components/quill_editor";
// import { uploadFile } from 'react-s3';
// import ReactQuill from 'react-quill';
import { Buffer } from "buffer";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Tabs,
  Form,
  Dropdown,
  message,
  Menu,
  Avatar,
  List,
  Image,
  Tag,
  Table,
  InputNumber,
  Select,
  DatePicker
} from "antd";
import { Comment } from '@ant-design/compatible';
import {
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
  DownOutlined,
  RightOutlined,
  LeftCircleOutlined,
} from "@ant-design/icons";
import { BsChevronDown } from "react-icons/bs";
import useFetch from "../_fetch";
import { Loader } from "../components/loader";
import CommentView from "../components/commentView";
import Files from "../components/files";
import { ChangeQuery } from "../store/action/queries";
import { useDispatch, useSelector } from "react-redux";
import Activity from "../components/activity";
import { query_type, user_role } from "../consts";
import PermissionModal from "../components/PermissionModal";
import SubQueryModal from "../components/SubQueryModal";
import moment from "moment";
import { changeSelectedClient } from "../store/action/client";
import { changeSelectedFY } from "../store/action/fy";

var cc = require("currency-codes");
var QuillDeltaToHtmlConverter =
  require("quill-delta-to-html").QuillDeltaToHtmlConverter;

export default function Query(props) {
  const quillContent = useRef({ content: {}, html: "" });
  const myRef = useRef(null);
  const [showquery, setShowquery] = useState(false);
  const [shownewquery, setShownewquery] = useState(false);

  const [query, setQuery] = useState({});
  const displayDesc = useRef();
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [subQueries, setSubQueries] = useState([]);
  const [addQuery, setAddQuery] = useState(false);
  // const [subQueryComments, setSubQueryComments] = useState([]);
  const [editQuery, setEditQuery] = useState(false);
  const [permissionModalOpen, setPermissionModalOpen] = useState(null);
  const [newSubQueryComment, setNewSubQueryComment] = useState("");
  const [subQueryFilesWaiting, setSubQueryFilesWaiting] = useState(false);
  const [parentModalQueryId, setParentModalQueryId] = useState(null);
  const [activeKey, setActiveKey] = useState([]);
  const { _id, role } = useSelector((st) => st.user);
  const files = useSelector((st) => st.file);
  const { all: client } = useSelector((st) => st.client);
  const { all: fy } = useSelector((st) => st.fy);

  const currencyCodes = cc.codes();
  const currencyOptions = currencyCodes.map((code) => {
    return {
      label: code,
      value: code,
    };
  });

  const displayQueries = () => {
    setShowquery(true);
  };
  const displaynewqueries = () => {
    setShownewquery(true);
  };
  const navigate = useNavigate();
  // const currencyCodes = cc.codes();
  // const currencyOptions = currencyCodes.map((code) => {
  //     return {
  //         label: code,
  //         value: code
  //     };
  // });
  const selectAfter = (
    <Form.Item
      name="cashInCurrency"
      initialValue={"AUD"}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      style={{
        marginBottom: -2,
      }}
    >
      <Select
        showSearch
        placeholder="Select a currency"
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        options={currencyOptions}
        style={{ width: "80px" }}
      />
    </Form.Item>
  );
  const cashout = (
    <Form.Item
      name="cashOutCurrency"
      initialValue={"AUD"}
      labelAlign="right"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      style={{
        marginBottom: -2,
      }}
    >
      <Select
        showSearch
        placeholder="Select a currency"
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        options={currencyOptions}
        style={{ width: "80px" }}
      />
    </Form.Item>
  );
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  async function submit(values) {
    try {
      setLoading(true);
      if (values.cashInValue >= 0 && values.cashInCurrency) {
        const cashIn = {
          value: values.cashInValue,
          currency: values.cashInCurrency,
        };
        delete values.cashInValue;
        delete values.cashInCurrency;
        values.cashIn = cashIn;
      }
      if (values.cashOutValue >= 0 && values.cashOutCurrency) {
        const cashOut = {
          value: values.cashOutValue,
          currency: values.cashOutCurrency,
        };
        delete values.cashOutValue;
        delete values.cashOutCurrency;
        values.cashOut = cashOut;
      }
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/query`, {
        method: "POST",
        body: {
          ...values,
          descriptionV2: values.descriptionV2
            ? [{ insert: values.descriptionV2 }]
            : [],
          financialYear: query.financialYear,
          client_id: query.client,
          type: query_type.TRANSACTIONAL,
          parent_id: query._id,
        },
      });
      await res.json();
      if (res.status === 200) {
        console.log(res);
        message.success("Sub Query added successfully");

        onSuccess();
      } else {
        message.error("Some error occurred while opening sub query");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const _fetch = useFetch();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  let { query_id } = useParams();
  if (props.query) {
    query_id = props.query;
  }
  let { setModalQueryId, onSuccess } = props;
  const dispatch = useDispatch();
  const [, setSeed] = useState(1);
  const reset = () => {
    setSeed(Math.random());
  };

  const init = async () => {
    try {
      let res = await _fetch(
        `${process.env.REACT_APP_API_URL}/query/${query_id}`
      );
      let result = await res.json();
      if (res.status === 200) {
        setQuery(result.response);
        if (result.response.descriptionV2.length) {
          var converter = new QuillDeltaToHtmlConverter(
            result.response.descriptionV2,
            []
          );
          displayDesc.current = converter.convert();
        } else displayDesc.current = result.response.description;

        let record = (Array.isArray(fy) ? fy : []).find(
          (e) => e._id === result.response.financialYear
        );
        let changedClient = client.find(
          (e) => e._id === result.response.client
        );
        if (record && changedClient) {
          let changedFY = {
            _id: record._id,
            start: record.start,
            end: record.end,
            priority: record.priority,
            assigned_to: {
              first_name: record.first_name,
              last_name: record.last_name,
            },
          };
          dispatch(changeSelectedClient(changedClient));
          dispatch(changeSelectedFY(changedFY));
        }

        let commRes = await _fetch(
          `${process.env.REACT_APP_API_URL}/comment?query_id=${result.response._id}`
        );
        if (commRes.status === 200) {
          commRes = await commRes.json();
          setComments(commRes.response);
        }
        await loadSubQueries();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const loadSubQueries = async () => {
    try {
      let res = await _fetch(
        `${process.env.REACT_APP_API_URL}/query/${query_id}?sub=true`
      );
      let result = await res.json();

      for (let i = 0; i < result.response.length; i++) {
        let commRes = await _fetch(
          `${process.env.REACT_APP_API_URL}/comment?query_id=${result.response[i]._id}`
        );
        if (commRes.status === 200) {
          commRes = await commRes.json();
          result.response[i].comments = commRes.response.slice(0, 2);
        }
        result.response[i].key = i;
      }
      setSubQueries(result.response);
      console.log(subQueries);
    } catch (e) {
    }
  };

  useEffect(() => {
    init();
  }, [query_id]);

  const columns = [
    {
      title: "Narration",
      key: "narration",
      dataIndex: "title",
      render: (text) => (
        <Typography.Title
          level={5}
          style={{
            userSelect: "none",
          }}
        >
          {text}
        </Typography.Title>
      ),
    },
    {
      title: "Cash In",
      key: "cashIn",
      render: (record) => {
        return `${record.cashIn?.value || 0} ${record.cashIn?.currency || ""}`;
      },
    },
    {
      title: "Cash Out",
      key: "cashOut",
      render: (record) => {
        return `${record.cashOut?.value || 0} ${record.cashOut?.currency || ""
          }`;
      },
    },
    {
      title: "Date",
      key: "date",
      render: (record) => {
        return `${moment(record.Date).format('YYYY-MMM-DD') || ""}`;
      },
    },
    {
      title: "Status",
      key: "action",
      render: (record) => <QueryStatus queryObj={record} />,
    },
  ];

  async function uploadPhotos(op) {
    let url = await _fetch(
      `${process.env.REACT_APP_API_URL}/file/get_upload_url?name=${query_id}_${moment().format("MMDDYYYYhhmmss")}.jpeg&query=${query._id}`
    );
    if (url.status === 200) {
      url = await url.json();
      url = url.response;
      console.log("url to upload generation Success with url ", url);
    } else {
      console.log("url to upload generation failed");
      throw new Error("url to upload generation failed");
    }

    var buf = new Buffer.from(
      op.insert.image.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "image/jpeg",
      },
      body: buf,
    };
    let res = await fetch(url, options);
    console.log("url is ", url);
    if (res.status === 200) {
      try {
        let parsedUrl = new URL(url);
        console.log(parsedUrl);
        let pathname = parsedUrl.pathname.slice(1);
        console.log(pathname);
        let fileInDB = await _fetch(`${process.env.REACT_APP_API_URL}/file`, {
          method: "POST",
          body: {
            query: query._id,
            path: pathname,
            type: "image/jpeg",
          },
        });
        if (fileInDB.status >= 200 && fileInDB.status < 300) {
          fileInDB = await fileInDB.json();
          op.insert.image = `${process.env.REACT_APP_API_URL}/file/get_object?id=${fileInDB.response._id}`;
          return op;
        }
      } catch (e) {
        console.log(e);
        return {};
      }
    }
    return {};
  }

  const handleQueryUpdate = async (update) => {
    if (update.cashInValue >= 0 && update.cashInCurrency) {
      const cashIn = {
        value: update.cashInValue,
        currency: update.cashInCurrency,
      };
      delete update.cashInValue;
      delete update.cashInCurrency;
      update.cashIn = cashIn;
    }
    if (update.cashOutValue >= 0 && update.cashOutCurrency) {
      const cashOut = {
        value: update.cashOutValue,
        currency: update.cashOutCurrency,
      };
      delete update.cashOutValue;
      delete update.cashOutCurrency;
      update.cashOut = cashOut;
    }
    let body = update;

    let { ops } = quillContent.current.content;
    if (ops) {
      let descriptionV2 = [];
      for (let i = 0; i < ops.length; i++) {
        console.log(ops[i].insert.image);
        if (ops[i].insert.image && !ops[i].insert.image.includes("http")) {
          let j = await uploadPhotos(ops[i]);
          descriptionV2.push(j);
        } else descriptionV2.push(ops[i]);
      }
      body = { ...body, descriptionV2, description: "" };
    }
    console.log("BODY", body)
    let updatedQuery = await _fetch(
      `${process.env.REACT_APP_API_URL}/query/${query_id}`,
      {
        method: "PATCH",
        body,
      }
    );
    let resJson = await updatedQuery.json();
    if (resJson.status === 200) {
      console.log(resJson);
      message.success("Query Updated");
      setQuery(resJson.response);
      var converter = new QuillDeltaToHtmlConverter(
        resJson.response.descriptionV2,
        []
      );
      displayDesc.current = converter.convert();
      dispatch(ChangeQuery(resJson.response));
      setEditQuery(false);
    }
  };

  const handleQueryStatusUpdate = async (update, queryObj) => {
    if (update?.status === queryObj?.status) return;
    try {
      setLoading(true);
      let body = update;
      let updatedQuery = await _fetch(
        `${process.env.REACT_APP_API_URL}/query/${queryObj.query_id}`,
        {
          method: "PATCH",
          body,
        }
      );
      let resJson = await updatedQuery.json();
      if (resJson.status === 200) {
        console.log(resJson);
        message.success("Status Updated");
        await dispatch(ChangeQuery(resJson.response));
        await init();
        await loadSubQueries();
      } else {
        message.error("Status Update Failed");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleQuill = (content, delta, source, editor) => {

    quillContent.current.content = editor.getContents();
    quillContent.current.html = editor.getHTML();
  };

  function QueryForm() {
    console.log(query, moment(query?.Date));
    return (
      <Form className="w-100" onFinish={handleQueryUpdate} form={form}>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <Form.Item className="w-75" name="title" initialValue={query.title}>
            <Input
              value={query.title}
              size="large"
              autoFocus
              bordered={false}
              style={{
                fontSize: "30px",
                border: 0,
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => setEditQuery(false)}
              size="large"
              style={{ fontSize: "12px", marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ fontSize: "12px" }}
            >
              Update
            </Button>
          </Form.Item>
        </div>
        <div className="d-flex flex-column align-items-start my-4">
          <Typography.Title level={5}>Description</Typography.Title>

          <RQuill
            onChange={handleQuill}
            queryID={query._id}
            initValue={
              query.descriptionV2?.length
                ? query.descriptionV2
                : query.description
            }
          />
        </div>
        {query.type === query_type.TRANSACTIONAL && (
          <div className="d-flex w-100 flex-column align-items-start my-4">
            <div className="w-100">
              <Typography.Title level={5}>Transaction Details</Typography.Title>
              <Row justify={"space-evenly"} gutter={[16, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="cashInValue"
                    label="Cash In"
                    initialValue={query.cashIn?.value}
                    labelAlign="right"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                  >
                    <InputNumber
                      style={{ width: "200px" }}
                      size="medium"
                      placeholder="Cash In"
                      className="mr-5"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="cashInCurrency"
                    label="Cash In Currency"
                    initialValue={query.cashIn?.currency}
                    labelAlign="right"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                  >
                    <Select
                      showSearch
                      placeholder="Select a currency"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      options={currencyOptions}
                      style={{ width: "200px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={"space-evenly"} gutter={[16, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="cashOutValue"
                    label="Cash Out"
                    initialValue={query.cashOut?.value}
                    labelAlign="right"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                  >
                    <InputNumber
                      style={{ width: "200px" }}
                      size="medium"
                      placeholder="Cash Out"
                      className="mr-5"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="cashOutCurrency"
                    label="Cash Out Currency"
                    initialValue={query.cashOut?.currency}
                    labelAlign="right"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                  >
                    <Select
                      showSearch
                      placeholder="Select a currency"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      options={currencyOptions}
                      style={{ width: "200px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{ marginLeft: "-42px" }}
                >
                  <Form.Item name='Date' label='Date' initialValue={query?.Date ? moment(query?.Date) : '0'} labelAlign='right' labelCol={{
                    span: 8,
                  }} wrapperCol={{
                    span: 16,
                  }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <DatePicker format="YYYY-MM-DD" style={{ width: '180px' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} ></Col>
              </Row>
            </div>
          </div>
        )}
      </Form>
    );
  }

  async function deleteQuery() {
    try {
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/query`, {
        method: "DELETE",
        body: {
          id: permissionModalOpen._id,
          user_id: _id,
        },
      });
      let resJson = await res.json();
      if (resJson.status === 200) {
        if (setModalQueryId) {
          setModalQueryId(null);
          onSuccess();
        } else {
          navigate("/queries", { replace: true });
        }
        message.success("Query Deleted Successfully");
      } else {
        message.error(resJson.response);
      }
    } catch (err) {
      console.log(err);
      message.error("Some error occurred while deleting query");
    } finally {
      setPermissionModalOpen(null);
    }
  }

  async function handleNewSubQueryComment(subQuery) {
    var payload = {
      query_id: subQuery._id,
      text: newSubQueryComment,
    };
    try {
      let res = await _fetch(`${process.env.REACT_APP_API_URL}/comment`, {
        method: "POST",
        body: {
          ...payload,
        },
      });
      res = await res.json();
      if (res.status === 200) {
        message.success("Comment Added Successfully");
        setNewSubQueryComment("");
        setSubQueryFilesWaiting(false);
        if (subQuery.status === "CLOSED") {
          await handleQueryStatusUpdate({ status: "OPEN" }, subQuery);
        } else {
          await loadSubQueries();
        }
        return res.response._id;
      }
    } catch (e) {
      console.log(e);
      message.error("Some error occurred while adding comment");
    }
  }

  function QueryStatus({ queryObj }) {
    const statusMenu = (
      <Menu>
        <Menu.Item
          value="OPEN"
          onClick={(e) => {
            handleQueryStatusUpdate({ status: "OPEN" }, queryObj);
          }}
        >
          Open
        </Menu.Item>
        <Menu.Item
          value="CLOSED"
          onClick={(e) => {
            handleQueryStatusUpdate({ status: "CLOSED" }, queryObj);
          }}
        >
          Closed
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        {role === user_role.COMPANY ? (
          <Button
            type="primary"
            danger={queryObj.status === "CLOSED"}
            className="mx-auto"
          >
            {queryObj.status}
          </Button>
        ) : (
          <Dropdown
            trigger={["click"]}
            overlay={statusMenu}
            arrow
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              style={{
                background: queryObj.status === "CLOSED" ? "green" : "#1890ff",
                borderColor: queryObj.status === "CLOSED" ? "green" : null,
              }}
              type="primary"
              className="d-flex justify-content-between"
            >
              {queryObj.status}
              <BsChevronDown size={20} />
            </Button>
          </Dropdown>
        )}
      </div>
    );
  }


  return (
    <>
      <Card
        className="header-solid h-full mb-24"
        bordered={false}
        bodyStyle={{ height: "100%", marginBottom: "24px" }}
      >
        {loading ? (
          <Loader />
        ) : query.query_id ? (
          <Row gutter={[10, 10]} className="h-100">
            <Col span={24}>
              <div className="p-2 h-auto">
                {editQuery ? (
                  <QueryForm />
                ) : (
                  <div>
                    {
                      setModalQueryId && (
                        <div
                          className="d-flex flex-row align-items-center justify-content-start"
                        >
                          <LeftCircleOutlined
                            onClick={() => {
                              if (parentModalQueryId) {
                                setModalQueryId(parentModalQueryId)
                                setParentModalQueryId(null)
                              } else {
                                setModalQueryId(null)
                              }
                            }}
                            style={{
                              fontSize: '25px',
                              cursor: 'pointer',
                              color: '#1890ff'
                            }}
                          />
                          <Typography.Text
                            style={{
                              marginLeft: '10px'
                            }}
                          >
                            <b>Query ID:</b> {query.query_id}
                          </Typography.Text>
                        </div>
                      )
                    }
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      <div
                        className="d-flex flex-row align-items-center justify-content-start"
                      >
                        {
                          !setModalQueryId && (
                            <LeftCircleOutlined
                              onClick={() => {
                                navigate(-1);
                              }}
                              style={{
                                fontSize: '25px',
                                cursor: 'pointer',
                                color: '#1890ff',
                                marginRight: '10px'
                              }}
                            />
                          )
                        }
                        <Typography.Title level={2} className="my-auto">
                          {query.title}
                        </Typography.Title>
                      </div>
                      {subQueries.length === 0 && query.type === query_type.NORMAL && (
                        <Button
                          style={{
                            position: "absolute",
                            top: "5%",
                            right: "15%",
                          }}
                          onClick={() => displayQueries()}
                          type="link"
                        >
                          + Add Transactional Query
                        </Button>
                      )}

                      <Row>
                        <QueryStatus queryObj={query} />
                        {role !== user_role.COMPANY && (
                          <Dropdown
                            trigger={["click"]}
                            overlay={
                              <Menu>
                                {role !== user_role.COMPANY && (
                                  <Menu.Item
                                    onClick={() => {
                                      setEditQuery(true);
                                    }}
                                    disabled={query.status === "CLOSED"}
                                  >
                                    <Row style={{ alignItems: "center" }}>
                                      <EditOutlined
                                        style={{
                                          color: "#1890ff",
                                          fontSize: "20px",
                                          marginRight: "5px",
                                        }}
                                      />
                                      Edit
                                    </Row>
                                  </Menu.Item>
                                )}
                                {role === user_role.ADMIN && (
                                  <Menu.Item
                                    onClick={() => {
                                      setPermissionModalOpen(query);
                                    }}
                                    disabled={query.status === "CLOSED"}
                                  >
                                    <Row style={{ alignItems: "center" }}>
                                      <DeleteOutlined
                                        style={{
                                          color: "red",
                                          fontSize: "20px",
                                          marginRight: "5px",
                                        }}
                                      />
                                      Delete
                                    </Row>
                                  </Menu.Item>
                                )}
                                { }
                              </Menu>
                            }
                          >
                            <Button
                              type="link"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <EllipsisOutlined
                                style={{
                                  fontSize: "20px",
                                  color: "black",
                                }}
                              />
                            </Button>
                          </Dropdown>
                        )}
                      </Row>
                    </div>
                    <div className="d-flex flex-column align-items-start my-4">
                      <Typography.Title level={5}>Description</Typography.Title>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: displayDesc.current,
                        }}
                        style={{ whiteSpace: "pre-line" }}
                      />
                    </div>
                    {query.type === query_type.TRANSACTIONAL && (
                      <div className="d-flex flex-column align-items-start my-4">
                        <Typography.Title level={5}>
                          Transaction Details
                        </Typography.Title>
                        <Typography.Text>
                          {`Cash In: ${query.cashIn?.value || 0} ${query.cashIn?.currency || ""
                            }`}
                        </Typography.Text>
                        <Typography.Text>
                          {`Cash Out: ${query.cashOut?.value || 0} ${query.cashOut?.currency || ""
                            }`}
                        </Typography.Text>
                        <Typography.Text>
                          {`Date: ${query.Date !== undefined ? moment(query.Date).format(
                            "DD MMM YYYY"
                          ) : ""} `}
                        </Typography.Text>
                      </div>
                    )}
                  </div>
                )}
                <div>
                  <Typography.Title
                    level={5}
                    className="align-self-start"
                    disabled={query.status === "CLOSED"}
                  >
                    Files
                  </Typography.Title>
                  <Files
                    setQuery={setQuery}
                    query={query}
                    query_id={query_id}
                    id={query._id}
                    queryStatus={query.status}
                  />
                </div>

                {/* // subQueries.length > 0 && */}
                {(subQueries.length > 0 || showquery) && (
                  <div
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    <Row
                      style={{
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                      gutter={[8, 0]}
                    >
                      <Col>
                        <Typography.Title
                          level={5}
                          style={{
                            margin: 0,
                          }}
                        >
                          Sub Query
                        </Typography.Title>
                      </Col>
                      {!setModalQueryId && <Col>{ }</Col>}
                      {subQueries.length > 0 && query.type === query_type.NORMAL && (
                        <Button onClick={() => displaynewqueries()} type="link">
                          {" "}
                          + ADD Transactional Query
                        </Button>
                      )}
                    </Row>
                    <Card
                      className="header-solid h-full mb-15 mt-15"
                      bordered={true}
                    >
                      {subQueries.length == 0 && (
                        <div ref={myRef}>
                          <Subquery
                            subQueries={subQueries}
                            setSubQueries={setSubQueries}
                            myref={myRef}
                            query={query}
                            query_type={query_type}
                            onSuccess={onSuccess}
                          />
                        </div>
                      )}
                      {/* .................add queriessssssssssssssssssssssssssss .........*/}
                      {subQueries.length > 0 && (
                        <div>
                          <Table
                            style={{ width: "100%" }}
                            columns={columns}
                            dataSource={subQueries}
                            pagination={false}
                            size="small"
                            loading={loading}
                            expandable={{
                              expandedRowRender: (subQuery) => (
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{ width: "70%" }}
                                      className="d-flex flex-row align-items-center justify-content-around"
                                    >
                                      <Input.TextArea
                                        id="subQueryComment"
                                        placeholder="Add a comment"
                                        autoSize={{
                                          minRows: 2,
                                          maxRows: 3,
                                        }}
                                        value={newSubQueryComment}
                                        onChange={(e) => {
                                          setNewSubQueryComment(e.target.value);
                                        }}
                                      />
                                      {(newSubQueryComment ||
                                        subQueryFilesWaiting) && (
                                          <div>
                                            <Button
                                              type="text"
                                              onClick={() => {
                                                setNewSubQueryComment("");
                                              }}
                                              size="small"
                                              style={{
                                                margin: "0 5px",
                                              }}
                                            >
                                              Cancel
                                            </Button>
                                            <Button
                                              type="text"
                                              onClick={async () => {
                                                let commentId =
                                                  await handleNewSubQueryComment(
                                                    subQuery
                                                  );
                                                // window.myFileUpload(
                                                //     commentId
                                                // );
                                              }}
                                              size="small"
                                              style={{
                                                margin: "0 5px",
                                              }}
                                            >
                                              Submit
                                            </Button>
                                          </div>
                                        )}
                                    </div>
                                    <div>
                                      <Button
                                        type="primary"
                                        onClick={() => {
                                          if (setModalQueryId) {
                                            setModalQueryId(subQuery.query_id);
                                            setParentModalQueryId(query_id);
                                          } else {
                                            navigate(`/query/${subQuery.query_id}`);
                                          }
                                        }}
                                      >
                                        View more
                                      </Button>
                                    </div>
                                  </div>
                                  {/* <UploadSnapshot
                                                                    setFilesWaiting={setSubQueryFilesWaiting}
                                                                    refresh={reset}
                                                                    id={subQuery._id}
                                                                    containerId = {'subQueryComment'}
                                                                /> */}
                                  {subQuery.comments?.length ? (
                                    <List
                                      className="comment-list w-100 px-3"
                                      itemLayout="horizontal"
                                      dataSource={subQuery.comments}
                                      loadMore={
                                        <div
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          <Files
                                            id={subQuery._id}
                                            queryStatus={subQuery.status}
                                          />
                                        </div>
                                      }
                                      renderItem={(item) => (
                                        <Comment
                                          author={
                                            <span>
                                              <b>
                                                {item?.created_by?.first_name}{" "}
                                                {item?.created_by?.last_name}
                                              </b>
                                              <span className="ms-2">
                                                {moment(item.createdAt).format(
                                                  "DD MMM YYYY HH:mm"
                                                )}
                                              </span>
                                            </span>
                                          }
                                          avatar={
                                            <Avatar shape="square">
                                              {item?.created_by?.first_name[0]}
                                              {item?.created_by?.last_name[0]}
                                            </Avatar>
                                          }
                                          content={
                                            <div>
                                              <span
                                                style={{
                                                  whiteSpace: "pre-line",
                                                }}
                                              >
                                                {" "}
                                                {item?.text}
                                              </span>

                                              <div>
                                                {files.filter(
                                                  (s) =>
                                                    s.commentId === item._id
                                                ).length
                                                  ? files
                                                    .filter(
                                                      (s) =>
                                                        s.commentId ===
                                                        item._id
                                                    )
                                                    .map((e) => (
                                                      <Tag
                                                        className="pointer m-1"
                                                        style={{
                                                          padding: "9px",
                                                          borderRadius: "5px",
                                                          maxWidth: "180px",
                                                        }}
                                                      >
                                                        <Image src={e.path} />
                                                        <div>
                                                          {(() => {
                                                            e.name =
                                                              e.name.split(
                                                                "---"
                                                              )[0];
                                                            const changedName =
                                                              e.name.length <=
                                                                15
                                                                ? `${e.name}`
                                                                : `${e.name
                                                                  .split(
                                                                    "."
                                                                  )[0]
                                                                  .slice(
                                                                    0,
                                                                    12
                                                                  )}...${e.name.split(
                                                                    "."
                                                                  )[1]
                                                                }`;

                                                            return changedName;
                                                          })()}
                                                        </div>
                                                        <div>
                                                          {moment
                                                            .utc(e.createdAt)
                                                            .format(
                                                              "DD MMM YYYY HH:mm"
                                                            )}
                                                        </div>
                                                      </Tag>
                                                    ))
                                                  : null}
                                              </div>
                                            </div>
                                          }
                                        />
                                      )}
                                    />
                                  ) : null}
                                </div>
                              ),
                              expandedRowKeys: activeKey,
                              onExpand: (expanded, record) => {
                                if (expanded) {
                                  setActiveKey([record.key]);
                                } else {
                                  setActiveKey([]);
                                }
                                setSubQueryFilesWaiting(false);
                                setNewSubQueryComment("");
                              },

                              rowExpandable: (record) =>
                                record.title !== "Not Expandable",
                              expandRowByClick: true,
                              expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                  <DownOutlined
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onExpand(record, e);
                                    }}
                                  />
                                ) : (
                                  <RightOutlined
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onExpand(record, e);
                                    }}
                                  />
                                ),
                            }}
                          />
                        </div>
                      )}
                      {subQueries.length > 0 && shownewquery && (
                        <div ref={myRef}>
                          <Subquery
                            subQueries={subQueries}
                            setSubQueries={setSubQueries}
                            myref={myRef}
                            query={query}
                            query_type={query_type}
                            onSuccess={onSuccess}
                          />
                        </div>
                      )}
                    </Card>

                    <div></div>
                  </div>
                )}
                <Tabs type="card" className="my-4">
                  <Tabs.TabPane
                    tab={
                      <Typography.Title level={5} className="align-self-start">
                        Comments
                      </Typography.Title>
                    }
                    key="comments"
                  >
                    <CommentView
                      comments={comments}
                      query_id={query._id}
                      setComments={setComments}
                      onCommentAdded={
                        query.status === "CLOSED" ? handleQueryUpdate : null
                      }
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <Typography.Title level={5} className="align-self-start">
                        History
                      </Typography.Title>
                    }
                    key="history"
                  >
                    <Activity query_id={query._id} canNavigate={false} />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        ) : (
          <div className="py-3 px-3">
            <Typography.Title level={3}>
              The Query does not exist or you do not have access :/
            </Typography.Title>
          </div>
        )}
      </Card>
      <PermissionModal
        visible={permissionModalOpen !== null}
        setVisible={setPermissionModalOpen}
        onSuccess={deleteQuery}
        child={
          <p>
            Are you sure you want to delete this <b>Query</b>? This will delete
            all the <b>Attachments</b> related to this Query.
          </p>
        }
      />
      <SubQueryModal
        visible={addQuery}
        setVisible={setAddQuery}
        onSuccess={loadSubQueries}
        query={query}
      />
    </>
  );
}
