import React, { useEffect, useState, useRef } from 'react';
import useFetch from '../../_fetch';
import { Button, Col, Row, Typography, message, Form, Input, Upload, List, Avatar, Image } from 'antd';
import moment from 'moment';
import { Comment } from '@ant-design/compatible';
import { FolderFilled, PlusOutlined } from '@ant-design/icons';
import './styles.scss'
import FolderContent from './FolderContent';
import { InboxOutlined, RollbackOutlined } from "@ant-design/icons";
import CreateDocument from './CrateDocument';
import FileItem from '../../components/FilesItem';
import { useSelector } from 'react-redux';
const { Dragger } = Upload;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

export default function Documents({ fy_id, toggle, company_id, setToggle }) {
    const _fetch = useFetch();
    const [allFolder, setAllFolder] = useState([]);
    const [showFolderContent, setShowFolderContent] = useState({});
    const [form] = Form.useForm();
    const filesRef = useRef([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false)
    const [document, setDocument] = useState([]);
    const { selected } = useSelector((st) => st.fy);
    const [byDefault, setByDefault] = useState(false);

    async function init() {
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/folder?fy_id=${fy_id}`)
            res = await res.json();
            console.log(res.response);
            if (res.status >= 200 && res.status < 300) {
                setAllFolder(res.response);
            } else {
                message.error(res.response);
            }
        } catch (e) {
            message.error("Something went wrong")
            console.log(e);
        }
    }

    const upload = async (file, url, document_id, resolve, reject) => {
        console.log("trying to upload an image on url:", url);
        try {
            let options;
            let contentType = "";
            contentType = file.type;
            console.log(file);
            options = {
                method: "PUT",
                headers: {
                    "Content-Type": contentType,
                },
                body: file,
            };

            let res = await fetch(url, options);
            if (res.status === 200) {
                try {
                    let parsedUrl = new URL(url);
                    let pathname = parsedUrl.pathname.slice(1);
                    let uploadedRes = await _fetch(
                        `${process.env.REACT_APP_API_URL}/file`,
                        {
                            method: "POST",
                            body: {
                                document_id,
                                path: pathname,
                                type: contentType,
                            },
                        }
                    );
                    if (uploadedRes.status === 200) {
                        uploadedRes = await uploadedRes.json();
                        console.log("DataCreate", uploadedRes);
                        resolve(file.uid);
                        // setToggle((pre) => !pre)
                    }
                } catch (e) {
                    console.log(e);
                    reject(e);
                }
            }
        } catch (e) {
            console.log(e);
            reject(e);
        }
    };

    async function getUploadUrl(file, id, d_id, status) {
        const fileParts = file.name.split(".");
        fileParts.pop();
        let name = fileParts.join(".");
        let ext = file.name.split(".").pop();
        name = `${name}-${moment().valueOf()}.${ext}`;
        console.log(name);
        let url;
        status === "confirmed" ? url = await _fetch(
            `${process.env.REACT_APP_API_URL}/file/get_upload_url?name=${name}&fy=${id}`
        ) : url = await _fetch(
            `${process.env.REACT_APP_API_URL}/file/get_upload_url?name=${name}&document_id=${d_id}`
        )
        if (url.status === 200) {
            url = await url.json();
            console.log("URL Res", url.response)
            return url.response;
        } else {
            console.log("url to upload generation failed");
            throw new Error("url to upload generation failed");
        }
    }

    const Reload = (ev) => {
        ev.preventDefault();
        return (ev.returnValue = "Are you sure you want to close?");
    };

    async function submit(values) {
        console.log(values)
        setLoading(true);
        if (!values?.files?.fileList?.length) {
            message.info("Upload A Document");
            setLoading(false);
            return
        }
        const body = {
            fy_id: fy_id,
            status: "confirmed",
            company: company_id,
            comment: values.comment,
            folder_id: showFolderContent.folder_id
        }
        console.log("body", body);
        window.addEventListener("beforeunload", Reload);
        try {
            let document_id = "";
            let status = ""
            let createdDocument = await _fetch(
                `${process.env.REACT_APP_API_URL}/documents`,
                {
                    method: "POST",
                    body
                }
            );
            createdDocument = await createdDocument.json();
            if (createdDocument.status >= 200 && createdDocument.status < 300) {
                console.log('Create Documents', createdDocument);
                document_id = createdDocument.response._id;
                status = createdDocument.response.status
            } else {
                throw new Error(createdDocument.response);
            }

            let promises = [];
            for (let i = 0; i < filesRef.current.length; i += 1) {
                let file = filesRef.current[i];
                promises[i] = new Promise(async function (resolve, reject) {
                    let url = await getUploadUrl(file, fy_id, document_id, status);
                    console.log(url);
                    if (url) upload(file, url, document_id, resolve, reject);
                });
                Promise.all(promises)
                    // eslint-disable-next-line no-loop-func
                    .then((values) => {
                        if (values.length === filesRef.current.length) {
                            message.success("Documents Submitted");
                            window.removeEventListener("beforeunload", Reload);
                            setLoading(false);
                            setToggle((pre) => !pre)
                            filesRef.current = []
                            form.resetFields();
                        }
                    })
                    .catch((e) => {
                        message.error(e);
                        window.removeEventListener("beforeunload", Reload);
                        setLoading(false);
                        filesRef.current = []
                        form.resetFields();
                    });
            }
        } catch (e) {
            message.error(e);
            window.removeEventListener("beforeunload", Reload);
            setLoading(false);
            form.resetFields();
        }
    }

    const getDocumentData = async () => {
        try {
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/documents?fy_id=${fy_id}`)
            res = await res.json();
            console.log('response', res)
            if (res.status >= 200 && res.status < 300) {
                return res.response
            }
        }
        catch (e) {
            console.log(e)
        }
    }
    async function getInit() {

        let k = await getDocumentData();
        // if (k !== 'oK') return;
        let tempDoc = [];

        for (let i = 0; i < k.length; i += 1) {
            console.log("fgkjdfhd");
            tempDoc[i] = k[i];
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/file?document_id=${tempDoc[i]._id}`)
            res = await res.json();
            if (res.status >= 200 && res.status < 300)
                tempDoc[i].files = res.response;

        }
        console.log("Doc", tempDoc);
        setDocument(tempDoc);
    }

    const props = {
        multiple: true,
        showUploadList: {
            showPreviewIcon: false,
        },
        listType: "picture-card",
        beforeUpload: async (file) => {
            filesRef.current.push(file);
            return false;
        },
        onRemove: (file) => {
            console.log(file, filesRef)
            filesRef.current = filesRef.current.filter((e) => e.uid !== file.uid);
        },
    };

    useEffect(() => {
        init();
        getInit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fy_id, toggle, loading, open])
    return (
        <React.Fragment>
            <Row style={{ display: 'flex', justifyContent: "space-between" }}>

                {
                    !byDefault ? !showFolderContent.folder_id ? (
                        <Row gutter={[24, 24]} id="folders" >
                            <Col
                                lg={4} md={6} xl={4} sm={8}
                                className='folderContainer'
                                style={{
                                    border: '2px solid #53acff', backgroundColor: '#00000005',
                                    height: "100px", minWidth: '150px', marginTop: '15px'
                                }}
                                onClick={() => setOpen(true)}
                            >
                                <PlusOutlined className='folderIcon' style={{ fontSize: "50px" }} />
                                <Typography.Title level={5} >
                                    Create Folder
                                </Typography.Title>
                            </Col>
                            {
                                document.length > 0 && <Col
                                    lg={4} md={6} xl={4} sm={8}
                                    className='folderContainer'
                                    style={{ minWidth: "150px" }}
                                    onClick={() => setByDefault(true)}
                                >
                                    <FolderFilled className='folderIcon' />
                                    <Typography.Title level={5} >
                                        {moment(selected.start).format("YYYY")} -{" "}
                                        {moment(selected.end).format("YY")}
                                    </Typography.Title>
                                </Col>
                            }
                            {allFolder &&
                                allFolder.map((name) => {
                                    console.log(name);
                                    return (
                                        <>
                                            <Col
                                                lg={4} md={6} xl={4} sm={8}
                                                className='folderContainer'
                                                style={{ minWidth: "150px" }}
                                                onClick={() => setShowFolderContent({ folder_id: name._id, name: name.name })}
                                            >
                                                <FolderFilled className='folderIcon' />
                                                <Typography.Title level={5} >
                                                    {name.name}
                                                </Typography.Title>
                                            </Col>
                                        </>
                                    )
                                })
                            }
                        </Row>
                    ) : (
                        <FolderContent id={showFolderContent.folder_id}
                            name={showFolderContent.name}
                            style={{ width: "100%" }}
                            setShowFolderContent={setShowFolderContent} toggle={toggle}
                            setToggle={setToggle}
                        />
                    ) :
                        <Col>
                            <Button type="link"
                                onClick={() => setByDefault(false)}
                            >
                                <RollbackOutlined /> Back
                            </Button>

                            <List
                                className="p-4"
                                dataSource={document}
                                renderItem={(doc) => (
                                    <Comment
                                        author={`${doc.created_by?.first_name} ${doc.created_by?.last_name}`}
                                        avatar={
                                            <Avatar shape='square'>
                                                {doc.created_by?.first_name[0]}
                                                {doc.created_by?.last_name[0]}
                                            </Avatar>
                                        }
                                        content={doc.comment}
                                        datetime={moment(doc.createdAt).format("DD/MM/YYYY hh:mm A")}
                                    >
                                        <div>
                                            <br />
                                            <Image.PreviewGroup>
                                                {
                                                    doc.files.map(file => (
                                                        <FileItem file={file} />
                                                    ))
                                                }
                                            </Image.PreviewGroup>
                                        </div>
                                    </Comment>
                                )}
                            />
                        </Col>
                }

                {showFolderContent.folder_id !== undefined &&
                    <Form
                        layout="vertical"
                        {...layout}
                        form={form}
                        onFinish={submit}
                        style={{ padding: "10px", width: "40%" }}
                    >
                        <Form.Item name="files">
                            <Dragger
                                {...props}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    Click or drag file to this area to upload documents
                                </p>
                            </Dragger>
                        </Form.Item>

                        <Form.Item
                            label="Comment"
                            name="comment">
                            <Input.TextArea
                                placeholder="type your comment..."
                                autoSize={{
                                    minRows: 5,
                                    maxRows: 10,
                                }}
                                style={{ height: '20px', width: "100%" }}
                            />
                        </Form.Item>
                        <Form.Item style={{ display: "flex", justifyContent: "center" }}>
                            <Button htmlType="submit" style={{ backgroundColor: "#53acff", color: "white" }}
                                loading={loading}
                            >Submit</Button>
                        </Form.Item>
                    </Form>
                }
            </Row>
            <CreateDocument open={open} setOpen={setOpen} fy_id={fy_id} />
        </React.Fragment>
    )
}