import {
    useEffect, useState
} from "react";
import React from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "../loader";
import useFetch from "../../_fetch";
import { Button, Modal, Row, Table, message } from "antd";
import moment from "moment";
import { fy_status_order } from "../../consts";
import { useNavigate } from "react-router-dom";
import SearchBox from "../SearchBox";
import Query from "../../pages/Query";
import { query_type } from "../../consts";

const statusFilters = fy_status_order.map((e) => ({ text: e, value: e }));
const queryOwnerFilters = (dat) => {
    let map = {};
    let filter = [];
    dat.forEach((e) => {
        let name = `${e.created_by.first_name} ${e.created_by.last_name}`;
        if (!map[name]) {
            map[name] = 1;
            filter.push({ text: name, value: name });
        }
    });
    return filter;
};
const titleFilters = (dat) => {
    let map = {};
    let filter = [];
    dat.forEach((e) => {
        if (!map[e.title]) {
            map[e.title] = 1;
            filter.push({ text: e.title, value: e.title });
        }
    });
    return filter;
};

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ReviewPoints({
    fy_id, toggle
}) {
    const _fetch = useFetch();
    const navigate = useNavigate();
    const [show, setShow] = useState([]);
    const [reviewPoints, setReviewPoints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalQueryId, setModalQueryId] = useState(null);
    let query = useQuery();

    const init = async () => {
        try {
            setLoading(true);
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/query?fy_id=${fy_id}&type=${query_type.REVIEW_POINTS}&company_id=${query.get("company")}`);
            res = await res.json();
            if (res.success) {
                setReviewPoints(res.response);
                console.log("Response", res.response)
            } else {
                message.error(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }
    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            filters: titleFilters(show),
            key: "title",
            width: "20%",
            sorter: (a, b) => {
                return `${a.title.toLowerCase()}` > `${b.title.toLowerCase()}`
                    ? 10
                    : -10;
            },
            onFilter: (value, record) => record.title === value,
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            sorter: (a, b) => {
                return `${a.status.toLowerCase()}` > `${b.status.toLowerCase()}`
                    ? 10
                    : -10;
            },
            render(record) {
                return (
                    <div
                        style={{
                            padding: "5px",
                            borderRadius: "10px",
                            textAlign: "center",
                            backgroundColor: record === "CLOSED" ? "green" : null,
                            color: record === "CLOSED" ? "white" : "",
                        }}
                    >
                        {record}
                    </div>
                );
            },
            filters: statusFilters,
            onFilter: (value, record) => record.status === value,
        },
        {
            title: "Created On",

            key: "createdAt",
            sorter: (a, b) =>
                new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            render: (record) => moment(record.createdAt).format("DD MMM YYYY"),
        },

        {
            title: "Query Owner",
            key: "createdBy",
            sorter: (a, b) => {
                return `${a.created_by?.first_name} ${a.created_by?.last_name}`.toLowerCase() >
                    `${b.created_by?.first_name} ${b.created_by?.last_name}`.toLowerCase()
                    ? 10
                    : -10;
            },
            render: (record) =>
                `${record.created_by?.first_name} ${record.created_by?.last_name}`,
            filters: queryOwnerFilters(show),
            onFilter: (value, record) =>
                `${record.created_by.first_name} ${record.created_by.last_name}` ===
                value,
        },
        {
            title: "",
            key: "actions",
            render: (record) => (
                <Row style={{ alignItems: "center" }}>
                    <Button
                        type="primary"
                        onClick={() => navigate(`/query/${record.query_id}`)}
                    >
                        View Review Point
                    </Button>
                </Row>
            ),
        },
    ]

    useEffect(() => {
        init();
    }, [fy_id, toggle]);
    if (loading) return <Loader />;
    return (
        <div>
            <div
                style={{
                    padding: '0px 50px',
                }}
            >
                <SearchBox
                    all={reviewPoints}
                    setshow={setShow}
                    name='Search Review Points'
                />
            </div>
            <Table
                columns={columns}
                dataSource={show}
                pagination={false}
                className="ant-border-space"
                sortDirections={["ascend", "descend", "ascend"]}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            setModalQueryId(record.query_id);
                        },
                    };
                }}
            />
            <Modal
                style={{
                    top: 0,
                }}
                width="70vw"
                destroyOnClose
                centered
                footer={null}
                onOk={() => setModalQueryId(null)}
                onCancel={() => setModalQueryId(null)}
                open={modalQueryId}
            >
                <Query
                    query={modalQueryId}
                    setModalQueryId={setModalQueryId}
                    onSuccess={init}
                />
            </Modal>
        </div>
    );
}